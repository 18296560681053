import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { Currency, CurrencyAmount, Fraction, Percent, Price, Token } from '@uniswap/sdk-core'
import { NonfungiblePositionManager, Pool, Position } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import Badge from 'components/Badge'
import { ButtonConfirmed, ButtonGray, ButtonPrimary } from 'components/Button'
import { DarkCard, LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import CostChange from 'components/PositionListItem/CostChange'
import GetSimilarPositions from 'components/PositionListItem/GetSimilarPositions'
import PoolInfoCard from 'components/PositionListItem/PoolInfoCard'
import { PositionDuration } from 'components/PositionListItem/PositionDuration'
import PositionImpermanentLoss from 'components/PositionListItem/PositionImpermanentLoss'
import PositionKLineChart from 'components/PositionListItem/PositionKLineChart'
import PositionTxDetails from 'components/PositionListItem/PositionTxDetails'
import QuoteChange from 'components/PositionListItem/QuoteChange'
import RequestTopPools from 'components/PositionListItem/RequestTopPools'
import { RowBetween, RowFixed } from 'components/Row'
import { Dots } from 'components/swap/styleds'
import Toggle from 'components/Toggle'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
// import  from 'components/PositionListItem/PositionTxDetails'
import { fetchPositionData } from 'data/position/positionData'
import { useToken } from 'hooks/Tokens'
import { useV3NFTPositionManagerContract } from 'hooks/useContract'
import { PoolState, usePool } from 'hooks/usePools'
import {
  useAPR,
  usePosDataByTokenId,
  usePositionDuration,
  usePositionMintAndBurnData,
  useSpreadRange,
} from 'hooks/usePositionsData'
import useUSDCPrice from 'hooks/useUSDCPrice'
import { useV3PositionFees } from 'hooks/useV3PositionFees'
import { useV3PositionFromTokenId } from 'hooks/useV3Positions'
import { useSingleCallResult } from 'lib/hooks/multicall'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { useIsTransactionPending, useTransactionAdder } from 'state/transactions/hooks'
import styled from 'styled-components/macro'
import { ExternalLink, HideExtraSmall, ThemedText } from 'theme'
import { currencyId } from 'utils/currencyId'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import { NumbertoSignificantDigits } from 'utils/fromTickToPrice'
import { unwrappedToken } from 'utils/unwrappedToken'

import RangeBadge from '../../components/Badge/RangeBadge'
import { getPriceOrderingFromPositionForUI } from '../../components/PositionListItem'
import SpreadRange from '../../components/PositionListItem/SpreadRange'
import RateToggle from '../../components/RateToggle'
import ShowApr from '../../components/ShowApr'
import ShowOwner from '../../components/ShowOwner'
import { usePositionTokenURI } from '../../hooks/usePositionTokenURI'
import useTheme from '../../hooks/useTheme'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import { LoadingRows } from './styleds'

const PageWrapper = styled.div`
  min-width: 800px;
  max-width: 960px;
  width: 960px;

  // ${({ theme }) => theme.mediaWidth.upToMedium`
  //   min-width: 680px;
  //   max-width: 680px;
  // `};
  //
  // ${({ theme }) => theme.mediaWidth.upToSmall`
  //   min-width: 600px;
  //   max-width: 600px;
  // `};
  //
  // @media only screen and (max-width: 620px) {
  //   min-width: 500px;
  //   max-width: 500px;
  // }
  //
  // ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  //   min-width: 340px;
  //   max-width: 340px;
  // `};
`

export const BadgeText = styled.div`
  font-weight: 500;
  font-size: 14px;
`

// responsive text
// disable the warning because we don't use the end prop, we just want to filter it out
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Label = styled(({ end, ...props }) => <ThemedText.Label {...props} />)<{ end?: boolean }>`
  display: flex;
  font-size: 16px;
  justify-content: ${({ end }) => (end ? 'flex-end' : 'flex-start')};
  align-items: center;
`

export const DarkBadge = styled.div`
  width: fit-content;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.bg0};
  padding: 4px 6px;
`

const ExtentsText = styled.span`
  color: ${({ theme }) => theme.text2};
  font-size: 14px;
  text-align: center;
  margin-right: 4px;
  font-weight: 500;
`

const HoverText = styled(ThemedText.Main)`
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  :hover {
    color: ${({ theme }) => theme.text1};
    text-decoration: none;
  }
`

const DoubleArrow = styled.span`
  color: ${({ theme }) => theme.text3};
  margin: 0 1rem;
`
export const ResponsiveRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    width: 100%:
  `};
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  border-radius: 12px;
  padding: 6px 8px;
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex: 1 1 auto;
    width: 49%;
  `};
`

const NFTGrid = styled.div`
  display: grid;
  grid-template: 'overlap';
  min-height: 400px;
`

const NFTCanvas = styled.canvas`
  grid-area: overlap;
`

const NFTImage = styled.img`
  grid-area: overlap;
  height: 400px;
  /* Ensures SVG appears on top of canvas. */
  z-index: 1;
`

const PoolMsgBox = styled.div`
  display: flex;
  justify-content: flex-start;
  p {
    margin: 0;
    &.id {
      margin-bottom: 6px;
      margin-right: 20px;
      .title {
        margin-right: 6px;
      }
      .msg {
        margin-right: 6px;
      }
    }
    &.duration {
      margin-left: 12px;
    }
  }
`

const TickText = styled.span`
  font-size: 14px;
  color: #999;
`

const NoBorderTableCell = styled(TableCell)`
  border-bottom: none;
  padding: 8px;
  text-align: right;
`

function CurrentPriceCard({
  inverted,
  pool,
  currencyQuote,
  currencyBase,
}: {
  inverted?: boolean
  pool?: Pool | null
  currencyQuote?: Currency
  currencyBase?: Currency
}) {
  if (!pool || !currencyQuote || !currencyBase) {
    return null
  }

  return (
    <AutoColumn gap="8px" justify="center" style={{ padding: '0' }}>
      <ExtentsText>
        <Trans>Current price</Trans>
      </ExtentsText>
      <ThemedText.MediumHeader textAlign="center">
        {(inverted ? pool.token1Price : pool.token0Price).toSignificant(5)} <TickText>({pool.tickCurrent})</TickText>
      </ThemedText.MediumHeader>
      <ExtentsText>
        <Trans>
          {currencyQuote?.symbol} per {currencyBase?.symbol}
        </Trans>
      </ExtentsText>
    </AutoColumn>
  )
}

export function LinkedCurrency({ chainId, currency }: { chainId?: number; currency?: Currency }) {
  const address = (currency as Token)?.address

  if (typeof chainId === 'number' && address) {
    return (
      <ExternalLink href={getExplorerLink(chainId, address, ExplorerDataType.ADDRESS)}>
        <RowFixed>
          <CurrencyLogo currency={currency} size={'20px'} style={{ marginRight: '0.5rem' }} />
          <ThemedText.Main>
            {currency?.symbol} {/*↗*/}
          </ThemedText.Main>
        </RowFixed>
      </ExternalLink>
    )
  }

  return (
    <RowFixed>
      <CurrencyLogo currency={currency} size={'20px'} style={{ marginRight: '0.5rem' }} />
      <ThemedText.Main>{currency?.symbol}</ThemedText.Main>
    </RowFixed>
  )
}

function getRatio(
  lower: Price<Currency, Currency>,
  current: Price<Currency, Currency>,
  upper: Price<Currency, Currency>
) {
  try {
    if (!current.greaterThan(lower)) {
      return 100
    } else if (!current.lessThan(upper)) {
      return 0
    }

    const a = Number.parseFloat(lower.toSignificant(15))
    const b = Number.parseFloat(upper.toSignificant(15))
    const c = Number.parseFloat(current.toSignificant(15))

    const ratio = Math.floor((1 / ((Math.sqrt(a * b) - Math.sqrt(b * c)) / (c - Math.sqrt(b * c)) + 1)) * 100)

    if (ratio < 0 || ratio > 100) {
      throw Error('Out of range')
    }

    return ratio
  } catch {
    return undefined
  }
}

// snapshots a src img into a canvas
function getSnapshot(src: HTMLImageElement, canvas: HTMLCanvasElement, targetHeight: number) {
  const context = canvas.getContext('2d')

  if (context) {
    let { width, height } = src

    // src may be hidden and not have the target dimensions
    const ratio = width / height
    height = targetHeight
    width = Math.round(ratio * targetHeight)

    // Ensure crispness at high DPIs
    canvas.width = width * devicePixelRatio
    canvas.height = height * devicePixelRatio
    canvas.style.width = width + 'px'
    canvas.style.height = height + 'px'
    context.scale(devicePixelRatio, devicePixelRatio)

    context.clearRect(0, 0, width, height)
    context.drawImage(src, 0, 0, width, height)
  }
}

function NFT({ image, height: targetHeight }: { image: string; height: number }) {
  const [animate, setAnimate] = useState(false)

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  return (
    <NFTGrid
      onMouseEnter={() => {
        setAnimate(true)
      }}
      onMouseLeave={() => {
        // snapshot the current frame so the transition to the canvas is smooth
        if (imageRef.current && canvasRef.current) {
          getSnapshot(imageRef.current, canvasRef.current, targetHeight)
        }
        setAnimate(false)
      }}
    >
      <NFTCanvas ref={canvasRef} />
      <NFTImage
        ref={imageRef}
        src={image}
        hidden={!animate}
        onLoad={() => {
          // snapshot for the canvas
          if (imageRef.current && canvasRef.current) {
            getSnapshot(imageRef.current, canvasRef.current, targetHeight)
          }
        }}
      />
    </NFTGrid>
  )
}

export function PositionPage({
  match: {
    params: { tokenId: tokenIdFromUrl },
  },
}: RouteComponentProps<{ tokenId?: string }>) {
  // const { chainId, account, library } = useActiveWeb3React()
  const { chainId, account, provider } = useWeb3React()
  const theme = useTheme()

  const parsedTokenId = tokenIdFromUrl ? BigNumber.from(tokenIdFromUrl) : undefined
  const { loading, position: positionDetails } = useV3PositionFromTokenId(parsedTokenId)

  const {
    token0: token0Address,
    token1: token1Address,
    fee: feeAmount,
    liquidity,
    tickLower,
    tickUpper,
    tokenId,
  } = positionDetails || {}

  const removed = liquidity?.eq(0)

  const token0 = useToken(token0Address)
  const token1 = useToken(token1Address)

  const metadata = usePositionTokenURI(parsedTokenId)

  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined

  // flag for receiving WETH
  const [receiveWETH, setReceiveWETH] = useState(false)
  // construct Position from details returned
  const [poolState, pool] = usePool(token0 ?? undefined, token1 ?? undefined, feeAmount)
  const position = useMemo(() => {
    if (pool && liquidity && typeof tickLower === 'number' && typeof tickUpper === 'number') {
      return new Position({ pool, liquidity: liquidity.toString(), tickLower, tickUpper })
    }
    return undefined
  }, [liquidity, pool, tickLower, tickUpper])

  let { priceLower, priceUpper, base, quote } = getPriceOrderingFromPositionForUI(position)
  const [manuallyInverted, setManuallyInverted] = useState(false)
  // handle manual inversion
  if (manuallyInverted) {
    ;[priceLower, priceUpper, base, quote] = [priceUpper?.invert(), priceLower?.invert(), quote, base]
  }
  const inverted = token1 ? base?.equals(token1) : undefined
  const currencyQuote = inverted ? currency0 : currency1
  const currencyBase = inverted ? currency1 : currency0

  // check if price is within range
  const below = pool && typeof tickLower === 'number' ? pool.tickCurrent < tickLower : undefined
  const above = pool && typeof tickUpper === 'number' ? pool.tickCurrent >= tickUpper : undefined
  const inRange: boolean = typeof below === 'boolean' && typeof above === 'boolean' ? !below && !above : false

  // const ratio = useMemo(() => {
  //   return priceLower && pool && priceUpper
  //     ? getRatio(
  //         inverted ? priceUpper.invert() : priceLower,
  //         pool.token0Price,
  //         inverted ? priceLower.invert() : priceUpper
  //       )
  //     : undefined
  // }, [inverted, pool, priceLower, priceUpper])

  // fees
  const [feeValue0, feeValue1] = useV3PositionFees(pool ?? undefined, positionDetails?.tokenId, receiveWETH)

  const [collecting, setCollecting] = useState<boolean>(false)
  const [collectMigrationHash, setCollectMigrationHash] = useState<string | null>(null)
  const isCollectPending = useIsTransactionPending(collectMigrationHash ?? undefined)
  const [showConfirm, setShowConfirm] = useState(false)

  const addTransaction = useTransactionAdder()
  const positionManager = useV3NFTPositionManagerContract()

  const collect = useCallback(() => {
    if (!chainId || !feeValue0 || !feeValue1 || !positionManager || !account || !tokenId || !provider) return

    setCollecting(true)

    const { calldata, value } = NonfungiblePositionManager.collectCallParameters({
      tokenId: tokenId.toString(),
      expectedCurrencyOwed0: feeValue0,
      expectedCurrencyOwed1: feeValue1,
      recipient: account,
    })

    const txn = {
      to: positionManager.address,
      data: calldata,
      value,
    }

    provider
      .getSigner()
      .estimateGas(txn)
      .then((estimate) => {
        const newTxn = {
          ...txn,
          gasLimit: calculateGasMargin(estimate),
        }

        return provider
          .getSigner()
          .sendTransaction(newTxn)
          .then((response: TransactionResponse) => {
            setCollectMigrationHash(response.hash)
            setCollecting(false)

            // ReactGA.event({
            //   category: 'Liquidity',
            //   action: 'CollectV3',
            //   label: [feeValue0.currency.symbol, feeValue1.currency.symbol].join('/'),
            // })

            // addTransaction(response, {
            //   summary: `Collect ${feeValue0.currency.symbol}/${feeValue1.currency.symbol} fees`,
            // })
          })
      })
      .catch((error) => {
        setCollecting(false)
        console.error(error)
      })
  }, [chainId, feeValue0, feeValue1, positionManager, account, tokenId, provider])

  const owner = useSingleCallResult(!!tokenId ? positionManager : null, 'ownerOf', [tokenId]).result?.[0]
  const ownsNFT = owner === account || positionDetails?.operator === account

  // usdc prices always in terms of tokens
  const price0 = useUSDCPrice(token0 ?? undefined)
  const price1 = useUSDCPrice(token1 ?? undefined)

  const fiatValueOfFees: CurrencyAmount<Currency> | null = useMemo(() => {
    if (!price0 || !price1 || !feeValue0 || !feeValue1) return null

    // we wrap because it doesn't matter, the quote returns a USDC amount
    const feeValue0Wrapped = feeValue0?.wrapped
    const feeValue1Wrapped = feeValue1?.wrapped

    if (!feeValue0Wrapped || !feeValue1Wrapped) return null

    const amount0 = price0.quote(feeValue0Wrapped)
    const amount1 = price1.quote(feeValue1Wrapped)
    return amount0.add(amount1)
  }, [price0, price1, feeValue0, feeValue1])

  const fiatValueOfLiquidity: CurrencyAmount<Token> | null = useMemo(() => {
    if (!price0 || !price1 || !position) return null
    const amount0 = price0.quote(position.amount0)
    const amount1 = price1.quote(position.amount1)
    return amount0.add(amount1)
  }, [price0, price1, position])

  const feeValueUpper = inverted ? feeValue0 : feeValue1
  const feeValueLower = inverted ? feeValue1 : feeValue0

  // Begin 开始加东西了
  // End
  const positionInfo = usePosDataByTokenId(tokenId?.toNumber())
  const poolAddress = positionInfo?.pool.id

  // 获取用户仓位mint、burn的详情。
  // 这段代码会进入2次，也就是说查询量x2，不知道是不是与异步有关，目前还不知道如何去优化(有可能已经优化好了)
  const { mints, burns } = usePositionMintAndBurnData(chainId, tokenId?.toNumber(), owner)

  // 获取用户仓位的owner, liquidity, depositedToken0, depositedToken1, withdrawnToken0, withdrawnToken1, collectedFeesToken0, collectedFeesToken1等的数据
  const [collectedFeesToken0, setCollectedFeesToken0] = useState<number>()
  const [collectedFeesToken1, setCollectedFeesToken1] = useState<number>()
  const tokenIdNum = tokenId?.toNumber()
  useMemo(async () => {
    if (!tokenIdNum || !chainId) {
      return
    }

    const data = await fetchPositionData(chainId, tokenIdNum)
    if (data.length === 0) {
      return
    }
    setCollectedFeesToken0(Number(data[0].collectedFeesToken0) - Number(data[0].withdrawnToken0))
    setCollectedFeesToken1(Number(data[0].collectedFeesToken1) - Number(data[0].withdrawnToken1))
  }, [tokenIdNum, chainId])

  const { apr } = useAPR(tokenIdNum, owner, Number(fiatValueOfLiquidity?.toExact()), Number(fiatValueOfFees?.toExact()))
  // 计算持仓时间
  const positionDuration = usePositionDuration(mints, burns, removed)

  // check if price is within range
  const spreadRange = useSpreadRange(position?.token0PriceLower, position?.token0PriceUpper, 'percentage')

  // 存粹是从position里获取一些常量
  const { symbol0, symbol1, decimals0, decimals1, tickCurrent } = useMemo(() => {
    if (!position)
      return {
        symbol0: undefined,
        symbol1: undefined,
        decimals0: undefined,
        decimals1: undefined,
        tickCurrent: undefined,
      }
    return {
      symbol0: position.pool.token0.symbol,
      symbol1: position.pool.token1.symbol,
      decimals0: position.pool.token0.decimals,
      decimals1: position.pool.token1.decimals,
      tickCurrent: position.pool.tickCurrent,
    }
  }, [position])

  function modalHeader() {
    return (
      <AutoColumn gap={'md'} style={{ marginTop: '20px' }}>
        <LightCard padding="12px 16px">
          <AutoColumn gap="md">
            <RowBetween>
              <RowFixed>
                <CurrencyLogo currency={feeValueUpper?.currency} size={'20px'} style={{ marginRight: '0.5rem' }} />
                <ThemedText.Main>{feeValueUpper ? formatCurrencyAmount(feeValueUpper, 4) : '-'}</ThemedText.Main>
              </RowFixed>
              <ThemedText.Main>{feeValueUpper?.currency?.symbol}</ThemedText.Main>
            </RowBetween>
            <RowBetween>
              <RowFixed>
                <CurrencyLogo currency={feeValueLower?.currency} size={'20px'} style={{ marginRight: '0.5rem' }} />
                <ThemedText.Main>{feeValueLower ? formatCurrencyAmount(feeValueLower, 4) : '-'}</ThemedText.Main>
              </RowFixed>
              <ThemedText.Main>{feeValueLower?.currency?.symbol}</ThemedText.Main>
            </RowBetween>
          </AutoColumn>
        </LightCard>
        <ThemedText.Italic>
          <Trans>Collecting fees will withdraw currently available fees for you.</Trans>
        </ThemedText.Italic>
        <ButtonPrimary onClick={collect}>
          <Trans>Collect</Trans>
        </ButtonPrimary>
      </AutoColumn>
    )
  }

  return loading || poolState === PoolState.LOADING || !feeAmount ? (
    <LoadingRows>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoadingRows>
  ) : (
    <PageWrapper>
      <RequestTopPools />
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={() => setShowConfirm(false)}
        attemptingTxn={collecting}
        hash={collectMigrationHash ?? ''}
        content={() => (
          <ConfirmationModalContent
            title={<Trans>Claim fees</Trans>}
            onDismiss={() => setShowConfirm(false)}
            topContent={modalHeader}
          />
        )}
        pendingText={<Trans>Collecting fees</Trans>}
      />
      <AutoColumn gap="md">
        <AutoColumn gap="sm">
          <Link style={{ textDecoration: 'none', width: 'fit-content', marginBottom: '0.5rem' }} to="/position">
            <HoverText>
              <Trans>← Back to List</Trans>
            </HoverText>
          </Link>
          <ResponsiveRow>
            <RowFixed>
              <DoubleCurrencyLogo currency0={currencyBase} currency1={currencyQuote} size={24} margin={true} />
              <ThemedText.Label fontSize={'24px'} mr="10px">
                &nbsp;{currencyQuote?.symbol}&nbsp;/&nbsp;{currencyBase?.symbol}
              </ThemedText.Label>
              <Badge style={{ marginRight: '8px' }}>
                <BadgeText>
                  <Trans>{new Percent(feeAmount, 1_000_000).toSignificant()}%</Trans>
                </BadgeText>
              </Badge>
              <RangeBadge removed={removed} inRange={inRange} />
            </RowFixed>
            {ownsNFT && (
              <RowFixed>
                {currency0 && currency1 && feeAmount && tokenId ? (
                  <ButtonGray
                    as={Link}
                    to={`/increase/${currencyId(currency0)}/${currencyId(currency1)}/${feeAmount}/${tokenId}`}
                    style={{ marginRight: '8px', width: 'fit-content', padding: '6px 8px', borderRadius: '12px' }}
                  >
                    <Trans>Increase Liquidity</Trans>
                  </ButtonGray>
                ) : null}
                {tokenId && !removed ? (
                  <ResponsiveButtonPrimary as={Link} to={`/remove/${tokenId}`} style={{ width: 'fit-content' }}>
                    <Trans>Remove Liquidity</Trans>
                  </ResponsiveButtonPrimary>
                ) : null}
              </RowFixed>
            )}
          </ResponsiveRow>
          <RowBetween></RowBetween>
        </AutoColumn>
        <PoolMsgBox>
          <p className="id">ID: {tokenId?.toString()}</p>
          <ShowOwner account={owner} />
          <p className="duration" style={{ marginLeft: '20px' }}>
            <Trans>Holding period:</Trans> <PositionDuration duration={positionDuration} />
          </p>
          <PositionImpermanentLoss
            mints={mints}
            burns={burns}
            price0={price0?.toFixed(6)}
            price1={price1?.toFixed(6)}
            fiatValueOfLiquidity={fiatValueOfLiquidity?.toFixed(2)}
          />
        </PoolMsgBox>
        <ResponsiveRow align="flex-start">
          <AutoColumn gap="sm" style={{ width: '100%', height: '100%' }}>
            {/* <DarkCard> */}
            <LightCard padding="12px 16px" style={{ backgroundColor: 'white' }}>
              <RowBetween>
                <AutoColumn gap="4px" style={{ width: '56%' }}>
                  <AutoColumn gap="md">
                    <Label>
                      <Trans>Liquidity</Trans>
                    </Label>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      style={{ marginBottom: '8px' }}
                    >
                      <Grid item xs={7}>
                        <ThemedText.LargeHeader fontSize="36px" fontWeight={500}>
                          {fiatValueOfLiquidity?.greaterThan(new Fraction(1, 100)) ? (
                            <Trans>${fiatValueOfLiquidity.toFixed(2, { groupSeparator: ',' })}</Trans>
                          ) : (
                            <Trans>$-</Trans>
                          )}
                        </ThemedText.LargeHeader>
                      </Grid>
                      <Grid item xs={5}>
                        <QuoteChange
                          key="quotechange"
                          chainId={chainId}
                          position={position}
                          mints={mints}
                          burns={burns}
                          curFiatValueOfLiquidity={fiatValueOfLiquidity?.toFixed(2)}
                          price0={price0?.toFixed(2)}
                          price1={price1?.toFixed(2)}
                        />
                      </Grid>
                    </Grid>
                  </AutoColumn>
                  <CostChange
                    key="costchange"
                    chainId={chainId}
                    position={position}
                    mints={mints}
                    burns={burns}
                    curFiatValueOfLiquidity={fiatValueOfLiquidity?.toFixed(2)}
                    price0={price0?.toFixed(2)}
                    price1={price1?.toFixed(2)}
                    inverted={inverted}
                  />
                </AutoColumn>
                <AutoColumn gap="md" style={{ width: '43%' }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    style={{ marginBottom: '8px' }}
                  >
                    <Grid item xs={6}>
                      <Label>
                        <Trans>Unclaimed fees</Trans>
                      </Label>
                    </Grid>
                    <Grid item xs={3}>
                      {ownsNFT && (feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0) || !!collectMigrationHash) ? (
                        <ButtonConfirmed
                          disabled={collecting || !!collectMigrationHash}
                          confirmed={!!collectMigrationHash && !isCollectPending}
                          width="fit-content"
                          style={{ borderRadius: '12px' }}
                          padding="4px 8px"
                          onClick={() => setShowConfirm(true)}
                        >
                          {!!collectMigrationHash && !isCollectPending ? (
                            <ThemedText.Main color={theme.text1}>
                              <Trans> Collected</Trans>
                            </ThemedText.Main>
                          ) : isCollectPending || collecting ? (
                            <ThemedText.Main color={theme.text1}>
                              {' '}
                              <Dots>
                                <Trans>Collecting</Trans>
                              </Dots>
                            </ThemedText.Main>
                          ) : (
                            <>
                              <ThemedText.Main color={theme.white}>
                                <Trans>Collect fees</Trans>
                              </ThemedText.Main>
                            </>
                          )}
                        </ButtonConfirmed>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginBottom: '8px' }}
                  >
                    <Grid item xs={7}>
                      {fiatValueOfFees?.greaterThan(new Fraction(1, 100)) ? (
                        <ThemedText.LargeHeader color={theme.green1} fontSize="32px" fontWeight={500}>
                          <Trans>${fiatValueOfFees.toFixed(2, { groupSeparator: ',' })}</Trans>
                        </ThemedText.LargeHeader>
                      ) : (
                        <ThemedText.LargeHeader color={theme.text1} fontSize="36px" fontWeight={500}>
                          <Trans>$-</Trans>
                        </ThemedText.LargeHeader>
                      )}
                    </Grid>
                    <Grid item xs={5} style={{ textAlign: 'right' }}>
                      <ShowApr apr={apr} />
                    </Grid>
                  </Grid>
                  <LightCard padding="0" style={{ backgroundColor: 'white' }}>
                    <Table aria-label="simple table" size="small">
                      <TableHead>
                        <TableRow>
                          <NoBorderTableCell />
                          <NoBorderTableCell align="right">
                            <Trans>Claimed fees</Trans>
                          </NoBorderTableCell>
                          <NoBorderTableCell align="right">
                            <Trans>Unclaimed fees</Trans>
                          </NoBorderTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <NoBorderTableCell component="th" scope="row">
                            <RowFixed>
                              <CurrencyLogo
                                currency={inverted ? currency0 : currency1}
                                size={'20px'}
                                style={{ marginRight: '0.5rem' }}
                              />
                              <ThemedText.Main>{inverted ? token0?.symbol : token1?.symbol}</ThemedText.Main>
                            </RowFixed>
                          </NoBorderTableCell>
                          <NoBorderTableCell component="th" scope="row">
                            {NumbertoSignificantDigits(inverted ? collectedFeesToken0 : collectedFeesToken1, 5) ?? '-'}
                          </NoBorderTableCell>
                          <NoBorderTableCell component="th" scope="row">
                            <ThemedText.Main>
                              {feeValueUpper ? formatCurrencyAmount(feeValueUpper, 4) : '-'}
                            </ThemedText.Main>
                          </NoBorderTableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <NoBorderTableCell component="th" scope="row">
                            <RowFixed>
                              <CurrencyLogo
                                currency={inverted ? currency1 : currency0}
                                size={'20px'}
                                style={{ marginRight: '0.5rem' }}
                              />
                              <ThemedText.Main>{inverted ? token1?.symbol : token0?.symbol}</ThemedText.Main>
                            </RowFixed>
                          </NoBorderTableCell>
                          <NoBorderTableCell component="th" scope="row">
                            {NumbertoSignificantDigits(inverted ? collectedFeesToken1 : collectedFeesToken0, 5) ?? '-'}
                          </NoBorderTableCell>
                          <NoBorderTableCell component="th" scope="row">
                            <ThemedText.Main>
                              {feeValueLower ? formatCurrencyAmount(feeValueLower, 4) : '-'}
                            </ThemedText.Main>
                          </NoBorderTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </LightCard>

                  {ownsNFT &&
                  (feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0)) &&
                  currency0 &&
                  currency1 &&
                  (currency0.isNative || currency1.isNative) &&
                  !collectMigrationHash ? (
                    <AutoColumn gap="md">
                      <RowBetween>
                        <ThemedText.Main>
                          <Trans>Collect as WETH</Trans>
                        </ThemedText.Main>
                        <Toggle
                          id="receive-as-weth"
                          isActive={receiveWETH}
                          toggle={() => setReceiveWETH((receiveWETH) => !receiveWETH)}
                        />
                      </RowBetween>
                    </AutoColumn>
                  ) : null}
                </AutoColumn>
              </RowBetween>
            </LightCard>
          </AutoColumn>
        </ResponsiveRow>
        <DarkCard>
          <AutoColumn gap="md">
            <RowBetween>
              <RowFixed>
                <Label display="flex" style={{ marginRight: '12px' }}>
                  <Trans>Price range</Trans>
                </Label>
                <HideExtraSmall>
                  <>
                    <RangeBadge removed={removed} inRange={inRange} />
                    <span style={{ width: '8px' }} />
                  </>
                </HideExtraSmall>
              </RowFixed>
              <RowFixed>
                {currencyBase && currencyQuote && (
                  <RateToggle
                    currencyA={currencyBase}
                    currencyB={currencyQuote}
                    handleRateToggle={() => setManuallyInverted(!manuallyInverted)}
                  />
                )}
              </RowFixed>
            </RowBetween>

            <RowBetween>
              <LightCard padding="12px" width="100%" style={{ marginRight: '20px' }}>
                <CurrentPriceCard
                  inverted={inverted}
                  pool={pool}
                  currencyQuote={currencyQuote}
                  currencyBase={currencyBase}
                />
              </LightCard>

              <LightCard padding="12px" width="100%">
                <AutoColumn gap="8px" justify="center">
                  <ExtentsText>
                    <Trans>Min price</Trans>
                  </ExtentsText>
                  <ThemedText.MediumHeader textAlign="center">
                    {priceLower?.toSignificant(5)} <TickText>({tickLower})</TickText>
                  </ThemedText.MediumHeader>
                  <ExtentsText> {currencyQuote?.symbol + ' / ' + currencyBase?.symbol}</ExtentsText>
                  {inRange && (
                    <ThemedText.Small color={theme.text3}>
                      <Trans>Your position will be 100% {currencyBase?.symbol} at this price.</Trans>
                    </ThemedText.Small>
                  )}
                </AutoColumn>
              </LightCard>

              <div style={{ padding: '0 4px' }}>
                <DoubleArrow>⟷</DoubleArrow>
                <br />
                <SpreadRange
                  spreadRange={spreadRange}
                  tickUpper={tickUpper}
                  tickLower={tickLower}
                  tickCurrent={pool?.tickCurrent}
                  invert={manuallyInverted}
                />
              </div>
              <LightCard padding="12px" width="100%">
                <AutoColumn gap="8px" justify="center">
                  <ExtentsText>
                    <Trans>Max price</Trans>
                  </ExtentsText>
                  <ThemedText.MediumHeader textAlign="center">
                    {priceUpper?.toSignificant(5)} <TickText>({tickUpper})</TickText>
                  </ThemedText.MediumHeader>
                  <ExtentsText> {currencyQuote?.symbol + ' / ' + currencyBase?.symbol}</ExtentsText>

                  {inRange && (
                    <ThemedText.Small color={theme.text3}>
                      <Trans>Your position will be 100% {currencyQuote?.symbol} at this price.</Trans>
                    </ThemedText.Small>
                  )}
                </AutoColumn>
              </LightCard>
            </RowBetween>
          </AutoColumn>
        </DarkCard>

        {/* 因为有 仓位变化明细 这个存在，所以这两个都不需要了 */}
        {/* <ResponsiveRow align="flex-start">
          <AutoColumn gap="sm" style={{ width: '100%', height: '100%' }}>
            {/* <DarkCard>
            <LightCard padding="12px 16px">
              <RowBetween>
                <AutoColumn gap="md" style={{ width: '100%' }}>
                  <AutoColumn gap="md">
                    <Label>仓位数据统计</Label>
                  </AutoColumn>
                </AutoColumn>
              </RowBetween>
              <RowBetween>
                <AutoColumn gap="md" style={{ width: '100%' }}>
                  <PositionMintDetails id={parsedTokenId!.toNumber()} chainId={chainId} />
                </AutoColumn>
              </RowBetween>
            </LightCard>
          </AutoColumn>
        </ResponsiveRow> */}

        <ResponsiveRow align="center" justifyContent="center" style={{ justifyContent: 'center' }}>
          <PositionKLineChart
            symbol0={symbol0}
            symbol1={symbol1}
            tickLower={tickLower}
            tickUpper={tickUpper}
            decimals0={decimals0}
            decimals1={decimals1}
            tickCurrent={tickCurrent}
            poolAddress={poolAddress}
            mints={mints}
            burns={burns}
          />
        </ResponsiveRow>

        <ResponsiveRow align="flex-start">
          <AutoColumn gap="sm" style={{ width: '100%', height: '100%' }}>
            {/* <DarkCard> */}
            <LightCard padding="12px 16px" style={{ backgroundColor: 'white' }}>
              <RowBetween>
                <AutoColumn gap="md" style={{ width: '100%' }}>
                  <AutoColumn gap="md">
                    <Label>
                      <Trans>Position change details</Trans>
                    </Label>
                  </AutoColumn>
                </AutoColumn>
              </RowBetween>
              <RowBetween>
                <AutoColumn gap="md" style={{ width: '100%' }}>
                  {mints && burns && chainId && position ? (
                    <PositionTxDetails
                      key="PositionTxDetails"
                      chainId={chainId!}
                      // position={position}
                      token0={token0 ?? undefined}
                      token1={token1 ?? undefined}
                      mints={mints}
                      burns={burns}
                    />
                  ) : (
                    <LoadingRows>
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                    </LoadingRows>
                  )}
                </AutoColumn>
              </RowBetween>
            </LightCard>
          </AutoColumn>
        </ResponsiveRow>

        <ResponsiveRow align="flex-start">
          <AutoColumn gap="sm" style={{ width: '100%', height: '100%' }}>
            <LightCard padding="12px 16px" style={{ backgroundColor: 'white' }}>
              <RowBetween>
                <AutoColumn gap="md" style={{ width: '100%', marginBottom: '12px' }}>
                  <AutoColumn gap="md">
                    <Label>
                      <Trans>Pool infomation</Trans>
                    </Label>
                  </AutoColumn>
                </AutoColumn>
              </RowBetween>
              <RowBetween>
                <AutoColumn gap="md" style={{ width: '100%' }}>
                  <PoolInfoCard
                    token0Address={token0?.address}
                    token1Address={token1?.address}
                    fee={feeAmount.toString()}
                  />
                </AutoColumn>
              </RowBetween>
            </LightCard>
          </AutoColumn>
        </ResponsiveRow>
      </AutoColumn>
      <GetSimilarPositions
        chainId={chainId}
        poolAddress={poolAddress}
        token0={token0 ?? undefined}
        token1={token1 ?? undefined}
        feeAmount={feeAmount}
        tickLower={tickLower}
        tickUpper={tickUpper}
        price0={price0}
        price1={price1}
      />
    </PageWrapper>
  )
}
