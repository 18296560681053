import { getDefaultProvider } from '@ethersproject/providers'
// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import ContentCopyIcon from '@material-ui/icons/ContentCopy'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import MoreVert from '@material-ui/icons/MoreVert'
import { useWeb3React } from '@web3-react/core'
import React, { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components/macro'

import { ButtonPrimary } from '../../components/Button'
import ConfirmDialog from '../../components/ConfirmDialog'
import { useAccountManager } from '../../constants/accounts'
import { Account } from '../../hooks/useAccounts'
import useCopyClipboard from '../../hooks/useCopyClipboard'
import { useToggleWalletModal } from '../../state/application/hooks'
import { isAddress, shortenAddress } from '../../utils'
const PageTitle = styled.h2`
  text-align: left;
  vertical-align: bottom;
  width: 100%;
`
const AccountPageBox = styled.div`
  margin: 0 auto;
  width: 550px;
`
const AccountTypeTitle = styled.p`
  margin-top: 32px;
  margin-bottom: 6px;
  color: #bcc2cb;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  padding-bottom: 4px;
`
const SortAccountBox = styled.div`
  position: relative;
`
const AddAccountLink = styled.a`
  float: right;
  font-size: 14px;
  color: #3f51b5;
  vertical-align: bottom;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export default function Accounts() {
  // const [accounts, , removeAccount, editAccountRemark] = useObservedAccounts()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [handleAccount, setHandleAccount] = useState<string>('')
  const [isCopied, setCopied] = useCopyClipboard()
  const [handleRemark, setHandleRemark] = useState<string>('')
  const { accounts, removeAccount, editAccountRemark } = useAccountManager()
  function deleteAccount(account: string) {
    removeAccount(account)
  }
  function openDeleteDialog() {
    setDeleteDialogOpen(true)
    hideMoreMenu()
  }
  function openEditDialog() {
    const initHandleRemark = accounts.filter((item: Account) => item.account === handleAccount)[0].remark
    setHandleRemark(initHandleRemark)
    setEditDialogOpen(true)
    hideMoreMenu()
  }
  function closeDeleteDialog(account?: string) {
    setDeleteDialogOpen(false)
    if (account) deleteAccount(account)
  }
  function closeEditDialog(data?: any) {
    setEditDialogOpen(false)
    if (data) editAccountRemark(data.account, data.remark)
  }

  function handleCopy(account: string) {
    setHandleAccount(account)
    setCopied(account)
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)
  function showMoreMenu(event: React.MouseEvent<HTMLElement>, account: string) {
    setAnchorEl(event.currentTarget)
    setHandleAccount(account)
  }
  function hideMoreMenu() {
    setAnchorEl(null)
  }
  const { account } = useWeb3React()
  const toggleWalletModal = useToggleWalletModal()

  //add account
  const { addAccount, selectedAccount, setSelectedAccount, updateAccountSort } = useAccountManager()
  const [newAccount, setNewAccount] = useState<string>('')
  const [accountErr, setAccountErr] = useState<boolean>(false)
  const [showAddAccountDialog, setShowAddAccountDialog] = useState<boolean>(false)
  const [remark, setRemark] = useState<string>('')
  const [accountSortEnd, setAccountSortEnd] = useState<number>(0)
  const [accountSortStart, setAccountSortStart] = useState<number>(0)
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setNewAccount(event.target.value)
    // checkAccount(event.target.value)
  }
  function checkAccount(account: string) {
    if (isAddress(account)) {
      setAccountErr(false)
      return true
    } else {
      setAccountErr(true)
      return false
    }
  }
  function AddAccount(account?: string) {
    if (account) {
      const check: boolean = checkAccount(account)
      if (check && newAccount.trim() !== '') {
        setAccountErr(false)
        addAccount(newAccount.trim(), remark)
        setShowAddAccountDialog(false)
        if (!selectedAccount) {
          setSelectedAccount(newAccount.trim())
        }
      } else {
        setAccountErr(true)
        setShowAddAccountDialog(true)
      }
      setNewAccount('')
      setRemark('')
    } else {
      setAccountErr(false)
      setShowAddAccountDialog(false)
    }
  }
  function showAddDialog() {
    setShowAddAccountDialog(true)
  }
  function remarkChange(event: React.ChangeEvent<HTMLInputElement>) {
    setRemark(event.target.value)
  }
  function editRemarkChange(event: React.ChangeEvent<HTMLInputElement>) {
    setHandleRemark(event.target.value)
  }
  function handleDragEnter(result: any) {
    setAccountSortStart(result.source.index)
  }
  function handleDragEnd() {
    updateAccountSort(accountSortStart, accountSortEnd)
  }
  function handleDragOver(result: any) {
    setAccountSortEnd(result.destination.index)
  }
  const grid = 8
  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? '#eee' : '#ffffff',
    ...draggableStyle,
  })
  const getListStyle = (styles: any) => ({
    background: '#eee',
    width: 550,
    flexDirection: 'column',
    overflow: 'auto',
    ...styles,
  })

  return (
    <AccountPageBox>
      <PageTitle>
        <Trans>Manage Wallets</Trans>
      </PageTitle>
      <AccountTypeTitle>
        <Trans>MY WALLETS</Trans>
      </AccountTypeTitle>
      {!account ? (
        <ButtonPrimary style={{ marginTop: '2em', padding: '8px 16px' }} onClick={toggleWalletModal}>
          <Trans>Connect a wallet</Trans>
        </ButtonPrimary>
      ) : (
        <List
          key={account}
          dense={true}
          sx={{
            width: '550px',
            border: '1px solid #BCC2CB',
            padding: '12px 14px',
            marginBottom: '12px',
            borderRadius: '8px',
            cursor: 'pointer',
            ':hover': {
              borderColor: '#1C1F26',
            },
          }}
        >
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ marginRight: '8px' }}>{subStr(account.toUpperCase())}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={shortenAddress(account)} />
            <ListItemSecondaryAction>
              <Tooltip
                placement="top"
                open={isCopied && account === handleAccount}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={t`Copied`}
                arrow
              >
                <IconButton edge="end" aria-label="copy" onClick={() => handleCopy(account)}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      )}
      <AccountTypeTitle>
        <Trans>WATCHLIST</Trans>{' '}
        <AddAccountLink onClick={showAddDialog}>
          <Trans>Track any wallet</Trans>
        </AddAccountLink>
      </AccountTypeTitle>
      <DragDropContext onDragStart={handleDragEnter} onDragEnd={handleDragEnd} onDragUpdate={handleDragOver}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided: any, snapshot: any) => (
            <SortAccountBox
              {...provided.droppableProps}
              // 为了使 droppable 能够正常工作必须 绑定到最高可能的DOM节点中provided.innerRef.
              ref={provided.innerRef}
              style={getListStyle(snapshot)}
            >
              {accounts.map((item: Account, index: number) => {
                return (
                  <Draggable key={index} draggableId={index.toString()} index={index}>
                    {(provided: any, snapshot: any) => (
                      <List
                        key={item.account}
                        dense={true}
                        data-index={index}
                        sx={{
                          width: '550px',
                          border: '1px solid #BCC2CB',
                          padding: '12px 14px',
                          marginBottom: '12px',
                          borderRadius: '8px',
                          cursor: 'grab',
                          boxShadow: 'rgb(46 63 116 / 6%) 0px 2px 4px',
                          ':hover': {
                            borderColor: '#1C1F26',
                          },
                        }}
                        draggable={true}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onDragOver={() => {
                          console.log(1)
                        }}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <ListItem key={`item_${item}`}>
                          <MoreVert sx={{ marginLeft: '-20px' }} />
                          <ListItemAvatar>
                            <AccountImg account={item.account} />
                          </ListItemAvatar>
                          <ListItemText primary={shortenAddress(item.account)} secondary={item.remark} />
                          <ListItemSecondaryAction>
                            <Tooltip
                              placement="top"
                              open={isCopied && item.account === handleAccount}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={t`Copied`}
                              arrow
                            >
                              <IconButton edge="end" aria-label="copy" onClick={() => handleCopy(item.account)}>
                                <ContentCopyIcon />
                              </IconButton>
                            </Tooltip>
                            <IconButton
                              aria-label="more"
                              id="more-button"
                              aria-controls="more"
                              aria-expanded={openMenu ? 'true' : undefined}
                              aria-haspopup="true"
                              onClick={(event) => showMoreMenu(event, item.account)}
                              sx={{ marginLeft: '8px' }}
                            >
                              <MoreHorizIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </SortAccountBox>
          )}
        </Droppable>
      </DragDropContext>

      <ConfirmDialog onClose={closeDeleteDialog} value={handleAccount} open={deleteDialogOpen} title="Remove">
        <span>
          <Trans>Remove address {handleAccount} ?</Trans>
        </span>
      </ConfirmDialog>
      <ConfirmDialog
        onClose={closeEditDialog}
        value={{ account: handleAccount, remark: handleRemark }}
        open={editDialogOpen}
        title={t`Rename`}
      >
        <TextField
          sx={{ width: '100%' }}
          label={t`Rename`}
          value={handleRemark}
          inputProps={{ 'aria-label': t`Rename` }}
          onChange={editRemarkChange}
        />
        <span style={{ marginTop: '12px' }}>
          <Trans>Address：</Trans>
          {handleAccount}
        </span>
      </ConfirmDialog>
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={hideMoreMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={openEditDialog} disableRipple>
          <EditOutlinedIcon sx={{ marginRight: '8px' }} />
          <Trans>Rename</Trans>
        </MenuItem>
        <MenuItem onClick={openDeleteDialog} disableRipple>
          <DeleteOutlinedIcon sx={{ marginRight: '8px' }} />
          <Trans>Remove</Trans>
        </MenuItem>
      </Menu>
      <ConfirmDialog
        onClose={AddAccount}
        value={newAccount}
        open={showAddAccountDialog}
        title={t`Add Watchlist Address`}
      >
        <TextField
          sx={{ width: '100%' }}
          label={t`Paste Ethereum address`}
          value={newAccount}
          inputProps={{ 'aria-label': t`Add wallet` }}
          onChange={handleChange}
          error={accountErr}
          helperText={accountErr ? t`Not checksum address` : ' '}
        />
        <TextField
          sx={{ width: '100%' }}
          label={t`Rename`}
          value={remark}
          inputProps={{ 'aria-label': t`Rename` }}
          onChange={remarkChange}
        />
      </ConfirmDialog>
    </AccountPageBox>
  )
}

function AccountImg({ account }: { account: string }) {
  const provider = getDefaultProvider()
  const [isContract, setIsContract] = useState(false)
  provider.getCode(account).then((res) => {
    setIsContract(res !== '0x')
  })
  return (
    <>
      {isContract ? (
        <Avatar>
          <InsertDriveFileOutlinedIcon />
        </Avatar>
      ) : (
        <Avatar sx={{ marginRight: '8px' }}>{subStr(account.toUpperCase())}</Avatar>
      )}
    </>
  )
}

function subStr(str: string) {
  return str.substr(2, 2)
}
