import { getDefaultProvider } from '@ethersproject/providers'
// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import ContentCopyIcon from '@material-ui/icons/ContentCopy'
import { useWeb3React } from '@web3-react/core'
import { memo, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'

import { useAccountManager } from '../../constants/accounts'
import useCopyClipboard from '../../hooks/useCopyClipboard'
import { ExternalLink } from '../../theme'
import { isAddress, shortenAddress } from '../../utils'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'

interface ShowOwnerProps {
  account: string
  showTitle?: boolean
  style?: showOwnerStyle //string | ReactElement
}

const defaultTitle = (
  <span className="title" style={{ marginRight: 0 }}>
    <Trans>Owner</Trans>:{' '}
  </span>
)

type showOwnerStyle = 'defaultTitle'

const OwnerAccountBox = styled.p`
  margin: 0;
  .account {
    margin-right: 4px;
  }
  .remark {
    color: #aaaaaa;
    margin-right: 4px;
  }
`

function ShowOwner({ account, showTitle = true, style = 'defaultTitle' }: ShowOwnerProps) {
  const { chainId, account: myAccount } = useWeb3React()
  const [ownerRemark, setOwnerRemark] = useState<undefined | string>(undefined)
  // 这里会导致不断重绘，最好换成redux？但是要考虑用户之前添加的地址不能丢失啊
  const { accounts } = useAccountManager()
  const [isCopied, setCopied] = useCopyClipboard()
  const [chainIdBuynetwork, setChainIdBuynetwork] = useState<number | undefined>(undefined)
  const provider = getDefaultProvider()

  const title = useMemo(() => {
    switch (style) {
      case 'defaultTitle':
        return defaultTitle
      default:
        return defaultTitle
    }
  }, [style])

  useEffect(() => {
    const findAccount = accounts.filter((item) => item.account.toLowerCase() === account?.toLowerCase())[0]
    if (findAccount) {
      setOwnerRemark(findAccount.remark)
    } else {
      setOwnerRemark(undefined)
    }
    provider.getNetwork().then((res) => {
      setChainIdBuynetwork(res.chainId)
    })
  }, [account, myAccount, chainId])

  function handleCopy(_account: string) {
    const parsed = isAddress(_account)
    return parsed ? setCopied(parsed) : null
  }
  if (!isAddress(account)) return null
  return (
    <OwnerAccountBox>
      {showTitle ? title : null}
      <span className="account">
        {typeof chainIdBuynetwork === 'number' ? (
          <ExternalLink href={getExplorerLink(chainIdBuynetwork, account, ExplorerDataType.ADDRESS)}>
            {account ? shortenAddress(account) : ''}
          </ExternalLink>
        ) : null}
      </span>
      {ownerRemark ? (
        <span className="remark">({ownerRemark})</span>
      ) : account && myAccount && myAccount.toLowerCase() === account.toLowerCase() ? (
        <span className="remark">
          (<Trans>My address</Trans>){' '}
        </span>
      ) : null}
      <Tooltip
        placement="top"
        open={isCopied}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={t`Copied`}
        arrow
      >
        <IconButton edge="end" aria-label="copy" onClick={() => handleCopy(account)} style={{ padding: '4px' }}>
          <ContentCopyIcon sx={{ fontSize: 16 }} />
        </IconButton>
      </Tooltip>
    </OwnerAccountBox>
  )
}

export default memo(ShowOwner)
