import React from 'react'

import { Account } from '../hooks/useAccounts'

export type AccountsContextData = {
  selectedAccount: string
  setSelectedAccount: (data: string) => void
  accounts: Account[]
  addAccount: (account: string, remark?: string) => void
  removeAccount: (data: string) => void
  editAccountRemark: (account: string, remark: string) => void
  updateAccountSort: (sortStart: number, sortEnd: number) => void
}

export const AccountsManager = React.createContext<AccountsContextData>({
  selectedAccount: '',
  setSelectedAccount: (data) => {
    console.log(data)
  },
  accounts: [],
  addAccount: (account: string, remark?: string) => {
    console.log(account, remark)
  },
  removeAccount: (data: string) => {
    console.log(data)
  },
  editAccountRemark: (account, remark) => {
    console.log(account, remark)
  },
  updateAccountSort: (sortStart, sortEnd) => {
    console.log(sortStart, sortEnd)
  },
})

export const useAccountManager = () => React.useContext(AccountsManager)

export type AccountOverviewContext = {
  accountOverview: any[]
  setAccountOverview: (data: any[]) => void
}

export const AccountOverviewManager = React.createContext<AccountOverviewContext>({
  accountOverview: [],
  setAccountOverview: (data) => {
    console.log(data)
  },
})

export const useAccountOverviewManager = () => React.useContext(AccountOverviewManager)
