// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { Position } from '@uniswap/v3-sdk'
import BigNumber from 'bignumber.js'
import Loader from 'components/Loader'
import QuestionHelper from 'components/QuestionHelper'
import { BurnSchema, MintSchema } from 'data/GRTResultSchema'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { getSqrtPriceByTick } from 'utils/fromTickToPrice'

import { ThemedText } from '../../theme'
import { unwrappedToken } from '../../utils/unwrappedToken'
import Badge from '../Badge'
import CurrencyLogo from '../CurrencyLogo'
import { RowBetween, RowFixed } from '../Row'

interface IPops {
  chainId: number | undefined
  position: Position | undefined
  mints: MintSchema[] | undefined
  burns: BurnSchema[] | undefined
  curFiatValueOfLiquidity: string | undefined
  price0: string | undefined
  price1: string | undefined
  inverted: boolean | undefined
}

function RateOfReturnTip() {
  return (
    <>
      <span>
        该涨跌幅是基于成本变化（不考虑手续费收入）。假设第一次加仓$100,000，第二次减仓$20,000，则成本是$80,000。这时实时仓位总值是$100,000，则利润为：$20,000，涨跌幅为：+25%
      </span>
    </>
  )
}

const CostChangeBox = styled.div`
  display: inline;
  font-size: 20px;
  margin-left: 8px;
`
const NoBorderTableCell = styled(TableCell)`
  border-bottom: none;
  padding: 8px;
`

export default function CostChange(props: IPops) {
  const { chainId, position, mints, burns, curFiatValueOfLiquidity, price0, price1 } = props
  const tickLower = position?.tickLower
  const tickUpper = position?.tickUpper
  const token0 = position?.pool.token0
  const token1 = position?.pool.token1
  const token0Decimals = token0?.decimals
  const token1Decimals = token1?.decimals
  const curLiquidity = position?.liquidity.toString()
  const tickCurrent = position?.pool.tickCurrent
  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined
  const [ratioForUi, setRatioForUi] = useState(0)

  const { increaseAmount, increaseAmountUSD, decreaseAmount, decreaseAmountUSD } = useMemo(() => {
    if (!mints || !burns) {
      return {
        increaseAmount: undefined,
        increaseAmountUSD: undefined,
        decreaseAmount: undefined,
        decreaseAmountUSD: undefined,
      }
    }
    const mintsCost = mints.reduce(
      (t, v) => {
        t.amount = new BigNumber(t.amount).plus(new BigNumber(v.amount)).toString()
        t.amountUSD = new BigNumber(t.amountUSD).plus(new BigNumber(v.amountUSD)).toString()
        return t
      },
      { amount: '0', amountUSD: '0' }
    )
    const burnsCost = burns.reduce(
      (t, v) => {
        t.amount = new BigNumber(t.amount).plus(new BigNumber(v.amount)).toString()
        t.amountUSD = new BigNumber(t.amountUSD).plus(new BigNumber(v.amountUSD)).toString()
        return t
      },
      { amount: '0', amountUSD: '0' }
    )
    return {
      increaseAmount: mintsCost.amount,
      increaseAmountUSD: mintsCost.amountUSD,
      decreaseAmount: burnsCost.amount,
      decreaseAmountUSD: burnsCost.amountUSD,
    }
  }, [mints, burns])

  function getRatio(tickCurrent: number, tickLower: number, tickUpper: number): number {
    if (!(tickCurrent > tickLower)) {
      return new BigNumber(1).toNumber()
    } else if (!(tickCurrent < tickUpper)) {
      return new BigNumber(0).toNumber()
    }
    const a = getSqrtPriceByTick(tickLower)
    const b = getSqrtPriceByTick(tickUpper)
    const c = getSqrtPriceByTick(tickCurrent)
    const a_ = Number.parseFloat(tickLower.toString())
    const b_ = Number.parseFloat(tickUpper.toString())
    const c_ = Number.parseFloat(tickCurrent.toString())

    const ratio = Math.floor((1 / ((Math.sqrt(a_ * b_) - Math.sqrt(b_ * c_)) / (c_ - Math.sqrt(b_ * c_)) + 1)) * 100)
    setRatioForUi(ratio)

    // const a = TickMath.Math.floor((1 / ((Math.sqrt(a * b) - Math.sqrt(b * c)) / (c - Math.sqrt(b * c)) + 1)) * 100) // const { a, b, c } = getPriceByTick(tickCurrent, tickLower, tickUpper)
    const process1 = a.multipliedBy(b)
    const process2 = b.multipliedBy(c)
    const process3 = process1.minus(process2)
    const process4 = c.pow(2).minus(b.multipliedBy(c))
    const process5 = process3.dividedBy(process4).plus(1)
    return new BigNumber(1).dividedBy(process5).toNumber()
  }

  const { bn_0, bn_1, X96 } = useMemo(() => {
    return { bn_0: new BigNumber(0), bn_1: new BigNumber(1), X96: new BigNumber(2).pow(96) }
  }, [])

  const { currentLiquidity, holdingUSD, precision0, precision1 } = useMemo(() => {
    if (increaseAmountUSD && decreaseAmountUSD && curLiquidity && token0Decimals && token1Decimals) {
      const holdingUSD = new BigNumber(increaseAmountUSD).minus(decreaseAmountUSD)
      const currentLiquidity = new BigNumber(curLiquidity)
      const precision0 = new BigNumber(10).pow(token0Decimals)
      const precision1 = new BigNumber(10).pow(token1Decimals)
      return {
        currentLiquidity,
        holdingUSD,
        precision0,
        precision1,
      }
    }
    return {
      currentLiquidity: undefined,
      holdingUSD: undefined,
      precision0: undefined,
      precision1: undefined,
    }
  }, [
    position,
    increaseAmountUSD,
    decreaseAmountUSD,
    curLiquidity,
    token0Decimals,
    token1Decimals,
    tickLower,
    tickUpper,
  ])

  const ratio = useMemo(() => {
    if (!tickCurrent || !tickLower || !tickUpper) {
      return undefined
    }
    return getRatio(tickCurrent, tickLower, tickUpper)
  }, [tickCurrent, tickLower, tickUpper])

  // 计算平均持仓成本
  const { avgHoldingToken0, avgHoldingToken1 } = useMemo(() => {
    if (
      !holdingUSD ||
      !currentLiquidity ||
      !tickCurrent ||
      !tickLower ||
      !tickUpper ||
      !precision0 ||
      !precision1 ||
      !ratio
    ) {
      return { avgHoldingToken0: undefined, avgHoldingToken1: undefined }
    }
    // const beginTime = +new Date()
    // const ratio = getRatio(tickCurrent, tickLower, tickUpper)
    let amount0 = bn_0
    let amount1 = bn_0
    const sqrt_a = getSqrtPriceByTick(tickLower)
    const sqrt_b = getSqrtPriceByTick(tickUpper)
    if (tickCurrent < tickLower) {
      const temp = new BigNumber(1).dividedBy(sqrt_a).minus(new BigNumber(1).dividedBy(sqrt_b))
      amount0 = currentLiquidity.multipliedBy(temp)
    } else if (tickCurrent > tickUpper) {
      amount1 = currentLiquidity.multipliedBy(sqrt_b.minus(sqrt_a))
    } else {
      const sqrt_c = getSqrtPriceByTick(tickCurrent)
      const temp = new BigNumber(1).dividedBy(sqrt_c).minus(new BigNumber(1).dividedBy(sqrt_b))
      amount0 = currentLiquidity.multipliedBy(temp)
      amount1 = currentLiquidity.multipliedBy(sqrt_c.minus(sqrt_a))
    }

    amount0 = amount0.dividedBy(precision0)
    amount1 = amount1.dividedBy(precision1)
    const avgHoldingToken0 = holdingUSD.multipliedBy(ratio).dividedBy(amount0)
    const avgHoldingToken1 = holdingUSD.multipliedBy(new BigNumber(1).minus(ratio)).dividedBy(amount1)
    // const endTime = +new Date()
    return { avgHoldingToken0, avgHoldingToken1 }
  }, [
    holdingUSD,
    currentLiquidity,
    curFiatValueOfLiquidity,
    tickCurrent,
    tickLower,
    tickUpper,
    precision0,
    precision1,
    ratio,
  ])

  return (
    <Paper sx={{ width: '100%', marginTop: '8px' }}>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <NoBorderTableCell />
            <NoBorderTableCell align="right">
              <Trans>Amount</Trans>
            </NoBorderTableCell>
            <NoBorderTableCell align="right">
              <RowBetween style={{ justifyContent: 'flex-end', width: 'auto' }}>
                <RowFixed>
                  <Trans>Avg holding cost</Trans>
                  <QuestionHelper
                    text={t`If a certain currency is a new currency when increasing/decreasing a position, the calculation of the average holding cost will cause an error in the calculation of the cost due to the inability to obtain the price of the currency`}
                  />
                </RowFixed>
              </RowBetween>
            </NoBorderTableCell>
            <NoBorderTableCell align="right">
              <Trans>Current price</Trans>
            </NoBorderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <NoBorderTableCell component="th" scope="row">
              <RowFixed>
                <CurrencyLogo currency={currency0} size={'20px'} style={{ marginRight: '0.5rem' }} />
                <ThemedText.Main>{token0?.symbol}</ThemedText.Main>
              </RowFixed>
            </NoBorderTableCell>
            <NoBorderTableCell align="right">
              <ThemedText.Main style={{ display: 'inline-block' }}>
                {position?.amount0.toSignificant(4)}
              </ThemedText.Main>
              {typeof ratio === 'number' ? (
                <Badge style={{ marginLeft: '10px' }}>
                  <ThemedText.Main fontSize={11}>
                    <Trans>{new BigNumber(ratio).times(100).toFixed(0)}%</Trans>
                  </ThemedText.Main>
                </Badge>
              ) : null}
            </NoBorderTableCell>
            <NoBorderTableCell align="right">
              <ShowPrice key="avgprice0" price={avgHoldingToken0} />
            </NoBorderTableCell>
            <NoBorderTableCell align="right">
              <ShowPrice key="price0" price={price0} />
            </NoBorderTableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <NoBorderTableCell component="th" scope="row">
              <RowFixed>
                <CurrencyLogo currency={currency1} size={'20px'} style={{ marginRight: '0.5rem' }} />
                <ThemedText.Main>{token1?.symbol}</ThemedText.Main>
              </RowFixed>
            </NoBorderTableCell>
            <NoBorderTableCell align="right">
              <ThemedText.Main style={{ display: 'inline-block' }}>
                {position?.amount1.toSignificant(4)}
              </ThemedText.Main>
              {typeof ratio === 'number' ? (
                <Badge style={{ marginLeft: '10px' }}>
                  <ThemedText.Main fontSize={11}>
                    <Trans>{new BigNumber(1).minus(new BigNumber(ratio)).times(100).toFixed(0)}%</Trans>
                  </ThemedText.Main>
                </Badge>
              ) : null}
            </NoBorderTableCell>
            <NoBorderTableCell align="right">
              <ShowPrice key="avgprice1" price={avgHoldingToken1} />
            </NoBorderTableCell>
            <NoBorderTableCell align="right">
              <ShowPrice key="price1" price={price1} />
            </NoBorderTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  )
}

interface IShowPriceProps {
  price: string | BigNumber | undefined
}

function ShowPrice(props: IShowPriceProps) {
  const { price } = props
  let newPrice: string | undefined = undefined
  if (price) {
    let temp: BigNumber
    if (typeof price === 'string') {
      temp = new BigNumber(price)
    } else {
      temp = price
    }
    if (temp.isNaN() || !temp.isFinite()) {
      newPrice = '$-'
    } else {
      newPrice = new BigNumber(price).toNumber().toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        useGrouping: true,
        maximumFractionDigits: 2,
      })
    }
  }

  return <>{!newPrice ? <Loader /> : newPrice}</>
}
