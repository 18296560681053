import { createReducer } from '@reduxjs/toolkit'
import { Currency, Price, Token } from '@uniswap/sdk-core'
import { USDC_MAINNET as USDC } from 'constants/tokens'

import { updateTokenPrice } from './actions'

export interface TokenPrice {
  symbol: string | undefined
  address: string
  denominator: string
  numerator: string
  price: string
}

export interface TokenState {
  tokensPrice: string //Map<string, TokenPrice>
}

const initPriceToken: TokenPrice = {
  symbol: 'FakeToken',
  address: USDC.address,
  denominator: '1',
  numerator: '1',
  price: '1',
}

const initialState: TokenState = {
  tokensPrice: JSON.stringify(Array.from(new Map<string, TokenPrice>([['0', initPriceToken]]).entries())),
}

export function combineChainIdAndTokenAddressForKey(chainId: string | undefined, address: string | undefined): string {
  if (!address) {
    return ''
  }
  return chainId === undefined ? 'undefined' : chainId.toLowerCase() + address.toLowerCase()
}

export function getTokenPriceParamByTokenAndPrice(token: Token, price: Price<Currency, Token> | undefined): TokenPrice {
  const data: TokenPrice = {
    symbol: token.symbol,
    address: token.address,
    denominator: '1',
    numerator: '1',
    price: '1.00',
  }
  if (price) {
    data.denominator = price.denominator.toString()
    data.numerator = price.numerator.toString()
    data.price = price.toFixed(2)
  }
  return data
}

export default createReducer<TokenState>(initialState, (builder) =>
  builder.addCase(
    updateTokenPrice,
    (state, { payload: { chainId, symbol, address, denominator, numerator, price } }) => {
      if (!price) {
        return state
      }
      const tokensPrice = state.tokensPrice
      const data: TokenPrice = {
        symbol: symbol ?? 'Unknown',
        address,
        denominator,
        numerator,
        price,
      }
      const key = combineChainIdAndTokenAddressForKey(chainId, address)
      const newTokenPrice = new Map<string, TokenPrice>(JSON.parse(tokensPrice))
      newTokenPrice.set(key, data)
      return { ...state, tokensPrice: JSON.stringify(Array.from(newTokenPrice.entries())) }
    }
  )
)
