import { Trans } from '@lingui/macro'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
// import { useToken } from 'hooks/Tokens'
import Badge from 'components/Badge'
import React, { memo, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { dollarFormat, percentFormat } from 'utils/dollarFormat'

import { ButtonPrimary } from '../../components/Button'
import { useAccountManager, useAccountOverviewManager } from '../../constants/accounts'
import { useToggleWalletModal } from '../../state/application/hooks'
import { ThemedText } from '../../theme'

const OverviewBox = styled.div`
  width: 600px;
  margin: 0 auto;
`

const OverviewTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: #333;
  padding-left: 16px;
`
const TokenImage = styled.img`
  width: 36px;
`

function Overview() {
  const { selectedAccount } = useAccountManager()
  const toggleWalletModal = useToggleWalletModal()
  const { accountOverview } = useAccountOverviewManager()
  const [totalValue, setTotalValue] = useState<number | undefined>()
  useEffect(() => {
    // 如果已经有值了，则不需要再计算了
    if (totalValue || accountOverview.length === 0) return
    const totalUSD = accountOverview.reduce((t, v) => {
      return t + (v.value ? Number(v.value) : 0)
    }, 0)
    setTotalValue(totalUSD)
  }, [accountOverview])

  useEffect(() => {
    setTotalValue(undefined)
  }, [selectedAccount])

  return (
    <OverviewBox>
      <OverviewTitle>
        <Trans>Overview</Trans>
        <ThemedText.Small>
          <ThemedText.Gray>
            <Trans>Only supports Ethereum</Trans>
          </ThemedText.Gray>
        </ThemedText.Small>
        {typeof totalValue === 'number' ? `(${dollarFormat(totalValue)})` : ''}
      </OverviewTitle>
      {!selectedAccount ? (
        <ButtonPrimary style={{ marginTop: '2em', padding: '8px 16px' }} onClick={toggleWalletModal}>
          <Trans>Connect a wallet</Trans>
        </ButtonPrimary>
      ) : (
        <List dense={true} key={'assets_list'}>
          {accountOverview.map((info, index) => {
            return (
              <div key={`div_${index}`}>
                <ListItem key={index}>
                  <ListItemAvatar>
                    {info.image ? (
                      <TokenImage src={info.image} alt="" />
                    ) : (
                      <NotInterestedIcon sx={{ fontSize: '36px' }} />
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${info.symbol} ( ${info.name} )`}
                    secondary={`${info.balance} ${
                      info.rate !== undefined ? ` · ${dollarFormat(info.rate as string, '')}` : ''
                    }`}
                  />
                  <IconButton
                    disabled
                    edge="end"
                    aria-label="delete"
                    size="small"
                    style={{ color: '#666666', alignItems: 'top' }}
                  >
                    {info.value
                      ? `$${Number(info.value).toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 4,
                        })}`
                      : '-'}
                  </IconButton>
                  {totalValue && info.value ? (
                    <Badge style={{ marginRight: '8px' }}>{percentFormat(Number(info.value) / totalValue)}%</Badge>
                  ) : (
                    ''
                  )}
                </ListItem>
                <Divider />
              </div>
            )
          })}
        </List>
      )}
    </OverviewBox>
  )
}

export default memo(Overview)
