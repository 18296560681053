import { Trans } from '@lingui/macro'
import Stack from '@material-ui/core/Stack'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import { BigNumber } from 'bignumber.js'
import React from 'react'
import styled from 'styled-components/macro'

import { useAccountOverviewManager } from '../../constants/accounts'
import { ThemedText } from '../../theme'

const PoolAccountBox = styled.div`
  width: 55%;
`
const TotalAssets = styled.div`
  span {
    display: inline-block;
    width: 100%;
    &.title {
      font-size: 14px;
      font-weight: 400;
      color: #1c1f26;
      line-height: 14px;
    }
    &.content {
      font-size: 24px;
      font-weight: 600;
      color: #1c1f26;
      line-height: 24px;
      margin-top: 6px;
    }
  }
`
const MainAssetsBox = styled.div`
  margin-top: 20px;
  .title {
    font-size: 14px;
    font-weight: 400;
    color: #1c1f26;
    line-height: 14px;
    margin-bottom: 10px;
  }
`
const MainAssetsItem = styled.div`
  width: 16.6%;
  p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.symbol {
      font-size: 12px;
      font-weight: 400;
      color: #bcc2cb;
      line-height: 12px;
    }
    &.balance {
      font-size: 14px;
      font-weight: 500;
      color: #1c1f26;
      line-height: 14px;
      margin-top: 10px;
    }
    &.value {
      font-size: 12px;
      font-weight: 400;
      color: #bcc2cb;
      line-height: 12px;
      margin-top: 2px;
    }
  }
`
const TokenImage = styled.img`
  width: 12px;
  margin-right: 4px;
`

export function PoolAccountInfo() {
  const { accountOverview } = useAccountOverviewManager()
  let totalAssets = new BigNumber(0)
  accountOverview.forEach((item) => {
    totalAssets = totalAssets.plus(new BigNumber(item.value))
  })
  return (
    <PoolAccountBox>
      <TotalAssets>
        <span className="title">
          <Trans>Wallet</Trans>
          <ThemedText.Small>
            <ThemedText.Gray>
              <Trans>Only supports Ethereum</Trans>
            </ThemedText.Gray>
          </ThemedText.Small>
        </span>
        <span className="content">
          {totalAssets.toNumber().toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            useGrouping: true,
            maximumFractionDigits: 2,
          })}
        </span>
      </TotalAssets>
      <MainAssetsBox>
        <p className="title">
          <Trans>Main Assets</Trans>
        </p>
      </MainAssetsBox>
      <Stack direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={2}>
        {accountOverview.map((token, index) => {
          return index < 6 ? (
            <MainAssetsItem key={index}>
              <p className="symbol" title={token.symbol}>
                {token.image ? (
                  <TokenImage src={token.image} alt="" />
                ) : (
                  <NotInterestedIcon sx={{ fontSize: '12px', marginRight: '4px' }} />
                )}
                {token.symbol}
              </p>
              <p className="balance" title={token.balance}>
                {token.balance}
              </p>
              <p className="value" title={token.value}>
                ${' '}
                {Number(token.value).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                })}
              </p>
            </MainAssetsItem>
          ) : null
        })}
      </Stack>
    </PoolAccountBox>
  )
}
