import { Token } from '@uniswap/sdk-core'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppDispatch, AppState } from './../index'
import { updateTokenPrice } from './actions'
import { combineChainIdAndTokenAddressForKey, TokenPrice } from './reducer'

export function useTokensState(): AppState['tokens'] {
  return useSelector<AppState, AppState['tokens']>((state) => state.tokens)
}

export function useGetTokenPrice(chainId: string | undefined, token: Token | undefined): TokenPrice | undefined {
  const key = combineChainIdAndTokenAddressForKey(chainId, token?.address)
  const tokenPriceState = useTokensState().tokensPrice

  return useMemo(() => {
    const tokenPriceMap = new Map<string, TokenPrice>(JSON.parse(tokenPriceState))
    return tokenPriceMap.get(key)
  }, [tokenPriceState])
}

export function useUpdateTokenData(): {
  onUpdateTokenPrice: (
    chainId: string | undefined,
    symbol: string | undefined,
    address: string,
    denominator: string,
    numerator: string,
    price: string
  ) => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const onUpdateTokenPrice = useCallback(
    (chainId, symbol, address, denominator, numerator, price) => {
      dispatch(
        updateTokenPrice({
          chainId,
          symbol,
          address,
          denominator,
          numerator,
          price,
        })
      )
    },
    [dispatch]
  )

  return { onUpdateTokenPrice }
}
