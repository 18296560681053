import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { useCallback, useEffect } from 'react'

import { getAddressInfo } from '../../apis/ethplorer'
import { useAccountManager, useAccountOverviewManager } from '../../constants/accounts'

export function AccountOverview() {
  const { chainId } = useWeb3React()
  const { selectedAccount } = useAccountManager()
  const { setAccountOverview } = useAccountOverviewManager()

  function sortKey(array: any[], key: string) {
    return array.sort((a, b) => {
      const x = a[key]
      const y = b[key]
      return x > y ? -1 : x < y ? 1 : 0
    })
  }
  const getInfo = useCallback(() => {
    const chainId_ = chainId ? chainId : 1
    getAddressInfo(selectedAccount, chainId_).then((res) => {
      if (res.status === 200) {
        const data_ = res.data
        let ethPrice = new BigNumber(0)
        if (data_.ETH.price) {
          ethPrice = new BigNumber(data_.ETH.rawBalance).times(data_.ETH.price.rate).dividedBy(10 ** 18)
        }
        const tokens = [
          {
            symbol: 'ETH',
            name: 'Ethereum',
            balance: new BigNumber(data_.ETH.balance.toFixed(6)).toNumber(),
            value: new BigNumber(ethPrice.toFixed(2)).toNumber(),
            rate: new BigNumber(new BigNumber(data_.ETH.price.rate).toFixed(2)).toNumber(),
            image: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
            price: data_.ETH.price,
          },
        ]
        if (data_.tokens) {
          res.data.tokens.forEach(async (token: any) => {
            try {
              let tokenBalance, tokenValue
              if (token.tokenInfo.decimals) {
                tokenBalance = new BigNumber(token.rawBalance).dividedBy(new BigNumber(10 ** token.tokenInfo.decimals))
              } else {
                tokenBalance = new BigNumber(token.balance)
              }
              if (token.tokenInfo.price) {
                tokenValue = tokenBalance.times(token.tokenInfo.price.rate)
              } else {
                tokenValue = new BigNumber(0)
              }
              const tokenImage = token.tokenInfo.image ? `https://ethplorer.io${token.tokenInfo.image}` : undefined
              tokens.push({
                ...token.tokenInfo,
                balance: new BigNumber(tokenBalance.toFixed(6)).toNumber(),
                value: new BigNumber(tokenValue.toFixed(2)).toNumber(),
                rate: token.tokenInfo.price
                  ? new BigNumber(token.tokenInfo.price.rate.toFixed(2)).toNumber()
                  : undefined,
                image: tokenImage,
              })
            } catch (e) {
              console.log(e)
            }
          })
        }
        setAccountOverview(sortKey(tokens, 'value'))
      }
    })
  }, [chainId, selectedAccount, setAccountOverview])
  useEffect(() => {
    if (!selectedAccount) return
    getInfo()
    const getInfoInterval = setInterval(() => {
      getInfo()
    }, 30000)
    return () => clearInterval(getInfoInterval)
  }, [selectedAccount, chainId, getInfo])
  return null
}
