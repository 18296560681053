// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { Button, ButtonGroup } from '@material-ui/core'
import { useWeb3React } from '@web3-react/core'
import { BurnSchema, MintSchema, TxActionType } from 'data/GRTResultSchema'
import { fetchPoolDayDatas } from 'data/pools/klineData'
import {
  BarData,
  BusinessDay,
  createChart,
  HistogramData,
  IChartApi,
  IPriceLine,
  ISeriesApi,
  LineStyle,
  PriceLineOptions,
  SeriesMarker,
  SeriesMarkerPosition,
  SeriesMarkerShape,
  Time,
} from 'lightweight-charts'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useCallback } from 'react'
import { memo } from 'react'
import styled from 'styled-components/macro'
import { getFormattedTimeString } from 'utils/calculateTime'
import { combineMintsAndBurns } from 'utils/fixData'
import {
  getHumanReadablePrice0ByTick,
  getHumanReadablePrice1ByTick,
  getNumberPrecisionAndStepBySignificantDigits,
} from 'utils/fromTickToPrice'

import { RowFixed } from '../Row'

const ChartBox = styled.div`
  width: 100%;
`
const BtnRowFixed = styled(RowFixed)`
  width: 100%;
  box-sizing: border-box;
  padding: 12px 8px;
  justify-content: flex-end;
`

const BtnGroup = styled(ButtonGroup)`
  border-radius: 8px;
`

// 这里不直接传position进来时因为position在外部的引用一直在变化，导致组件一直在重绘
interface IProps {
  // position: Position | undefined
  poolAddress: string | undefined
  mints: MintSchema[] | undefined
  burns: BurnSchema[] | undefined
  symbol0: string | undefined
  symbol1: string | undefined
  decimals0: number | undefined
  decimals1: number | undefined
  tickLower: number | undefined
  tickUpper: number | undefined
  tickCurrent: number | undefined
}

function PositionKLineChart(props: IProps) {
  const { poolAddress, tickLower, tickUpper, mints, burns, symbol0, symbol1, decimals0, decimals1, tickCurrent } = props
  // const { position, poolAddress, mints, burns } = props
  // const tickLower = position?.tickLower
  // const tickUpper = position?.tickUpper
  // const decimals0 = position?.pool.token0.decimals
  // const decimals1 = position?.pool.token1.decimals
  // const tickCurrent = position?.pool.tickCurrent
  // const symbol0 = position?.pool.token0.symbol
  // const symbol1 = position?.pool.token1.symbol

  const chartContainerRef = useRef<HTMLDivElement>(null)
  const chart = useRef<IChartApi>()
  const [candleSeries, setCandleSeries] = useState<ISeriesApi<'Candlestick'>>()
  const [volumeSeries, setVolumeSeries] = useState<ISeriesApi<'Histogram'>>()
  const [p0Price, setP0Price] = useState<BarData[]>()
  const [p1Price, setP1Price] = useState<BarData[]>()
  const [minimumPriceLine, setMinimumPriceLine] = useState<IPriceLine | undefined>()
  const [maximumPriceLine, setMaximumPriceLine] = useState<IPriceLine>()
  const [volumeData, setVolumeData] = useState<HistogramData[]>()
  const [feesData, setFeesData] = useState<HistogramData[]>()
  const [tvlData, setTvlData] = useState<HistogramData[]>()
  const [liquidityData, setLiquidityData] = useState<HistogramData[]>()

  // 按钮相关, 按钮组的item数量根据需要增减, 按钮、变量、方法命名根据需求修改，state主要用于按钮active状态以及数据切换判断，对应调整即可
  const [tokenSelected, setTokenSelected] = useState(1)
  const [btnGroupTwoActive, setBtnGroupTwoActive] = useState(0)
  const [volumeSelected, setVolumeSelected] = useState<volumeType>('fees')
  const { chainId } = useWeb3React()

  // 创建chart、candleSeries和volumeSeries
  useEffect(() => {
    chart.current = createChart(chartContainerRef.current as HTMLElement, {
      width: 960,
      height: 400,
      // watermark: {
      //   visible: true,
      //   fontSize: 24,
      //   horzAlign: 'center',
      //   vertAlign: 'center',
      //   color: 'rgba(171, 71, 188, 0.5)',
      //   text: 'UniOcean',
      // },
    })

    const temp_candleSeries = chart.current.addCandlestickSeries({
      //   upColor: '#6495ED',
      //   downColor: '#FF6347',
      //   borderVisible: false,
      //   wickVisible: true,
      //   borderColor: '#000000',
      //   wickColor: '#000000',
      //   borderUpColor: '#4682B4',
      //   borderDownColor: '#A52A2A',
      //   wickUpColor: '#4682B4',
      //   wickDownColor: '#A52A2A',
    })
    // temp_candleSeries.applyOptions({
    //   // upColor: 'rgba(255, 0, 0, 1)',
    //   // downColor: 'rgba(0, 255, 0, 1)',
    //   priceFormat: {
    //     type: 'custom',
    //     minMove: 0.00000001,
    //     formatter: (price: string) => parseFloat(price).toFixed(8),
    //   },
    // })
    setCandleSeries(temp_candleSeries)

    const temp_volumeSeries = chart.current.addHistogramSeries({
      color: '#26a69a',
      priceFormat: {
        type: 'volume',
      },
      priceScaleId: '',
      scaleMargins: {
        top: 0.8,
        bottom: 0,
      },
      title: `总手续费`,
      // color: '#182233',
    })
    setVolumeSeries(temp_volumeSeries)

    // const switcherElement = createSimpleSwitcher(['Show', 'Hide'], 'Show', toggleVisibility)
    // const areaSeries = chart.current.addAreaSeries({
    //   topColor: 'rgba(38,198,218, 0.56)',
    //   bottomColor: 'rgba(38,198,218, 0.04)',
    //   lineColor: 'rgba(38,198,218, 1)',
    //   lineWidth: 2
    // });
    // areaSeries.setData(areaData);
  }, [])

  // 从The Graph获取Uniswap v3对应池子的每日open、high、low、close数据
  useMemo(async () => {
    if (!chainId) return
    if (poolAddress) {
      const data = await fetchPoolDayDatas(poolAddress, chainId)
      const p0PriceData: BarData[] = []
      const p1PriceData: BarData[] = []
      const volData: HistogramData[] = []
      const fData: HistogramData[] = []
      const tData: HistogramData[] = []

      const len = data.length
      if (len > 0) {
        //   由于The Graph获取的数据当天的open和close是一样的，所以这里要优化下，open取前一天的close数据
        for (const [index, item] of data.entries()) {
          if (index > len - 1) {
            continue
          }
          if (Number(item.open) === 0 || Number(item.close) === 0) {
            continue
          }
          const t = getFormattedTimeString(item.date, 'yyyy-MM-DD')
          const open = Number(data[index + 1].close)
          const high = Number(item.high)
          const low = Number(item.low)
          const close = Number(item.close)
          p0PriceData.push({
            time: t,
            open: 1 / open,
            high: 1 / high,
            low: 1 / low,
            close: 1 / close,
          })
          p1PriceData.push({
            time: t,
            open,
            high,
            low,
            close,
          })
          volData.push({
            time: t,
            value: Number(item.volumeUSD),
          })
          fData.push({
            time: t,
            value: Number(item.feesUSD),
          })
          tData.push({
            time: t,
            value: Number(item.tvlUSD),
          })
        }
        setP0Price(p0PriceData.reverse())
        setP1Price(p1PriceData.reverse())
        setVolumeData(volData.reverse())
        setFeesData(fData.reverse())
        setTvlData(tData.reverse())
      }
    }
  }, [poolAddress, chainId])

  // 设置用户的流动性Volume数据
  useEffect(() => {
    const txs = combineMintsAndBurns(mints, burns, 'asc')
    if (!txs) return
    if (txs.length === 0) return
    const liqData: HistogramData[] = []
    const first = txs[0]
    const firstTime = getFormattedTimeString(Number(first.timestamp), 'yyyy-MM-DD')
    const currentTime = moment()
    const diff = currentTime.diff(firstTime, 'days') + 1
    const nextMoment = moment(firstTime)

    for (let i = 0; i < diff; i++) {
      const sameDays = txs.filter(
        (x) => getFormattedTimeString(Number(x.timestamp), 'yyyy-MM-DD') === nextMoment.format('yyyy-MM-DD')
      )
      let totalLiquidity = 0
      if (i === 0) {
        totalLiquidity = Number(first.amount) // 1e18
      } else {
        totalLiquidity = liqData[i - 1].value
      }
      for (const tx of sameDays) {
        if (tx.type === TxActionType.INCREASE) {
          if (first.transaction.id.toLowerCase() !== tx.transaction.id.toLowerCase()) {
            totalLiquidity += Number(tx.amount) // 1e18
          }
        } else {
          totalLiquidity -= Number(tx.amount) // 1e18
        }
      }

      const data: HistogramData = {
        time: nextMoment.format('yyyy-MM-DD'),
        value: totalLiquidity,
      }
      liqData.push(data)
      nextMoment.add(1, 'days') //  这里是引用，自增就可以了
    }
    setLiquidityData(liqData)
  }, [mints, burns])

  // 显示k线图
  useEffect(() => {
    if (!candleSeries || !p0Price || !p1Price) return
    const priceBarData = tokenSelected === 1 ? p1Price : p0Price
    if (!priceBarData || priceBarData.length < 1) {
      return
    }
    // setUsingP0Price(false)
    const latestPrice = priceBarData[priceBarData.length - 1]
    const price = latestPrice.open ?? latestPrice.high ?? latestPrice.low ?? latestPrice.close ?? 0
    const { precision, step } = getNumberPrecisionAndStepBySignificantDigits(price)

    candleSeries.applyOptions({
      priceFormat: {
        type: 'custom',
        minMove: step,
        formatter: (price: string) => parseFloat(price).toFixed(precision),
      },
    })

    candleSeries.setData(priceBarData)
  }, [candleSeries, p0Price, p1Price, tokenSelected])

  // 显示volume
  useEffect(() => {
    if (!volumeSeries || !tvlData || !volumeData || !feesData) {
      return
    }
    // const volumeHistData = volumeSelected === 'tvl' ? tvlData : volumeSelected === 'volume' ? volumeData : feesData
    let volumeHistData: HistogramData[] | undefined
    let color = '#26a69a'
    let title = t`Fees`
    if (volumeSelected === 'tvl') {
      volumeHistData = tvlData
      color = '#26a69a'
      title = t`TVL`
    } else if (volumeSelected === 'volume') {
      volumeHistData = volumeData
      color = '#2196F3'
      title = t`Volume`
    } else if (volumeSelected === 'fees') {
      volumeHistData = feesData
      color = '#26a69a'
      title = t`Fees`
    } else if (volumeSelected === 'liquidity') {
      volumeHistData = liquidityData
      color = '#26a69a'
      title = t`Position liquidity`
    }
    if (!volumeHistData) {
      return
    }
    volumeSeries.applyOptions({
      color,
      title,
    })
    volumeSeries.setData(volumeHistData)
  }, [volumeSeries, volumeSelected, tvlData, volumeData, feesData])

  // 显示用户设置的上下限。注意，这里要用useEffect，不能用useMemo，否则无法清除之前的PriceLine。
  useEffect(() => {
    if (candleSeries && tickLower && tickUpper && decimals0 && decimals1) {
      // 这里要非常注意，一不小心就反了
      const maximumPrice =
        tokenSelected === 0
          ? getHumanReadablePrice0ByTick(tickUpper, decimals0, decimals1).toNumber()
          : getHumanReadablePrice1ByTick(tickLower, decimals0, decimals1).toNumber()
      const minimumPrice =
        tokenSelected === 0
          ? getHumanReadablePrice0ByTick(tickLower, decimals0, decimals1).toNumber()
          : getHumanReadablePrice1ByTick(tickUpper, decimals0, decimals1).toNumber()

      const lineWidth = 2

      const minPriceLine: PriceLineOptions = {
        price: minimumPrice,
        color: '#be1238',
        lineWidth,
        lineStyle: LineStyle.Solid,
        axisLabelVisible: true,
        title: t`Min Price`,
        lineVisible: true, // 这一句是新加的
      }
      const maxPriceLine: PriceLineOptions = {
        price: maximumPrice,
        color: '#be1238',
        lineWidth,
        lineStyle: LineStyle.Solid,
        axisLabelVisible: true,
        title: t`Max Price`,
        lineVisible: true, // 这一句是新加的
      }
      // 如果之前有最低最高限，则删除
      if (minimumPriceLine) {
        candleSeries.removePriceLine(minimumPriceLine)
      }
      if (maximumPriceLine) {
        candleSeries.removePriceLine(maximumPriceLine)
      }
      setMinimumPriceLine(candleSeries.createPriceLine(minPriceLine))
      setMaximumPriceLine(candleSeries.createPriceLine(maxPriceLine))
    }
  }, [candleSeries, tickLower, tickUpper, decimals0, decimals1, tokenSelected])

  // 显示用户的加减仓标记
  useEffect(() => {
    if (!mints || !burns || !candleSeries) {
      return
    }
    // 这里是因为如果用原来的mints和burns，会改变之前的数据数据，所以要重新拷贝一份
    const newMints = [...mints]
    const newBurns = [...burns]
    const markers: SeriesMarker<Time>[] = []
    const firstMint = newMints.pop()

    const IncTitle = t`Inc`
    const DecTitle = t`Dec`

    // 建仓
    const mintsSM = newMints.reverse().reduce((t, v) => {
      const key = getFormattedTimeString(Number(v.timestamp), 'yyyy-MM-DD')
      if (t.has(key)) {
        const temp = t.get(key)!
        temp.count += 1
        temp.value += Number(v.amountUSD)
        temp.text = `${IncTitle}(${temp.count})`
        t.set(key, temp)
      } else {
        const amountUSD = Number(v.amountUSD)
        const temp: ISeriesMarker = {
          time: key,
          position: 'belowBar',
          color: '#2196F3',
          shape: 'arrowUp',
          count: 1,
          value: amountUSD,
          text: `${IncTitle}(1)`,
        }
        t.set(key, temp)
      }
      return t
    }, new Map<string, ISeriesMarker>([]))

    // time: position: 'aboveBar', color: '#e91e63', shape: 'arrowDown', text: 'Sell @ ' + Math.floor(datesForMarkers[i].high + 2) }
    const burnsSM = newBurns.reverse().reduce((t, v) => {
      if (v.type === TxActionType.DECREASE) {
        const key = getFormattedTimeString(Number(v.timestamp), 'yyyy-MM-DD')
        if (t.has(key)) {
          const temp = t.get(key)!
          temp.count += 1
          temp.value += Number(v.amountUSD)
          temp.text = `${DecTitle}(${temp.count})`
          t.set(key, temp)
        } else {
          const amountUSD = Number(v.amountUSD)
          const temp: ISeriesMarker = {
            time: key,
            position: 'aboveBar',
            color: '#e91e63',
            shape: 'arrowDown',
            count: 1,
            value: amountUSD,
            text: `${DecTitle}(1)`,
          }
          t.set(key, temp)
        }
      }
      return t
    }, new Map<string, ISeriesMarker>([]))

    if (firstMint) {
      markers.push({
        time: getFormattedTimeString(Number(firstMint.timestamp), 'yyyy-MM-DD'),
        position: 'aboveBar',
        color: '#f68410',
        shape: 'circle',
        text: t`Add`,
      })
    }
    Array.from(mintsSM.values()).forEach((item) => {
      markers.push({
        time: item.time,
        position: item.position,
        color: item.color,
        shape: item.shape,
        text: item.text,
      })
    })
    Array.from(burnsSM.values()).forEach((item) => {
      markers.push({
        time: item.time,
        position: item.position,
        color: item.color,
        shape: item.shape,
        text: item.text,
      })
    })
    candleSeries.setMarkers(markers)
  }, [candleSeries, mints, burns])

  // 显示实时k线价格
  useEffect(() => {
    if (!tickCurrent || !candleSeries || !decimals0 || !decimals1) {
      return
    }
    const priceBarData = tokenSelected === 0 ? p0Price : p1Price
    if (!priceBarData || priceBarData?.length === 0) {
      return
    }
    const currentPrice = priceBarData[priceBarData.length - 1]
    const currentTime = getFormattedTimeString(new Date().getTime(), 'yyyy-MM-DD')
    const currentBar: BarData = {
      open: currentPrice.open,
      high: currentPrice.high,
      low: currentPrice.low,
      close: currentPrice.close,
      time: currentPrice.time,
    }

    // 这里要考虑选择的币对, 这里是获取price1的价格
    const price =
      tokenSelected === 0
        ? getHumanReadablePrice0ByTick(tickCurrent, decimals0, decimals1).toNumber()
        : getHumanReadablePrice1ByTick(tickCurrent, decimals0, decimals1).toNumber()
    const bd = currentPrice.time as BusinessDay
    const bdTime = getFormattedTimeString(new Date(`${bd.year}-${bd.month}-${bd.day}`).getTime(), 'yyyy-MM-DD')
    if (bdTime === currentTime) {
      currentBar.close = price
      currentBar.high = Math.max(currentBar.high, price)
      currentBar.low = Math.min(currentBar.low, price)
    } else {
      currentBar.open = price
      currentBar.high = price
      currentBar.low = price
      currentBar.close = price
    }

    candleSeries.update(currentBar)
  }, [tickCurrent, candleSeries, p0Price, p1Price, decimals0, decimals1, tokenSelected])

  //   time: datesForMarkers[i].time, position: 'belowBar', color: '#2196F3', shape: 'arrowUp', text: 'Buy @ ' + Math.floor(datesForMarkers[i].low - 2) }

  // Resize chart on container resizes.
  //   useEffect(() => {
  //     resizeObserver.current = new ResizeObserver((entries) => {
  //       const { width, height } = entries[0].contentRect
  //       chart.current.applyOptions({ width, height })
  //       setTimeout(() => {
  //         chart.current.timeScale().fitContent()
  //       }, 0)
  //     })

  //     resizeObserver.current.observe(chartContainerRef.current)

  //     return () => resizeObserver.current.disconnect()
  //   }, [])
  function createSimpleSwitcher(items: string[], activeItem: string, activeItemChangedCallback: any) {
    const switcherElement = document.createElement('div')
    switcherElement.classList.add('switcher')

    const intervalElements = items.map(function (item) {
      const itemEl = document.createElement('button')
      itemEl.innerText = item
      itemEl.classList.add('switcher-item')
      itemEl.classList.toggle('switcher-active-item', item === activeItem)
      itemEl.addEventListener('click', function () {
        onItemClicked(item)
      })
      switcherElement.appendChild(itemEl)
      return itemEl
    })

    function onItemClicked(item: string) {
      if (item === activeItem) {
        return
      }

      intervalElements.forEach(function (element, index) {
        element.classList.toggle('switcher-active-item', items[index] === item)
      })

      activeItem = item

      activeItemChangedCallback(item)
    }

    return switcherElement
  }

  const handleTokenChange = (tokenIndex: number) => {
    setTokenSelected(tokenIndex)
  }
  const handleBtnGroupTwoChange = (tokenIndex: number) => {
    setBtnGroupTwoActive(tokenIndex)
  }
  const handleVolumeChange = (tokenIndex: volumeType) => {
    setVolumeSelected(tokenIndex)
  }

  const tokenChangeHandle = useCallback(
    (tokenIndex: number) => {
      setTokenSelected(tokenIndex)
    },
    [setTokenSelected]
  )
  const volumeChangeHandle = useCallback(
    (tokenIndex: volumeType) => {
      setVolumeSelected(tokenIndex)
    },
    [setVolumeSelected]
  )

  return (
    <ChartBox>
      <BtnRowFixed>
        <BtnGroup variant="contained" size="small" aria-label="outlined primary button group" sx={{ marginRight: 0 }}>
          <Button variant={tokenSelected === 0 ? 'contained' : 'outlined'} onClick={() => tokenChangeHandle(0)}>
            {symbol0}
          </Button>
          <Button variant={tokenSelected === 1 ? 'contained' : 'outlined'} onClick={() => tokenChangeHandle(1)}>
            {symbol1}
          </Button>
        </BtnGroup>
      </BtnRowFixed>
      <div ref={chartContainerRef} className="chart-container" />
      <BtnRowFixed style={{ justifyContent: 'flex-end' }}>
        {/*<ButtonGroup*/}
        {/*  variant="contained"*/}
        {/*  size="small"*/}
        {/*  aria-label="outlined primary button group"*/}
        {/*  sx={{ marginRight: 0 }}*/}
        {/*>*/}
        {/*  <Button*/}
        {/*    variant={btnGroupTwoActive === 0 ? 'contained' : 'outlined'}*/}
        {/*    onClick={() => handleBtnGroupTwoChange(0)}*/}
        {/*  >*/}
        {/*    token0*/}
        {/*  </Button>*/}
        {/*  <Button*/}
        {/*    variant={btnGroupTwoActive === 1 ? 'contained' : 'outlined'}*/}
        {/*    onClick={() => handleBtnGroupTwoChange(1)}*/}
        {/*  >*/}
        {/*    token1*/}
        {/*  </Button>*/}
        {/*</ButtonGroup>*/}
        <BtnGroup variant="contained" size="small" aria-label="outlined primary button group" sx={{ marginRight: 0 }}>
          <Button
            variant={volumeSelected === 'fees' ? 'contained' : 'outlined'}
            // onClick={() => handleVolumeChange('fees')}
            onClick={() => volumeChangeHandle('fees')}
          >
            <Trans>Fees</Trans>
          </Button>
          <Button
            variant={volumeSelected === 'volume' ? 'contained' : 'outlined'}
            // onClick={() => handleVolumeChange('volume')}
            onClick={() => volumeChangeHandle('volume')}
          >
            <Trans>Volume</Trans>
          </Button>
          <Button
            variant={volumeSelected === 'tvl' ? 'contained' : 'outlined'}
            // onClick={() => handleVolumeChange('tvl')}
            onClick={() => volumeChangeHandle('tvl')}
          >
            <Trans>TVL</Trans>
          </Button>
          {liquidityData ? (
            <Button
              variant={volumeSelected === 'liquidity' ? 'contained' : 'outlined'}
              onClick={() => volumeChangeHandle('liquidity')}
            >
              <Trans>Position liquidity</Trans>
            </Button>
          ) : (
            ''
          )}
        </BtnGroup>
      </BtnRowFixed>
    </ChartBox>
  )
}

interface ISeriesMarker {
  time: string
  position: SeriesMarkerPosition
  color: string
  shape: SeriesMarkerShape
  text: string
  count: number
  value: number
  collectFeeCount?: number
}

type volumeType = 'volume' | 'fees' | 'tvl' | 'liquidity'

export default memo(PositionKLineChart)
