import { Trans } from '@lingui/macro'
import { Position } from '@uniswap/v3-sdk'
import BigNumber from 'bignumber.js'
import { MouseoverTooltipContent } from 'components/Tooltip'
import { BurnSchema, MintSchema } from 'data/GRTResultSchema'
import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import Badge from '../Badge'

interface IPops {
  chainId: number | undefined
  position: Position | undefined
  mints: MintSchema[] | undefined
  burns: BurnSchema[] | undefined
  curFiatValueOfLiquidity: string | undefined
  price0: string | undefined
  price1: string | undefined
}

export function RateOfReturnTip() {
  return (
    <>
      <span>
        <Trans>
          The increase or decrease is based on cost changes (without considering fee income). Assuming that the first
          time you increase your position is $100,000 and the second time you reduce your position by $20,000, the cost
          is $80,000. At this time, the total value of the real-time position is $100,000, the profit is: $20,000, and
          the increase or decrease is: +25%
        </Trans>
      </span>
    </>
  )
}

const CostChangeBox = styled.div`
  display: inline;
  font-size: 18px;
  margin-left: 8px;
`
const BadgeText = styled.div`
  font-weight: 500;
  font-size: 14px;
`
const CostChangeBadge = styled(Badge)`
  padding: 4px 6px;
  display: inline-block;
  line-height: 14px;
  background-color: ${(props) => props.color};
  vertical-align: text-bottom;
`

export default function CostChange(props: IPops) {
  const { chainId, position, mints, burns, curFiatValueOfLiquidity, price0, price1 } = props
  const tickLower = position?.tickLower
  const tickUpper = position?.tickUpper
  const token0 = position?.pool.token0
  const token1 = position?.pool.token1
  const token0Decimals = token0?.decimals
  const token1Decimals = token1?.decimals
  const curLiquidity = position?.liquidity.toString()
  const tickCurrent = position?.pool.tickCurrent

  const { increaseAmount, increaseAmountUSD, decreaseAmount, decreaseAmountUSD } = useMemo(() => {
    if (!mints || !burns) {
      return {
        increaseAmount: undefined,
        increaseAmountUSD: undefined,
        decreaseAmount: undefined,
        decreaseAmountUSD: undefined,
      }
    }
    const mintsCost = mints.reduce(
      (t, v) => {
        t.amount = new BigNumber(t.amount).plus(new BigNumber(v.amount)).toString()
        t.amountUSD = new BigNumber(t.amountUSD).plus(new BigNumber(v.amountUSD)).toString()
        return t
      },
      { amount: '0', amountUSD: '0' }
    )
    const burnsCost = burns.reduce(
      (t, v) => {
        t.amount = new BigNumber(t.amount).plus(new BigNumber(v.amount)).toString()
        t.amountUSD = new BigNumber(t.amountUSD).plus(new BigNumber(v.amountUSD)).toString()
        return t
      },
      { amount: '0', amountUSD: '0' }
    )
    return {
      increaseAmount: mintsCost.amount,
      increaseAmountUSD: mintsCost.amountUSD,
      decreaseAmount: burnsCost.amount,
      decreaseAmountUSD: burnsCost.amountUSD,
    }
  }, [mints, burns])

  // 计算收益率（这部分写得太TM丑了）
  const { costNum, diffNum, ratioNum } = useMemo(() => {
    if (chainId && curLiquidity && curFiatValueOfLiquidity && increaseAmountUSD && decreaseAmountUSD) {
      const currentLiquidity = new BigNumber(curLiquidity)
      const currentFiatValueOfLiquidity = new BigNumber(curFiatValueOfLiquidity)
      const currentUnitLiquidity = currentFiatValueOfLiquidity.div(currentLiquidity)

      const increaseAmountUSDNum = new BigNumber(increaseAmountUSD)
      const decreaseAmountUSDNum = new BigNumber(decreaseAmountUSD)
      const profitUSDNum = decreaseAmountUSDNum.plus(currentFiatValueOfLiquidity)
      if (increaseAmountUSDNum.eq(0)) {
        return {
          costNum: undefined,
          diffNum: undefined,
          ratioNum: undefined,
        }
      }
      return {
        costNum: increaseAmountUSDNum.toNumber(),
        diffNum: profitUSDNum.minus(increaseAmountUSDNum).toNumber(),
        ratioNum: profitUSDNum.minus(increaseAmountUSDNum).dividedBy(increaseAmountUSDNum).toNumber(),
      }
    }
    return { costNum: 0, diffNum: 0, ratioNum: 0 }
  }, [
    chainId,
    curLiquidity,
    curFiatValueOfLiquidity,
    increaseAmount,
    increaseAmountUSD,
    decreaseAmount,
    decreaseAmountUSD,
  ])

  return (
    <>
      {diffNum && ratioNum ? (
        <CostChangeBox color={new BigNumber(diffNum).comparedTo(0) !== -1 ? '#00B464' : '#FE542B'}>
          (
          {diffNum.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            useGrouping: true,
            maximumFractionDigits: 2,
          })}
          <MouseoverTooltipContent content={<RateOfReturnTip />}>
            <CostChangeBadge
              style={{ marginLeft: '10px' }}
              color={new BigNumber(diffNum).comparedTo(0) !== -1 ? 'rgba(0, 180, 100, .1)' : 'rgba(254, 84, 43, .1)'}
            >
              <ThemedText.Main
                fontSize={11}
                color={new BigNumber(diffNum).comparedTo(0) !== -1 ? '#00B464' : '#FE542B'}
              >
                {(ratioNum * 100).toFixed(2)}%
              </ThemedText.Main>
            </CostChangeBadge>
          </MouseoverTooltipContent>
          )
        </CostChangeBox>
      ) : null}
    </>
  )
}
