import { skipToken } from '@reduxjs/toolkit/query/react'
import { TxActionType } from 'data/GRTResultSchema'
import ms from 'ms.macro'
import { useEffect, useMemo, useState } from 'react'
import { useAddressHistoryQuery } from 'state/data/enhanced'
import { AddressHistoryQuery } from 'state/data/generated'
import { getRealDecreaseAndFeeAmount } from 'utils/fixData'

export interface IV3Transaction {
  tokenId: string
  owner: string
  origin: string
  pool: {
    id: string | undefined
    token0: {
      id: string | undefined
      name: string | undefined
      symbol: string | undefined
      decimals: string | undefined
    }
    token1: {
      id: string | undefined
      name: string | undefined
      symbol: string | undefined
      decimals: string | undefined
    }
    feeTier: string
  }
  tickLower: number
  tickUpper: number
  timestamp: string
  amount: string | undefined
  amount0: string
  amount1: string
  amountUSD: string
  feeAmount0?: string
  feeAmount1?: string
  type: TxActionType
  transaction: {
    id: string | undefined
    blockNumber: string
    gasPrice: string
    gasUsed: string
    timestamp: string
  }
}

export function useHistory(owner: string, first = 100, skip = 0) {
  const { isLoading, isError, error, isUninitialized, data } = useAddressHistoryQuery(
    owner ? { owner, first, skip } : skipToken,
    {
      pollingInterval: ms`1 day`,
    }
  )

  return {
    isLoading,
    isUninitialized,
    isError,
    error,
    data: data?.positionSnapshots as AddressHistoryQuery['positionSnapshots'],
  }
}

export function useHistoryWithFixedData(owner: string, first = 100, skip = 0) {
  const { isError, error, isUninitialized, data: originData } = useHistory(owner, first, skip)
  const [data, setData] = useState<IV3Transaction[]>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
  }, [skip])

  useMemo(async () => {
    if (isError || !originData) return
    const newData: IV3Transaction[] = []
    for (const item of originData) {
      const mints = item.transaction.mints
      for (const tx of mints) {
        // const v3trans: IV3Transaction = {} as IV3Transaction
        const v3trans = positionSnapshotsDataToV3Transaction(tx, TxActionType.INCREASE)
        v3trans.tokenId = item.position.id
        v3trans.owner = item.owner
        v3trans.tickLower = Number(item.position.tickLower.tickIdx)
        v3trans.tickUpper = Number(item.position.tickUpper.tickIdx)
        newData.push(v3trans)
      }
      const burns = item.transaction.burns
      for (const tx of burns) {
        let v3trans: IV3Transaction
        const transactionId = tx!.transaction.id
        // item.position
        const same = newData.find((x) => {
          return x.transaction.id?.toLowerCase() === transactionId.toLowerCase()
        })
        if (same) {
          continue
        } else {
          v3trans = positionSnapshotsDataToV3Transaction(tx, TxActionType.COLLECT)
          const fixedResult = await getRealDecreaseAndFeeAmount(
            transactionId,
            Number(tx?.pool.token0.decimals),
            Number(tx?.pool.token1.decimals),
            1
          )
          if (fixedResult) {
            const { decreaseAmount, decreaseAmount0, decreaseAmount1, feeAmount0, feeAmount1, gasUsed } = fixedResult
            v3trans.amount = decreaseAmount
            v3trans.amount0 = decreaseAmount0
            v3trans.amount1 = decreaseAmount1
            v3trans.feeAmount0 = feeAmount0
            v3trans.feeAmount1 = feeAmount1
            v3trans.transaction.gasUsed = gasUsed
          }
        }
        v3trans.tokenId = item.position.id
        v3trans.owner = item.owner
        v3trans.tickLower = Number(item.position.tickLower.tickIdx)
        v3trans.tickUpper = Number(item.position.tickUpper.tickIdx)
        newData.push(v3trans)
      }
    }
    setData(newData)
    setIsLoading(false)
  }, [isError, originData])

  return {
    isLoading,
    isUninitialized,
    isError,
    error,
    data,
  }
}

function positionSnapshotsDataToV3Transaction(tx: any, type: TxActionType) {
  const v3trans: IV3Transaction = {} as IV3Transaction
  //   v3trans.tokenId = item.position.id
  //   v3trans.owner = item.owner
  v3trans.origin = tx?.origin
  v3trans.timestamp = tx?.timestamp
  v3trans.type = type
  v3trans.pool = {
    id: tx?.pool.id, // 这里要改
    token0: {
      id: tx?.pool.token0.id,
      name: tx?.pool.token0.name,
      symbol: tx?.pool.token0.symbol,
      decimals: tx?.pool.token0.decimals,
    },
    token1: {
      id: tx?.pool.token1.id,
      name: tx?.pool.token1.name,
      symbol: tx?.pool.token1.symbol,
      decimals: tx?.pool.token1.decimals,
    },
    feeTier: tx?.pool.feeTier,
  }
  v3trans.amount = tx?.amount
  v3trans.amount0 = tx?.amount0
  v3trans.amount1 = tx?.amount1
  v3trans.amountUSD = tx?.amountUSD
  v3trans.transaction = {
    id: tx?.transaction.id,
    blockNumber: tx?.transaction.blockNumber,
    gasPrice: tx?.transaction.gasPrice,
    gasUsed: tx?.transaction.gasUsed,
    timestamp: tx?.transaction.timestamp,
  }
  return v3trans
}
