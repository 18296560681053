import { BaseQueryFn } from '@reduxjs/toolkit/query'
import { createApi } from '@reduxjs/toolkit/query/react'
import { SupportedChainId } from 'constants/chains'
import { DocumentNode } from 'graphql'
import { ClientError, gql, GraphQLClient } from 'graphql-request'
import { AppState } from 'state'

// List of supported subgraphs. Note that the app currently only support one active subgraph at a time
export const CHAIN_SUBGRAPH_URL: Record<number, string> = {
  [SupportedChainId.MAINNET]: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
  [SupportedChainId.RINKEBY]: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',

  [SupportedChainId.ARBITRUM_ONE]: 'https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-minimal',

  [SupportedChainId.OPTIMISM]: 'https://api.thegraph.com/subgraphs/name/ianlapham/optimism-post-regenesis',

  [SupportedChainId.POLYGON]: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon',
}

export const api = createApi({
  reducerPath: 'dataApi',
  baseQuery: graphqlRequestBaseQuery(),
  endpoints: (builder) => ({
    allV3Ticks: builder.query({
      query: ({ poolAddress, skip = 0 }) => ({
        document: gql`
          query allV3Ticks($poolAddress: String!, $skip: Int!) {
            ticks(first: 1000, skip: $skip, where: { poolAddress: $poolAddress }, orderBy: tickIdx) {
              tick: tickIdx
              liquidityNet
              price0
              price1
            }
          }
        `,
        variables: {
          poolAddress,
          skip,
        },
      }),
    }),
    feeTierDistribution: builder.query({
      query: ({ token0, token1 }) => ({
        document: gql`
          query feeTierDistribution($token0: String!, $token1: String!) {
            _meta {
              block {
                number
              }
            }
            asToken0: pools(
              orderBy: totalValueLockedToken0
              orderDirection: desc
              where: { token0: $token0, token1: $token1 }
            ) {
              feeTier
              totalValueLockedToken0
              totalValueLockedToken1
            }
            asToken1: pools(
              orderBy: totalValueLockedToken0
              orderDirection: desc
              where: { token0: $token1, token1: $token0 }
            ) {
              feeTier
              totalValueLockedToken0
              totalValueLockedToken1
            }
          }
        `,
        variables: {
          token0,
          token1,
        },
      }),
    }),
    // 通过tokenId查询pool数据
    position: builder.query({
      query: ({ id }) => ({
        document: gql`
          query getPositions($id: ID!) {
            positions(first: 1, where: { id: $id }) {
              id
              owner
              liquidity
              depositedToken0
              depositedToken1
              withdrawnToken0
              withdrawnToken1
              collectedFeesToken0
              collectedFeesToken1
              tickLower {
                tickIdx
              }
              tickUpper {
                tickIdx
              }
              pool {
                id
                token0 {
                  id
                  symbol
                  name
                  decimals
                }
                token1 {
                  id
                  symbol
                  name
                  decimals
                }
                feeTier
              }
              transaction {
                id
                blockNumber
                timestamp
                gasUsed
                gasPrice
                mints(first: 500) {
                  id
                  owner
                  origin
                  amount
                  amount0
                  amount1
                  amountUSD
                  transaction {
                    id
                    blockNumber
                    timestamp
                    gasUsed
                    gasPrice
                  }
                }
                burns(first: 500) {
                  id
                  timestamp
                  owner
                  origin
                  amount
                  amount1
                  amountUSD
                  transaction {
                    id
                    blockNumber
                    timestamp
                    gasUsed
                    gasPrice
                  }
                }
                collects(first: 500) {
                  id
                  timestamp
                  owner
                  amount0
                  amount1
                  amountUSD
                  transaction {
                    id
                    blockNumber
                    timestamp
                    gasUsed
                    gasPrice
                  }
                }
              }
            }
          }
        `,
        variables: {
          id,
        },
      }),
    }),
    // 通过token0、token1、fee 查询对应pool数据，和最近30天的数据
    poolData: builder.query({
      query: ({ token0Address, token1Address, fee }) => ({
        document: gql`
          query getPoolData($token0Address: String!, $token1Address: String!, $fee: BigInt!) {
            pools(first: 1, where: { token0: $token0Address, token1: $token1Address, feeTier: $fee }) {
              id
              createdAtTimestamp
              createdAtBlockNumber
              token0 {
                symbol
                decimals
              }
              token1 {
                symbol
                decimals
              }
              feeTier
              liquidity
              totalValueLockedUSD
              volumeUSD
              feesUSD
              collectedFeesUSD
              txCount
              token0Price
              token1Price
              tick
              poolDayData(first: 30, orderBy: date, orderDirection: desc) {
                id
                date
                tvlUSD
                feesUSD
                volumeUSD
                txCount
              }
            }
          }
        `,
        variables: {
          token0Address,
          token1Address,
          fee,
        },
      }),
    }),
    // 主要通过Position Snapshot 获取 mints和burns数据
    positionSnapshotMintAndBurn: builder.query({
      query: ({ tokenId, owner, first = 1000, skip = 0, orderBy = 'timestamp', orderDirection = 'desc' }) => ({
        document: gql`
          query getPositionSnapshotMintAndBurnData(
            $tokenId: String!
            $owner: Bytes!
            $first: Int = 1000
            $skip: Int! = 0
            $orderBy: PositionSnapshot_orderBy = timestamp
            $orderDirection: OrderDirection = desc
          ) {
            positionSnapshots(
              first: $first
              skip: $skip
              where: { position: $tokenId, owner: $owner }
              orderBy: $orderBy
              orderDirection: $orderDirection
            ) {
              transaction {
                mints {
                  id
                  timestamp
                  pool {
                    id
                    token0 {
                      id
                      symbol
                      name
                      decimals
                    }
                    token1 {
                      id
                      symbol
                      name
                      decimals
                    }
                    feeTier
                  }
                  owner
                  origin
                  amount
                  amount0
                  amount1
                  amountUSD
                  tickLower
                  tickUpper
                  transaction {
                    id
                    blockNumber
                    timestamp
                    gasUsed
                    gasPrice
                  }
                }
                burns {
                  id
                  timestamp
                  pool {
                    id
                    token0 {
                      id
                      symbol
                      name
                      decimals
                    }
                    token1 {
                      id
                      symbol
                      name
                      decimals
                    }
                    feeTier
                  }
                  owner
                  origin
                  amount
                  amount0
                  amount1
                  amountUSD
                  tickLower
                  tickUpper
                  transaction {
                    id
                    blockNumber
                    timestamp
                    gasUsed
                    gasPrice
                  }
                }
              }
            }
          }
        `,
        variables: {
          tokenId,
          owner,
          first,
          skip,
          orderBy,
          orderDirection,
        },
      }),
    }),
    addressHistory: builder.query({
      query: ({ owner, first = 1000, skip = 0 }) => ({
        document: gql`
          query addressHistory($owner: Bytes!, $first: Int!, $skip: Int!) {
            positionSnapshots(
              first: $first
              skip: $skip
              where: { owner: $owner }
              orderBy: timestamp
              orderDirection: desc
            ) {
              id
              owner
              position {
                id
                tickLower {
                  tickIdx
                }
                tickUpper {
                  tickIdx
                }
              }
              transaction {
                mints {
                  owner
                  origin
                  id
                  timestamp
                  pool {
                    id
                    token0 {
                      id
                      symbol
                      decimals
                      name
                    }
                    token1 {
                      id
                      symbol
                      decimals
                      name
                    }
                    feeTier
                  }
                  amount
                  amount0
                  amount1
                  amountUSD
                  transaction {
                    id
                    blockNumber
                    timestamp
                    gasUsed
                    gasPrice
                  }
                }
                burns {
                  owner
                  origin
                  id
                  timestamp
                  pool {
                    id
                    token0 {
                      id
                      symbol
                      decimals
                      name
                    }
                    token1 {
                      id
                      symbol
                      decimals
                      name
                    }
                    feeTier
                  }
                  amount
                  amount0
                  amount1
                  amountUSD
                  transaction {
                    id
                    blockNumber
                    timestamp
                    gasUsed
                    gasPrice
                  }
                }
                swaps {
                  id
                  origin
                  sender
                  recipient
                  timestamp
                  pool {
                    id
                    token0 {
                      id
                      symbol
                      decimals
                      name
                    }
                    token1 {
                      id
                      symbol
                      decimals
                      name
                    }
                    feeTier
                  }
                  amount0
                  amount1
                  amountUSD
                  sqrtPriceX96
                  tick
                  transaction {
                    id
                    blockNumber
                    timestamp
                    gasUsed
                    gasPrice
                  }
                }
              }
            }
          }
        `,
        variables: {
          owner,
          first,
          skip,
        },
      }),
    }),
  }),
})

// Graphql query client wrapper that builds a dynamic url based on chain id
function graphqlRequestBaseQuery(): BaseQueryFn<
  { document: string | DocumentNode; variables?: any },
  unknown,
  Pick<ClientError, 'name' | 'message' | 'stack'>,
  Partial<Pick<ClientError, 'request' | 'response'>>
> {
  return async ({ document, variables }, { getState }) => {
    try {
      const chainId = (getState() as AppState).application.chainId

      const subgraphUrl = chainId ? CHAIN_SUBGRAPH_URL[chainId] : undefined

      if (!subgraphUrl) {
        return {
          error: {
            name: 'UnsupportedChainId',
            message: `Subgraph queries against ChainId ${chainId} are not supported.`,
            stack: '',
          },
        }
      }

      return { data: await new GraphQLClient(subgraphUrl).request(document, variables), meta: {} }
    } catch (error) {
      if (error instanceof ClientError) {
        const { name, message, stack, request, response } = error
        return { error: { name, message, stack }, meta: { request, response } }
      }
      throw error
    }
  }
}
