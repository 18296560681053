import { Trans } from '@lingui/macro'
import { Button, Divider, IconButton, Menu as MenuUi } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
// import Menu as MenuUi from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import MenuIcon from '@material-ui/icons/Menu'
import useScrollPosition from '@react-hook/window-scroll'
import { useWeb3React } from '@web3-react/core'
import { YellowCard } from 'components/Card'
import { Account } from 'hooks/useAccounts'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import { isChainAllowed } from 'utils/switchChain'

import Logo from '../../assets/images/logo.png'
import { useAccountManager } from '../../constants/accounts'
import { useToggleWalletModal } from '../../state/application/hooks'
import { shortenAddress } from '../../utils'
import ClaimModal from '../claim/ClaimModal'
import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
import NetworkSelector from './NetworkSelector'

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: grid;
  grid-template-columns: 120px 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 1rem;
  z-index: 21;
  position: relative;
  /* Background slide effect on scroll. */
  background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px ${({ theme, showBackground }) => (showBackground ? theme.bg2 : 'transparent;')};
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: 48px 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding:  1rem;
    grid-template-columns: 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding:  1rem;
    grid-template-columns: 36px 1fr;
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 0.5em;
  }

  /* addresses safaris lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`

const HeaderLinks = styled(Row)`
  justify-self: center;
  background-color: ${({ theme }) => theme.bg0};
  width: fit-content;
  padding: 2px;
  border-radius: 16px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    justify-self: start;  
    `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: center;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    z-index: 99;
    position: fixed;
    bottom: 0; right: 50%;
    transform: translate(50%,-50%);
    margin: 0 auto;
    background-color: ${({ theme }) => theme.bg0};
    border: 1px solid ${({ theme }) => theme.bg2};
    box-shadow: 0px 6px 10px rgb(0 0 0 / 2%);
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg0 : theme.bg0)};
  border-radius: 16px;
  white-space: nowrap;
  width: 100%;
  height: 40px;

  :focus {
    border: 1px solid blue;
  }
`

// const UNIAmount = styled(AccountElement)`
//   color: white;
//   padding: 4px 8px;
//   height: 36px;
//   font-weight: 500;
//   background-color: ${({ theme }) => theme.bg3};
//   background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
// `

// const UNIWrapper = styled.span`
//   width: fit-content;
//   position: relative;
//   cursor: pointer;

//   :hover {
//     opacity: 0.8;
//   }

//   :active {
//     opacity: 0.9;
//   }
// `

// const BalanceText = styled(Text)`
//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//     display: none;
//   `};
// `

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }

  position: relative;
`

// const activeClassName = 'ACTIVE'

// const StyledNavLink = styled(NavLink).attrs({
//   activeClassName,
// })`
//   ${({ theme }) => theme.flexRowNoWrap}
//   align-items: left;
//   border-radius: 3rem;
//   outline: none;
//   cursor: pointer;
//   text-decoration: none;
//   color: ${({ theme }) => theme.text2};
//   font-size: 1rem;
//   font-weight: 500;
//   padding: 8px 12px;
//   word-break: break-word;
//   overflow: hidden;
//   white-space: nowrap;
//   &.${activeClassName} {
//     border-radius: 14px;
//     font-weight: 600;
//     justify-content: center;
//     color: ${({ theme }) => theme.text1};
//     background-color: ${({ theme }) => theme.bg1};
//   }

//   :hover,
//   :focus {
//     color: ${({ theme }) => darken(0.1, theme.text1)};
//   }
// `

// const StyledExternalLink = styled(ExternalLink).attrs({
//   activeClassName,
// })<{ isActive?: boolean }>`
//   ${({ theme }) => theme.flexRowNoWrap}
//   align-items: left;
//   border-radius: 3rem;
//   outline: none;
//   cursor: pointer;
//   text-decoration: none;
//   color: ${({ theme }) => theme.text2};
//   font-size: 1rem;
//   width: fit-content;
//   margin: 0 12px;
//   font-weight: 500;

//   &.${activeClassName} {
//     border-radius: 14px;
//     font-weight: 600;
//     color: ${({ theme }) => theme.text1};
//   }

//   :hover,
//   :focus {
//     color: ${({ theme }) => darken(0.1, theme.text1)};
//     text-decoration: none;
//   }
// `

const HeaderRow = styled(RowFixed)`
  width: max-content;
  flex-wrap: wrap;
  ${({ theme }) => theme.mediaWidth.upToMedium`
   // width: 100%;
  `};
`

const Slogan = styled.span`
  display: inline-block;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none
  `};
`

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const NetworkCard = styled(YellowCard)`
  border-radius: 12px;
  padding: 8px 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`

const AccountButton = styled(Button)`
  background-color: white;
`

const AccountsMenu = styled(MenuUi)`
  & .MuiMenu-paper {
    width: 260px;
  }
`

const MuiDivider = styled(Divider)`
  margin: 8px 0;
  & .MuiDivider-wrapper {
    color: #bcc2cb;
    font-size: 12px;
    line-height: 12px;
  }
`

const AccountMenuButton = styled(Button)`
  color: #1c1f26;
`

export default function Header({ showSidebar }: { showSidebar: () => void }) {
  const { account, chainId, connector } = useWeb3React()

  const chainAllowed = chainId && isChainAllowed(connector, chainId)

  // const userEthBalance = useNativeCurrencyBalances(account ? [account] : [])?.[account ?? '']
  // const [darkMode] = useDarkModeManager()
  // const { white, black } = useTheme()
  // const toggleClaimModal = useToggleSelfClaimModal()
  // const availableClaim: boolean = useUserHasAvailableClaim(account)
  // const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)
  // const showClaimPopup = useShowClaimPopup()

  const scrollY = useScrollPosition()

  // const {
  //   infoLink,
  //   nativeCurrency: { symbol: nativeCurrencySymbol },
  // } = CHAIN_INFO[!chainId || !chainAllowed ? SupportedChainId.MAINNET : chainId]

  // 以下是新增内容
  // const { label: networkLabel } = CHAIN_INFO[!chainId || !chainAllowed ? SupportedChainId.MAINNET : chainId]
  const { selectedAccount, setSelectedAccount, accounts } = useAccountManager()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const toggleWalletModal = useToggleWalletModal()

  const [remark, setRemark] = useState('')

  function changeSelected(_account: string) {
    setSelectedAccount(_account)
    hideMoreMenu()
  }
  const openMenu = Boolean(anchorEl)
  function showMoreMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
  }
  function hideMoreMenu() {
    setAnchorEl(null)
  }

  function connectWallet() {
    hideMoreMenu()
    toggleWalletModal()
  }
  function subStr(str: string) {
    return str.substr(2, 2)
  }
  useEffect(() => {
    if (account && !selectedAccount) {
      setSelectedAccount(account)
    }
  }, [account, selectedAccount, setSelectedAccount])
  useEffect(() => {
    const handleAccount = accounts.filter((item) => item.account === selectedAccount)
    setRemark(handleAccount[0] ? handleAccount[0].remark : '')
  }, [selectedAccount, accounts])
  // const { positions } = usePositionRemindState()
  // const positionRemindList = [...positions]

  return (
    <HeaderFrame showBackground={scrollY > 45}>
      <ClaimModal />
      <Title href=".">
        {/* <UniIcon>
          <Logo fill={darkMode ? white : black} width="24px" height="100%" title="logo" />
          <HolidayOrnament />
        </UniIcon> */}
        <HeaderRow>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={showSidebar}
            sx={{ marginRight: '8px', display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Title href=".">
            <UniIcon>
              <img src={Logo} alt="logo" />
            </UniIcon>
          </Title>
          <br />
          <Slogan>One-stop Platform for Uniswap v3 Liquidity Management</Slogan>
        </HeaderRow>
      </Title>
      <HeaderLinks>
        {/* <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
          <Trans>Overview</Trans>
        </StyledNavLink>
        <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
          <Trans>Manange Wallets</Trans>
        </StyledNavLink>
        <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
          <Trans>History</Trans>
        </StyledNavLink>
        <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
          <Trans>V3 LPs</Trans>
        </StyledNavLink> */}

        {/* <StyledNavLink
          id={`pool-nav-link`}
          to={'/pool'}
          isActive={(match, { pathname }) =>
            Boolean(match) ||
            pathname.startsWith('/add') ||
            pathname.startsWith('/remove') ||
            pathname.startsWith('/increase') ||
            pathname.startsWith('/find')
          }
        >
          <Trans>Pool</Trans>
        </StyledNavLink>
        {(!chainId || chainId === SupportedChainId.MAINNET) && (
          <StyledNavLink id={`vote-nav-link`} to={'/vote'}>
            <Trans>Vote</Trans>
          </StyledNavLink>
        )}
        <StyledExternalLink id={`charts-nav-link`} href={infoLink}>
          <Trans>Charts</Trans>
          <sup>↗</sup>
        </StyledExternalLink> */}
      </HeaderLinks>

      <HeaderControls>
        <HeaderElement>
          <NetworkSelector />
        </HeaderElement>
        <HeaderElement>
          {/* <HideSmall>
            {chainId && networkLabel && <NetworkCard title={networkLabel}>{networkLabel}</NetworkCard>}
          </HideSmall> */}
          <div style={{ width: '1px', height: '1px', overflow: 'hidden', display: 'inline-block' }}>
            <Web3Status />
          </div>
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            {/*{account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                <Trans>{userEthBalance?.toSignificant(4)} ETH</Trans>
              </BalanceText>
            ) : null}
            <Web3Status />*/}
            <AccountButton
              id="more-button"
              aria-controls="more-menu"
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              disableElevation
              onClick={(event) => showMoreMenu(event)}
              endIcon={
                selectedAccount ? (
                  open ? (
                    <KeyboardArrowDownIcon sx={{ transform: `rotate(180deg)` }} />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                ) : null
              }
            >
              {selectedAccount ? (
                <List dense={true} sx={{ padding: 0 }}>
                  <ListItem sx={{ padding: 0, height: '40px' }}>
                    <ListItemAvatar sx={{ display: { xs: 'none', sm: 'block' } }}>
                      <Avatar sx={{ marginRight: '8px', backgroundColor: '#00B464' }}>
                        {subStr(selectedAccount.toUpperCase())}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={shortenAddress(selectedAccount)}
                      secondary={remark}
                      primaryTypographyProps={{ color: '#000000' }}
                      secondaryTypographyProps={{ color: '#BCC2CB' }}
                    />
                  </ListItem>
                </List>
              ) : !account ? (
                <Trans>Choose wallet</Trans>
              ) : (
                <>
                  <List dense={true} sx={{ padding: 0 }}>
                    <ListItem sx={{ padding: 0, height: '40px' }}>
                      <ListItemAvatar sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <Avatar sx={{ marginRight: '8px', backgroundColor: '#00B464' }}>
                          {subStr(account.toUpperCase())}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={shortenAddress(account)}
                        secondary={remark}
                        primaryTypographyProps={{ color: '#000000' }}
                        secondaryTypographyProps={{ color: '#BCC2CB' }}
                      />
                    </ListItem>
                  </List>
                </>
              )}
            </AccountButton>
            <AccountsMenu
              id="more-menu"
              MenuListProps={{
                'aria-labelledby': 'more-button',
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={hideMoreMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MuiDivider textAlign="left">
                <Trans>My Wallets</Trans>
              </MuiDivider>
              {account ? (
                <MenuItem onClick={() => changeSelected(account)} disableRipple>
                  <List dense={true} sx={{ padding: 0, width: '100%' }}>
                    <ListItem sx={{ padding: 0, height: '40px' }}>
                      <ListItemAvatar sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <Avatar sx={{ marginRight: '8px', backgroundColor: '#00B464' }}>
                          {subStr(account.toUpperCase())}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={shortenAddress(account)}
                        // secondary={remark}
                        primaryTypographyProps={{ color: '#000000' }}
                        secondaryTypographyProps={{ color: '#BCC2CB' }}
                      />
                      {/*<ListItemSecondaryAction>*/}
                      {/*  <IconButton edge="end" aria-label="copy">*/}
                      {/*    <ContentCopyIcon />*/}
                      {/*  </IconButton>*/}
                      {/*</ListItemSecondaryAction>*/}
                    </ListItem>
                  </List>
                </MenuItem>
              ) : (
                <MenuItem onClick={connectWallet} disableRipple>
                  <Trans>Connect a wallet</Trans>
                </MenuItem>
                // <Web3Status />
              )}
              {accounts.length > 0 ? (
                <MuiDivider textAlign="left">
                  <Trans>Watchlist</Trans>
                </MuiDivider>
              ) : null}
              {accounts.map((item: Account) => {
                return (
                  <MenuItem key={item.account} onClick={() => changeSelected(item.account)} disableRipple>
                    <List dense={true} sx={{ padding: 0 }}>
                      <ListItem sx={{ padding: 0, height: '40px' }}>
                        <ListItemAvatar sx={{ display: { xs: 'none', sm: 'block' } }}>
                          <Avatar sx={{ marginRight: '8px', backgroundColor: '#00B464' }}>
                            {subStr(item.account.toUpperCase())}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={shortenAddress(item.account)}
                          secondary={item.remark}
                          primaryTypographyProps={{ color: '#000000' }}
                          secondaryTypographyProps={{ color: '#BCC2CB' }}
                        />
                      </ListItem>
                    </List>
                    {/*<Avatar sx={{ marginRight: '8px' }}>{subStr(item.account)}</Avatar>*/}
                    {/*{shortenAddress(item.account)}*/}
                  </MenuItem>
                )
              })}
              <MuiDivider textAlign="left" />
              <MenuItem onClick={connectWallet} disableRipple>
                <AccountMenuButton startIcon={<AddCircleIcon />}>
                  <Trans>Connect wallet</Trans>
                </AccountMenuButton>
              </MenuItem>
              <NavLink to="/accounts" style={{ textDecoration: 'none' }}>
                <MenuItem disableRipple>
                  <AccountMenuButton startIcon={<AccountBalanceWalletIcon />}>
                    <Trans>Manage wallets</Trans>
                  </AccountMenuButton>
                </MenuItem>
              </NavLink>
            </AccountsMenu>
          </AccountElement>
        </HeaderElement>

        {/* 这个是原版连接钱包的样式 */}
        {/* <HeaderElement>
          {availableClaim && !showClaimPopup && (
            <UNIWrapper onClick={toggleClaimModal}>
              <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                <ThemedText.White padding="0 2px">
                  {claimTxn && !claimTxn?.receipt ? (
                    <Dots>
                      <Trans>Claiming UNI</Trans>
                    </Dots>
                  ) : (
                    <Trans>Claim UNI</Trans>
                  )}
                </ThemedText.White>
              </UNIAmount>
              <CardNoise />
            </UNIWrapper>
          )}
          <AccountElement active={!!account}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0, userSelect: 'none' }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                <Trans>
                  {userEthBalance?.toSignificant(3)} {nativeCurrencySymbol}
                </Trans>
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement>
        </HeaderElement> */}

        {/* <HeaderElement>
          <Menu />
        </HeaderElement> */}
      </HeaderControls>
    </HeaderFrame>
  )
}
