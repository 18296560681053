import { Trans } from '@lingui/macro'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'

interface Props {
  open: boolean
  onClose: (value?: string) => void
  value?: any
  title?: string
  children?: React.ReactNode
}

export default function ConfirmDialog(props: Props) {
  const { onClose, value, open, children, title } = props

  const handleCancel = () => {
    onClose()
  }

  const handleOk = () => {
    onClose(value)
  }

  return (
    <>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '600px', height: 300 } }}
        maxWidth={false}
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        {title ? <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle> : null}
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            <Trans>取消</Trans>
          </Button>
          <Button onClick={handleOk}>
            <Trans>确定</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
