import { BigNumber } from '@ethersproject/bignumber'
import { Trans } from '@lingui/macro'
import { Box, Card, CardContent, Grid, Tab, Tabs } from '@material-ui/core'
import { Currency, Price, Token } from '@uniswap/sdk-core'
import { FeeAmount, Pool, Position } from '@uniswap/v3-sdk'
import { AutoColumn } from 'components/Column'
import { RowBetween } from 'components/Row'
import { PositionSchema } from 'data/GRTResultSchema'
import { fetchSamePoolPositions, fetchTopPoolPositions } from 'data/position/positionData'
import { usePool } from 'hooks/usePools'
import { useAPR, useSpreadRange } from 'hooks/usePositionsData'
import { useV3PositionFees } from 'hooks/useV3PositionFees'
import { Label } from 'pages/Position/PositionPage'
import React, { useEffect, useMemo } from 'react'
import { useState } from 'react'
import { memo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { useAccountManager } from '../../constants/accounts'
import { formatPrice } from '../../utils/formatCurrencyAmount'
import { unwrappedToken } from '../../utils/unwrappedToken'
import RangeBadge from '../Badge/RangeBadge'
import { LightCard } from '../Card'
import Loader from '../Loader'
import RateToggle from '../RateToggle'
import ShowApr from '../ShowApr'
import ShowOwner from '../ShowOwner'
import { getPriceOrderingFromPositionForUI } from './index'
import SpreadRange from './SpreadRange'

interface IProps {
  chainId: number | undefined
  token0: Token | undefined
  token1: Token | undefined
  feeAmount: FeeAmount | undefined
  poolAddress: string | undefined
  tickLower: number | undefined
  tickUpper: number | undefined
  // 这里两个price是用传进来的而不是在里面用 useUSDCPrice(token0 ?? undefined)查询的原因是
  //这里的价格不需要随时变化去消耗性能，跟随外面的变化即可，哪怕不变其实也没有问题的
  price0: Price<Currency, Token> | undefined
  price1: Price<Currency, Token> | undefined
}

const SimilarBox = styled(Grid)`
  max-width: 900px;
`
const SimilarItemTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 8px 0;
`

function GetSimilarPositions(props: IProps) {
  const { chainId, token0, token1, feeAmount, poolAddress, tickLower, tickUpper, price0, price1 } = props
  // construct Position from details returned
  const [poolState, pool] = usePool(token0, token1, feeAmount)
  const [topPoolPositions, setTopPoolPositions] = useState<PositionSchema[] | undefined>(undefined)
  const [sameTickPositions, setSameTickPositions] = useState<PositionSchema[] | undefined>(undefined)
  const [sameTickLowerPositions, setSameTickLowerPositions] = useState<PositionSchema[] | undefined>(undefined)
  const [sameTickUpperPositions, setSameTickUpperPositions] = useState<PositionSchema[] | undefined>(undefined)

  const [topPositionsData, setTopPositionsData] = useState<IPositionCardMini[] | undefined>(undefined)
  const [sameTickPositionsData, setSameTickPositionsData] = useState<IPositionCardMini[] | undefined>(undefined)
  const [sameTickLowerPositionsData, setSameTickLowerPositionsData] = useState<IPositionCardMini[] | undefined>(
    undefined
  )
  const [sameTickUpperPositionsData, setSameTickUpperPositionsData] = useState<IPositionCardMini[] | undefined>(
    undefined
  )
  const [isInit, setIsInit] = useState<boolean>(false)

  useMemo(async () => {
    if (!poolAddress || typeof tickLower === 'undefined' || typeof tickUpper === 'undefined' || !chainId) {
      return
    }
    // 获取当前pool排名前10的仓位和地址
    const data1 = await fetchTopPoolPositions(chainId, poolAddress, 1000)
    //   获取跟当前仓位一样tickLower、tickUpper的仓位和地址
    const data2 = await fetchSamePoolPositions(chainId, poolAddress, tickLower, tickUpper)
    //获取跟当前仓位设置一样tickLower的仓位和地址
    const data3 = await fetchSamePoolPositions(chainId, poolAddress, tickLower, undefined)
    //获取跟当前仓位设置一样tickUpper的仓位和地址
    const data4 = await fetchSamePoolPositions(chainId, poolAddress, undefined, tickUpper)
    setTopPoolPositions(data1)
    setSameTickPositions(data2)
    setSameTickLowerPositions(data3)
    setSameTickUpperPositions(data4)
  }, [poolAddress, tickLower, tickUpper, chainId])

  useMemo(() => {
    // 能这样做的前提是topPoolPositions、sameTickPositions这些返回的不是undefined
    if (
      !price0 ||
      !price1 ||
      !pool ||
      !poolAddress ||
      !topPoolPositions ||
      !sameTickPositions ||
      !sameTickLowerPositions ||
      !sameTickUpperPositions
    ) {
      return
    }
    // 因为pool会不断变化，所以这里要加一个判断，免得一直进来
    if (isInit) {
      return
    }
    const data1 = formatPositionSchemaToPositionCardMiniParams(topPoolPositions, pool, poolAddress, price0, price1, 10)
    setTopPositionsData(data1)
    const data2 = formatPositionSchemaToPositionCardMiniParams(sameTickPositions, pool, poolAddress, price0, price1, 10)
    setSameTickPositionsData(data2)
    const data3 = formatPositionSchemaToPositionCardMiniParams(
      sameTickLowerPositions,
      pool,
      poolAddress,
      price0,
      price1,
      10
    )
    setSameTickLowerPositionsData(data3)
    const data4 = formatPositionSchemaToPositionCardMiniParams(
      sameTickUpperPositions,
      pool,
      poolAddress,
      price0,
      price1,
      10
    )
    setSameTickUpperPositionsData(data4)
    setIsInit(true)
  }, [
    topPoolPositions,
    sameTickPositions,
    sameTickLowerPositions,
    sameTickUpperPositions,
    poolAddress,
    price0,
    price1,
    pool,
  ])

  function formatPositionSchemaToPositionCardMiniParams(
    datas: PositionSchema[],
    pool: Pool,
    poolAddress: string,
    price0: Price<Currency, Token>,
    price1: Price<Currency, Token>,
    top = -1
  ): IPositionCardMini[] {
    let positionsData: IPositionCardMini[] = []
    for (const item of datas) {
      const position = new Position({
        pool,
        liquidity: item.liquidity.toString(),
        tickLower: Number(item.tickLower.tickIdx),
        tickUpper: Number(item.tickUpper.tickIdx),
      })
      const value0 = price0.quote(position.amount0)
      const value1 = price1.quote(position.amount1)
      const liquidityValue = Number(value0.add(value1).toExact())

      const data: IPositionCardMini = {
        tokenId: item.id,
        owner: item.owner,
        price0,
        price1,
        position,
        fiatValueOfLiquidity: liquidityValue,
        poolAddress,
        depositedToken0: item.depositedToken0,
        depositedToken1: item.depositedToken1,
        withdrawnToken0: item.withdrawnToken0,
        withdrawnToken1: item.withdrawnToken1,
        collectedFeesToken0: item.collectedFeesToken0,
        collectedFeesToken1: item.collectedFeesToken1,
      }
      positionsData.push(data)
    }
    positionsData.sort((a, b) => {
      return b.fiatValueOfLiquidity - a.fiatValueOfLiquidity
    })
    positionsData = positionsData.slice(0, top)
    return positionsData
  }

  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <LightCard padding="12px 16px" style={{ backgroundColor: 'white', marginTop: '20px', maxWidth: 900 }}>
      <RowBetween>
        <AutoColumn gap="md" style={{ width: '100%', marginBottom: '12px' }}>
          <AutoColumn gap="md">
            <Label>
              <Trans>Related positions</Trans>
              <span style={{ color: 'gray', fontSize: '14px' }}>
                <Trans>(Positions are sorted in reverse order of liquidity value)</Trans>
              </span>
            </Label>
          </AutoColumn>
        </AutoColumn>
      </RowBetween>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab
            label={
              <SimilarItemTitle>
                <Trans>All positions</Trans>
                {topPoolPositions ? (
                  <span>({topPoolPositions.length >= 1000 ? '1000+' : topPoolPositions.length})</span>
                ) : null}
              </SimilarItemTitle>
            }
          />
          <Tab
            label={
              <SimilarItemTitle>
                <Trans>Same positions</Trans>
                {sameTickPositions ? (
                  <span>({sameTickPositions.length >= 1000 ? '1000+' : sameTickPositions.length})</span>
                ) : null}
              </SimilarItemTitle>
            }
          />
          <Tab
            label={
              <SimilarItemTitle>
                <Trans>Same lower price</Trans>
                {sameTickLowerPositions ? (
                  <span>({sameTickLowerPositions.length >= 1000 ? '1000+' : sameTickLowerPositions.length})</span>
                ) : null}
              </SimilarItemTitle>
            }
          />
          <Tab
            label={
              <SimilarItemTitle>
                <Trans>Same upper price</Trans>
                {sameTickUpperPositions ? (
                  <span>({sameTickUpperPositions.length >= 1000 ? '1000+' : sameTickUpperPositions.length})</span>
                ) : null}
              </SimilarItemTitle>
            }
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          {!topPositionsData ? (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Loader size="22px" />
            </Grid>
          ) : topPositionsData?.length !== 0 ? (
            topPositionsData.map((item) => {
              return (
                <Grid item xs={6} key={item.tokenId}>
                  <PositionCardMini {...item} />
                </Grid>
              )
            })
          ) : (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <p style={{ marginTop: 0 }}>
                <Trans>No position data</Trans>
              </p>
            </Grid>
          )}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          {!sameTickPositionsData ? (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Loader size="22px" />
            </Grid>
          ) : sameTickPositionsData?.length !== 0 ? (
            sameTickPositionsData.map((item) => {
              return (
                <Grid item xs={6} key={item.tokenId}>
                  <PositionCardMini {...item} />
                </Grid>
              )
            })
          ) : (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <p style={{ marginTop: 0 }}>
                <Trans>No position data</Trans>
              </p>
            </Grid>
          )}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          {!sameTickLowerPositionsData ? (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Loader size="22px" />
            </Grid>
          ) : sameTickLowerPositionsData?.length !== 0 ? (
            sameTickLowerPositionsData.map((item) => {
              return (
                <Grid item xs={6} key={item.tokenId}>
                  <PositionCardMini {...item} />
                </Grid>
              )
            })
          ) : (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <p style={{ marginTop: 0 }}>
                <Trans>No position data</Trans>
              </p>
            </Grid>
          )}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          {!sameTickUpperPositionsData ? (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Loader size="22px" />
            </Grid>
          ) : sameTickUpperPositionsData?.length !== 0 ? (
            sameTickUpperPositionsData.map((item) => {
              return (
                <Grid item xs={6} key={item.tokenId}>
                  <PositionCardMini {...item} />
                </Grid>
              )
            })
          ) : (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <p style={{ marginTop: 0 }}>
                <Trans>No position data</Trans>
              </p>
            </Grid>
          )}
        </Grid>
      </TabPanel>
      {/*<SimilarBox container direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid item container xs={12} spacing={2} style={{ marginBottom: '12px' }}>
          <Grid item xs={12}>
            <SimilarItemTitle>
              大资金仓位
              {topPoolPositions ? (
                <span>({topPoolPositions.length >= 1000 ? '1000+' : topPoolPositions.length})</span>
              ) : null}
            </SimilarItemTitle>
          </Grid>
          {topPositionsData && topPositionsData?.length !== 0 ? (
            topPositionsData.map((item) => {
              return (
                <Grid item xs={6} key={item.tokenId}>
                  <PositionCardMini {...item} />
                </Grid>
              )
            })
          ) : (
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <p style={{ marginTop: 0 }}>暂无相同仓位</p>
            </Grid>
          )}
        </Grid>
        <Grid item container xs={12} spacing={2} style={{ marginBottom: '12px' }}>
          <Grid item xs={12}>
            <SimilarItemTitle>
              相同仓位
              {sameTickPositions ? (
                <span>({sameTickPositions.length >= 1000 ? '1000+' : sameTickPositions.length})</span>
              ) : null}
            </SimilarItemTitle>
          </Grid>
          {sameTickPositionsData && sameTickPositionsData?.length !== 0 ? (
            sameTickPositionsData.map((item) => {
              return (
                <Grid item xs={6} key={item.tokenId}>
                  <PositionCardMini {...item} />
                </Grid>
              )
            })
          ) : (
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <p style={{ marginTop: 0 }}>暂无相同仓位</p>
            </Grid>
          )}
        </Grid>
        <Grid item container xs={12} spacing={2} style={{ marginBottom: '12px' }}>
          <Grid item xs={12}>
            <SimilarItemTitle>
              相同最低价仓位
              {sameTickLowerPositions ? (
                <span>({sameTickLowerPositions.length >= 1000 ? '1000+' : sameTickLowerPositions.length})</span>
              ) : null}
            </SimilarItemTitle>
          </Grid>
          {sameTickLowerPositionsData && sameTickLowerPositionsData?.length !== 0 ? (
            sameTickLowerPositionsData.map((item) => {
              return (
                <Grid item xs={6} key={item.tokenId}>
                  <PositionCardMini {...item} />
                </Grid>
              )
            })
          ) : (
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <p style={{ marginTop: 0 }}>暂无相同仓位</p>
            </Grid>
          )}
        </Grid>
        <Grid item container xs={12} spacing={2} style={{ marginBottom: '12px' }}>
          <Grid item xs={12}>
            <SimilarItemTitle>
              相同最高价仓位
              {sameTickUpperPositions ? (
                <span>({sameTickUpperPositions.length >= 1000 ? '1000+' : sameTickUpperPositions.length})</span>
              ) : null}
            </SimilarItemTitle>
          </Grid>
          {sameTickUpperPositionsData && sameTickUpperPositionsData?.length !== 0 ? (
            sameTickUpperPositionsData.map((item) => {
              return (
                <Grid item xs={6} key={item.tokenId}>
                  <PositionCardMini {...item} />
                </Grid>
              )
            })
          ) : (
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <p style={{ marginTop: 0 }}>暂无相同仓位</p>
            </Grid>
          )}
        </Grid>
      </SimilarBox>*/}
    </LightCard>
  )
}
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ padding: '20px 0' }}>{children}</Box>}
    </div>
  )
}

interface IPositionCardMini {
  tokenId: number
  owner: string
  price0: Price<Currency, Token>
  price1: Price<Currency, Token>
  position: Position | undefined
  poolAddress: string
  fiatValueOfLiquidity: number
  depositedToken0: string
  depositedToken1: string
  withdrawnToken0: string
  withdrawnToken1: string
  collectedFeesToken0: string
  collectedFeesToken1: string
}

function PositionCardMini(props: IPositionCardMini) {
  const { tokenId, owner, price0, price1, position, fiatValueOfLiquidity, poolAddress } = props

  const isInRange = useMemo(() => {
    if (!position) {
      return undefined
    }
    const tickCurrent = position.pool.tickCurrent
    const tickLower = position.tickLower
    const tickUpper = position.tickUpper
    if (tickCurrent >= tickLower && tickCurrent <= tickUpper) {
      return true
    }
    return false
  }, [position])
  const { accounts } = useAccountManager()
  // const { chainId, account } = useActiveWeb3React()
  const [ownerRemark, setOwnerRemark] = useState<undefined | string>(undefined)
  useEffect(() => {
    accounts.forEach((item) => {
      if (owner && item.account.toLowerCase() === owner.toLowerCase() && item.remark !== '') {
        setOwnerRemark(item.remark)
      } else {
        setOwnerRemark('')
      }
    })
  }, [owner])

  const [manuallyInverted, setManuallyInverted] = useState(false)
  let { priceLower, priceUpper, quote, base } = getPriceOrderingFromPositionForUI(position)
  if (manuallyInverted) {
    ;[priceLower, priceUpper, base, quote] = [priceUpper?.invert(), priceLower?.invert(), quote, base]
  }
  const currencyQuote = quote && unwrappedToken(quote)
  const currencyBase = base && unwrappedToken(base)

  //   const fiatValueOfLiquidity: CurrencyAmount<Token> | null = useMemo(() => {
  //     if (!price0 || !price1 || !position) return null
  //     const amount0 = price0.quote(position.amount0)
  //     const amount1 = price1.quote(position.amount1)
  //     return amount0.add(amount1)
  //   }, [price0, price1, position])

  const [feeValue0, feeValue1] = useV3PositionFees(position?.pool ?? undefined, BigNumber.from(tokenId), false)
  const fiatValueOfFees: number | undefined = useMemo(() => {
    if (!price0 || !price1 || !feeValue0 || !feeValue1) return undefined

    // we wrap because it doesn't matter, the quote returns a USDC amount
    const feeValue0Wrapped = feeValue0?.wrapped
    const feeValue1Wrapped = feeValue1?.wrapped

    if (!feeValue0Wrapped || !feeValue1Wrapped) return undefined

    const amount0 = price0.quote(feeValue0Wrapped)
    const amount1 = price1.quote(feeValue1Wrapped)
    return Number(amount0.add(amount1).toExact())
  }, [price0, price1, feeValue0, feeValue1])

  const { apr, currentTimestamp, createPositionTimestamp } = useAPR(
    tokenId,
    owner,
    fiatValueOfLiquidity,
    fiatValueOfFees
  )

  const spreadRange = useSpreadRange(position?.token0PriceLower, position?.token0PriceUpper, 'percentage')
  //   positionStartTime: number
  //   positionAdjustmentsCount: number
  const TokenIdLink = styled(Link)`
    color: #1c1f26;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  `
  const DoubleArrow = styled.span`
    margin: -10px 2px 0;
    color: ${({ theme }) => theme.text3};
    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 4px;
    padding: 20px;
  `};
  `
  return (
    <Card>
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          style={{ marginBottom: '8px' }}
        >
          <Grid item xs={3}>
            ID:{' '}
            <TokenIdLink to={`/position/${tokenId}`} target="_blank">
              {tokenId}
            </TokenIdLink>
          </Grid>
          <Grid item xs={9} style={{ textAlign: 'right' }}>
            <ShowOwner account={owner} showTitle={false} />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: '8px' }}
        >
          <Grid item xs={6}>
            <Label>
              <Trans>Liquidity</Trans>
            </Label>
          </Grid>
          <Grid item xs={6}>
            <RangeBadge removed={false} inRange={!!isInRange} />
            {/*{isInRange !== undefined ? (
              isInRange ? (
                <Label>In range</Label>
              ) : (
                <Label>Out of range</Label>
              )
            ) : (
              <Label>-</Label>
            )}*/}
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          style={{ marginBottom: '8px' }}
        >
          <Grid item xs={7}>
            <ThemedText.MediumHeader fontSize="26px" fontWeight={500}>
              {fiatValueOfLiquidity > 1 / 100 ? (
                <Trans>
                  {fiatValueOfLiquidity.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    useGrouping: true,
                    maximumFractionDigits: 2,
                  })}
                </Trans>
              ) : (
                <Trans>$-</Trans>
              )}
            </ThemedText.MediumHeader>
          </Grid>
          <Grid item xs={5} style={{ textAlign: 'right' }}>
            {/*APR: {apr ? <span style={{ color: '#00B464', fontWeight: 'bold' }}>{(100 * apr).toFixed(2)}%</span> : '-'}*/}
            <ShowApr apr={apr} />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={1}
          style={{ marginBottom: '8px' }}
        >
          <Grid item xs={3}>
            {/*{position?.token0PriceLower.toSignificant(5)}*/}
            {formatPrice(priceLower, 5)}
            <br />({position?.tickLower.toString()})
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'center' }}>
            {/*{position.pool.token0.symbol}/{position.pool.token1.symbol}*/}
            {currencyBase && currencyQuote && (
              <RateToggle
                currencyA={currencyBase}
                currencyB={currencyQuote}
                handleRateToggle={() => setManuallyInverted(!manuallyInverted)}
              />
            )}
            <DoubleArrow>⟷</DoubleArrow>
            <br />
            <SpreadRange
              spreadRange={spreadRange}
              tickUpper={position?.tickUpper}
              tickLower={position?.tickLower}
              tickCurrent={position?.pool.tickCurrent}
              invert={manuallyInverted}
            />
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'right' }}>
            {/*{position?.token0PriceUpper.toSignificant(5)}*/}
            {formatPrice(priceUpper, 5)}
            <br />({position?.tickUpper.toString()})
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Trans>Create time: </Trans>
            {createPositionTimestamp ? new Date(createPositionTimestamp! * 1000).toLocaleDateString() : '-'}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default memo(GetSimilarPositions)
