// eslint-disable-next-line no-restricted-imports
import { t } from '@lingui/macro'
import Loader from 'components/Loader'
import { useMemo } from 'react'
import { getDateDiff } from 'utils/calculateTime'

export function PositionDuration({ duration }: { duration: number | undefined }) {
  const diff = useMemo(() => {
    if (!duration) return undefined
    const { dateNumber, dateFormat } = getDateDiff(duration)
    let format = ''
    if (dateNumber && dateNumber > 1) {
      if (dateFormat === 'day') {
        format = t`days`
      } else if (dateFormat === 'hour') {
        format = t`hours`
      } else if (dateFormat === 'minute') {
        format = t`minutes`
      }
    } else {
      if (dateFormat === 'day') {
        format = t`day`
      } else if (dateFormat === 'hour') {
        format = t`hour`
      } else if (dateFormat === 'minute') {
        format = t`minute`
      }
    }

    return { dateNumber, dateFormat: format }
  }, [duration])
  return <>{diff ? diff.dateNumber ? `${diff.dateNumber} ${diff?.dateFormat}` : '-' : <Loader />}</>
}
