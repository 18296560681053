import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useMemo, useState } from 'react'

import { usePoolDataState } from '../../state/pools/hooks'
import { PoolDataState } from '../../state/pools/reducer'

// const BatchButton = styled(Button)`
//   background: #1c1f26;
//   border-radius: 20px;
//   margin-left: 8px;
// `
interface CheckDetailProps {
  selected: string[]
}

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} style={{ width: '600px' }}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export default function BatchFees({ selected }: CheckDetailProps) {
  const [selectedTotalLiquidity, setSelectedTotalLiquidity] = useState(0)
  const [selectedTotalFee, setSelectedTotalFee] = useState(0)

  const { pools } = usePoolDataState()

  const poolData: Map<string, PoolDataState> = useMemo(() => {
    return new Map<string, PoolDataState>(JSON.parse(pools))
  }, [pools])
  useEffect(() => {
    let totalFee = 0
    let totalLiquidity = 0
    selected.forEach((item) => {
      if (poolData.has(item)) {
        const data = poolData.get(item)
        totalLiquidity += data?.fiatValueOfLiquidity ?? 0
        totalFee += data?.fiatValueOfFees ?? 0
      }
    })
    setSelectedTotalLiquidity(totalLiquidity)
    setSelectedTotalFee(totalFee)
  }, [selected, poolData])

  const [open, setOpen] = useState(false)

  // const handleClickOpen = () => {
  //   setOpen(true)
  // }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {selected.length !== 0 ? (
        <span>
          已选{selected.length}个仓位, 流动性：
          {selectedTotalLiquidity.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            useGrouping: true,
            maximumFractionDigits: 2,
          })}
          , 待收割手续费 ：
          {selectedTotalFee.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            useGrouping: true,
            maximumFractionDigits: 2,
          })}
          {/*<BatchButton variant="contained" onClick={handleClickOpen}>*/}
          {/*  <span>批量收割手续费</span>*/}
          {/*</BatchButton>*/}
        </span>
      ) : null}
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          收割手续费
        </BootstrapDialogTitle>
        <DialogContent dividers></DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
          <Button onClick={handleClose}>确定</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
