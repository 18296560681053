import { Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import { IV3Transaction } from 'hooks/useHistoryData'
import { useMemo } from 'react'
import { CSVLink } from 'react-csv'
import styled from 'styled-components/macro'
import { formatTimestampToSecond, getFormattedTimeString } from 'utils/calculateTime'
import { getHumanReadablePrice0ByTick } from 'utils/fromTickToPrice'

interface IProps {
  historyData: IV3Transaction[] | undefined
  address?: string
}

interface IHistoryCsvData {
  id: string
  pool: string
  poolAddress: string
  owner: string
  origin: string
  time: string
  operation: string
  token0: string
  token1: string
  tickLower: number
  tickUpper: number
  //   lowerPrice: number
  //   upperPrice: number
  amount0: string
  amount1: string
  amountUSD: string
  feeAmount0: string | undefined
  feeAmount1: string | undefined
  txHash: string
  gasPrice: number
  gasUsed: number
  gas: number
}

export default function HistoryDataExportToCsv(props: IProps) {
  const { historyData, address } = props
  const downloadTime = getFormattedTimeString(formatTimestampToSecond(new Date().getTime())!, 'yyyyMMDDhhmmss')
  const csvData = useMemo(() => {
    if (!historyData || historyData.length === 0) return undefined
    const csvData: IHistoryCsvData[] = []

    // 按照时间倒序排序
    historyData.sort((a, b) => {
      return Number(b.timestamp) - Number(a.timestamp)
    })

    for (const item of historyData) {
      // if (tx.type === TxActionType.CLAIM_FEE || tx.type === TxActionType.COLLECT) continue
      // const operation = tx.type === TxActionType.INCREASE ? '加仓' : '减仓'
      const priceLower0 = getHumanReadablePrice0ByTick(
        item.tickLower,
        Number(item.pool.token0.decimals!),
        Number(item.pool.token1.decimals!)
      )
      const priceUpper0 = getHumanReadablePrice0ByTick(
        item.tickUpper,
        Number(item.pool.token0.decimals!),
        Number(item.pool.token1.decimals!)
      )
      const gasPrice = new BigNumber(item.transaction.gasPrice)
      const gasUsed = Number(item.transaction.gasUsed)
      csvData.push({
        id: item.tokenId,
        pool: `${item.pool.token0.symbol}/${item.pool.token1.symbol}_${(
          Number(item.pool.feeTier) / 10000
        ).toString()}%`,
        poolAddress: item.pool.id!,
        owner: item.owner,
        origin: item.origin,
        token0: item.pool.token0.symbol!,
        token1: item.pool.token1.symbol!,
        tickLower: item.tickLower,
        tickUpper: item.tickUpper,
        // lowerPrice: priceLower0.toNumber(),
        // upperPrice: priceUpper0.toNumber(),
        time: getFormattedTimeString(Number(item.timestamp), 'yyyy-MM-DD hh:mm:ss'),
        operation: item.type.toString(),
        amount0: item.amount0,
        amount1: item.amount1,
        amountUSD: Number(item.amountUSD).toFixed(2),
        feeAmount0: item.feeAmount0,
        feeAmount1: item.feeAmount1,
        txHash: item.transaction.id!,
        gasPrice: gasPrice.dividedBy(1e9).toNumber(),
        gasUsed,
        gas: gasPrice.multipliedBy(gasUsed).dividedBy(1e18).toNumber(),
      })
    }
    return csvData
  }, [historyData])

  return (
    <>
      {csvData ? (
        <>
          {/* <CSVDownload data={csvData} target="_blank" /> */}
          <CsvBtn data={csvData} filename={`History_${address}_${downloadTime}.csv`}>
            <Trans>Download CSV</Trans>
          </CsvBtn>
        </>
      ) : (
        ''
      )}
    </>
  )
}
const CsvBtn = styled(CSVLink)`
  float: right;
  font-size: 14px;
  padding: 11px 20px;
  border-radius: 8px;
  border: 1px solid #2962ef;
  color: #2962ef;
  text-decoration: none;
  font-weight: 500;
`
