import { Trans } from '@lingui/macro'
import { Divider, Stack } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { useWeb3React } from '@web3-react/core'
import BatchFees from 'components/BatchFees'
import { ButtonGray, ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { NewMenu } from 'components/Menu'
import { SwapPoolTabs } from 'components/NavigationTabs'
import { PoolAccountInfo } from 'components/PoolAccountInfo'
import PositionList from 'components/PositionList/index'
import { RowBetween, RowFixed } from 'components/Row'
import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
import Toggle from 'components/Toggle'
import { useAccountManager } from 'constants/accounts'
import { PoolOverviewManager } from 'constants/poolOverview'
import { useToken } from 'hooks/Tokens'
import { usePositionsData } from 'hooks/usePositionsData'
import useUSDCPrice from 'hooks/useUSDCPrice'
import { useV3Positions } from 'hooks/useV3Positions'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Inbox } from 'react-feather'
import { Link } from 'react-router-dom'
import { useToggleWalletModal } from 'state/application/hooks'
import { useUpdateTokenData } from 'state/tokens/hooks'
import { getTokenPriceParamByTokenAndPrice } from 'state/tokens/reducer'
import { useUserHideClosedPositions } from 'state/user/hooks'
import styled, { ThemeContext } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { PositionDetails } from 'types/position'

import PoolOverview from './PoolOverview'
import { LoadingRows } from './styleds'

const PageWrapper = styled(AutoColumn)`
  max-width: 870px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`
const TitleRow = styled(RowBetween)`
  color: ${({ theme }) => theme.text2};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  `};
`
const ButtonRow = styled(RowFixed)`
  & > *:not(:last-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-direction: row-reverse;
  `};
`
const Menu = styled(NewMenu)`
  margin-left: 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex: 1 1 auto;
    width: 49%;
    right: 0px;
  `};

  a {
    width: 100%;
  }
`
const MenuItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
`
const MoreOptionsButton = styled(ButtonGray)`
  border-radius: 12px;
  flex: 1 1 auto;
  padding: 6px 8px;
  width: 100%;
  background-color: ${({ theme }) => theme.bg0};
  margin-right: 8px;
`
const NoLiquidity = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 25vh;
`
const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  border-radius: 12px;
  padding: 6px 8px;
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex: 1 1 auto;
    width: 100%;
  `};
`

const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.bg0};
  padding: 8px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
`

const ShowInactiveToggle = styled.div`
  display: grid;
  align-items: center;
  justify-items: end;

  grid-template-columns: 1fr auto;
  grid-column-gap: 8px;
  padding: 0 8px;
`

const PoolOverviewBox = styled(Paper)`
  //max-width: calc(100vw - 240px);
  min-width: calc(100vw - 240px);
  box-shadow: 0 0 16px 0 rgba(79, 120, 224, 0.08);
  margin: 40px 0 0;
  box-sizing: border-box;
  padding: 20px;
`

const PositionCheckDetail = styled.div`
  display: inline-block;
`

function PositionsLoadingPlaceholder() {
  return (
    <LoadingRows>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoadingRows>
  )
}

export default function Position() {
  const { account: myAccount, chainId } = useWeb3React()
  const toggleWalletModal = useToggleWalletModal()

  const theme = useContext(ThemeContext)
  const [userHideClosedPositions, setUserHideClosedPositions] = useUserHideClosedPositions()

  const { selectedAccount } = useAccountManager()
  const account = selectedAccount !== '' ? selectedAccount : undefined

  const { positions, loading: positionsLoading } = useV3Positions(account)

  const [openPositions, closedPositions] = positions?.reduce<[PositionDetails[], PositionDetails[]]>(
    (acc, p) => {
      acc[p.liquidity?.isZero() ? 1 : 0].push(p)
      return acc
    },
    [[], []]
  ) ?? [[], []]

  const filteredPositions = [...openPositions, ...(userHideClosedPositions ? [] : closedPositions)]

  const allPositions = [...openPositions, ...closedPositions]

  const [positionsData, setPositionsData, emptyPositions] = usePositionsData()

  const [selected, setSelected] = useState<string[]>([])
  useEffect(() => {
    emptyPositions()
    setSelected([])
  }, [selectedAccount])

  const noDupulicateTokenAddress = useMemo(() => {
    const noDupulicateTokenAddress = openPositions.reduce<string[]>((t, v) => {
      const token0Address = v.token0.toLowerCase()
      const token1Address = v.token1.toLowerCase()
      t = t.includes(token0Address) ? t : [...t, token0Address]
      t = t.includes(token1Address) ? t : [...t, token1Address]
      return t
    }, [])
    return noDupulicateTokenAddress
  }, [filteredPositions.length])

  function removeAddress(tokenAddress: string) {
    const index = noDupulicateTokenAddress.indexOf(tokenAddress)
    if (index >= 0) {
      noDupulicateTokenAddress.splice(index, 1)
    }
  }

  // const showConnectAWallet = Boolean(!account)
  // const showV2Features = Boolean(chainId && V2_FACTORY_ADDRESSES[chainId])
  // const menuItems = [
  //   {
  //     content: (
  //       <MenuItem>
  //         <Trans>Create a pool</Trans>
  //         <PlusCircle size={16} />
  //       </MenuItem>
  //     ),
  //     link: '/add/ETH',
  //     external: false,
  //   },
  //   {
  //     content: (
  //       <MenuItem>
  //         <Trans>Migrate</Trans>
  //         <ChevronsRight size={16} />
  //       </MenuItem>
  //     ),
  //     link: '/migrate/v2',
  //     external: false,
  //   },
  //   {
  //     content: (
  //       <MenuItem>
  //         <Trans>V2 liquidity</Trans>
  //         <Layers size={16} />
  //       </MenuItem>
  //     ),
  //     link: '/pool/v2',
  //     external: false,
  //   },
  //   {
  //     content: (
  //       <MenuItem>
  //         <Trans>Learn</Trans>
  //         <BookOpen size={16} />
  //       </MenuItem>
  //     ),
  //     link: 'https://docs.uniswap.org/',
  //     external: true,
  //   },
  // ]

  return (
    <>
      <PageWrapper>
        {noDupulicateTokenAddress.map((item) => {
          return <RequestTokenPriceComponent key={item} fn={removeAddress} tokenAddress={item} />
        })}
        <SwapPoolTabs active={'pool'} />
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <PoolOverviewManager.Provider value={{ positionsData, setPositionsData, emptyPositions }}>
              <PoolOverviewBox elevation={0}>
                <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                  <PoolOverview openPositions={openPositions} closedPositions={closedPositions} />
                  <PoolAccountInfo />
                </Stack>
              </PoolOverviewBox>
              <TitleRow style={{ marginTop: '1rem' }} padding={'0'}>
                <PositionCheckDetail>
                  <BatchFees selected={selected} />
                </PositionCheckDetail>
                <ButtonRow>
                  {closedPositions.length > 0 ? (
                    <ShowInactiveToggle>
                      <ThemedText.DarkGray>
                        <Trans>Hide closed positions</Trans>
                      </ThemedText.DarkGray>
                      <Toggle
                        isActive={userHideClosedPositions}
                        toggle={() => setUserHideClosedPositions(!userHideClosedPositions)}
                      />
                    </ShowInactiveToggle>
                  ) : null}
                  {selectedAccount && myAccount && selectedAccount === myAccount ? (
                    <ResponsiveButtonPrimary id="join-pool-button" as={Link} to="/add/ETH">
                      + <Trans>New Position</Trans>
                    </ResponsiveButtonPrimary>
                  ) : null}
                </ButtonRow>
              </TitleRow>

              <MainContentWrapper>
                {positionsLoading ? (
                  <LoadingRows>
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                  </LoadingRows>
                ) : filteredPositions && filteredPositions.length > 0 ? (
                  <>
                    <PositionList positions={filteredPositions} selected={selected} setSelected={setSelected} />
                  </>
                ) : (
                  <NoLiquidity>
                    <ThemedText.MediumHeader color={theme.text3} textAlign="center">
                      <Inbox size={48} strokeWidth={1} style={{ marginBottom: '.5rem' }} />
                      <div>
                        <Trans>Your V3 liquidity positions will appear here.</Trans>
                      </div>
                    </ThemedText.MediumHeader>
                    {!account ? (
                      <ButtonPrimary style={{ marginTop: '2em', padding: '8px 16px' }} onClick={toggleWalletModal}>
                        <Trans>Connect a wallet</Trans>
                      </ButtonPrimary>
                    ) : (
                      <div></div>
                    )}
                  </NoLiquidity>
                )}
              </MainContentWrapper>
            </PoolOverviewManager.Provider>
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
      <SwitchLocaleLink />
    </>
  )
}

interface IRequestTokenPriceComponent {
  fn: (tokenAddress: string) => void
  tokenAddress: string
}

function RequestTokenPriceComponent(props: IRequestTokenPriceComponent) {
  const { fn, tokenAddress } = props
  const token = useToken(tokenAddress)
  const usdcPrice = useUSDCPrice(token ?? undefined)
  const { chainId } = useWeb3React()
  const { onUpdateTokenPrice } = useUpdateTokenData()
  if (usdcPrice && token) {
    const { symbol, address, denominator, numerator, price } = getTokenPriceParamByTokenAndPrice(token, usdcPrice)
    onUpdateTokenPrice(chainId?.toString(), symbol, address, denominator, numerator, price)
    fn(tokenAddress)
  }
  return null
}
