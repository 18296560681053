import { Trans } from '@lingui/macro'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { useWeb3React } from '@web3-react/core'
import { CLIENT } from 'apollo/client'
import BigNumber from 'bignumber.js'
import { POOL_DATA_CHART } from 'data/GQLStatement'
import { PoolDayDataSchema, PoolInfoByAddressResult } from 'data/GRTResultSchema'
import { memo, useMemo, useState } from 'react'
import { getTopPoolsByStr, getTopPoolsRankByStr, usePoolDataState } from 'state/pools/hooks'
import styled from 'styled-components/macro'
import { formatDollarAmount } from 'utils/numbers'

import { isAddress } from '../../utils'

interface IProps {
  token0Address: string | undefined
  token1Address: string | undefined
  fee: string | undefined
}

const LiquidityBox = styled(Paper)`
  padding: 0 12px;
`

const GridItem = styled.div`
  margin-bottom: 12px;
  span {
    display: inline-block;
    //width: 100%;
    &.title {
      font-size: 12px;
      margin-bottom: 4px;
      color: #1c1f26;
      margin-right: 8px;
    }
    &.data {
      font-size: 16px;
      color: #000000;
    }
    &.liquidity {
      font-size: 12px;
    }
  }
`

// 还是会进来n次，但是之后就不会了
function PoolInfoCard({ token0Address, token1Address, fee }: IProps) {
  const [poolCreatedAtTimestamp, setPoolCreatedAtTimestamp] = useState<number>(0)
  const [poolAddress, setPoolAddress] = useState<string>('')
  const [poolTxCount, setPoolTxCount] = useState<number>(0)
  const [poolLiquidity, setPoolLiquidity] = useState<BigNumber>(new BigNumber(0))
  const [poolTotalValueLockedUSD, setPoolTotalValueLockedUSD] = useState<BigNumber>(new BigNumber(0))
  const [poolVolumeUSD, setPoolVolumeUSD] = useState<BigNumber>(new BigNumber(0))
  const [poolFeesUSD, setPoolFeesUSD] = useState<BigNumber>(new BigNumber(0))
  const [poolCollectedFeesUSD, setPoolCollectedFeesUSD] = useState<BigNumber>(new BigNumber(0))
  const [poolDayData, setPoolDayData] = useState<PoolDayDataSchema[]>([])
  const {
    topVolumeUSDPools,
    topVolumeUSDPoolsRank,
    topFeesUSDPools,
    topFeesUSDPoolsRank,
    topTotalValueLockedUSDPools,
    topTotalValueLockedUSDPoolsRank,
    topTxCountPools,
    topTxCountPoolsRank,
  } = usePoolDataState()

  // TODO: 这东西不应该写在这里的，写在hook里更加合理一些。有时间再改吧
  const { chainId } = useWeb3React()
  useMemo(() => {
    if (!chainId) return
    CLIENT[chainId]
      .query<PoolInfoByAddressResult>({
        query: POOL_DATA_CHART,
        variables: {
          token0Address: token0Address?.toLowerCase(),
          token1Address: token1Address?.toLowerCase(),
          fee: Number(fee),
        },
        fetchPolicy: 'cache-first',
      })
      .then((result) => {
        if (result.errors || result.data.pools.length === 0) return
        const pools = result.data.pools[0]
        setPoolCreatedAtTimestamp(Number(pools.createdAtTimestamp))
        setPoolAddress(pools.id)
        setPoolTxCount(Number(pools.txCount))
        setPoolLiquidity(new BigNumber(pools.liquidity))
        setPoolTotalValueLockedUSD(new BigNumber(pools.totalValueLockedUSD))
        setPoolVolumeUSD(new BigNumber(pools.volumeUSD))
        setPoolFeesUSD(new BigNumber(pools.feesUSD))
        setPoolCollectedFeesUSD(new BigNumber(pools.collectedFeesUSD))
        setPoolDayData(pools.poolDayData)
      })
  }, [])

  const { volumeUSDRank, feesUSDRank, totalValueLockedRank, txCountRank } = useMemo(() => {
    const topVolumeUSDPoolsArr = getTopPoolsByStr(topVolumeUSDPools)
    const topVolumeUSDPoolsRankMap = getTopPoolsRankByStr(topVolumeUSDPoolsRank)
    const topFeesUSDPoolsRankMap = getTopPoolsRankByStr(topFeesUSDPoolsRank)
    const topTotalValueLockedUSDPoolsRankMap = getTopPoolsRankByStr(topTotalValueLockedUSDPoolsRank)
    const topTxCountPoolsRankMap = getTopPoolsRankByStr(topTxCountPoolsRank)
    let _volumeUSDRank = topVolumeUSDPoolsRankMap.get(poolAddress.toLowerCase())?.toString()
    if (_volumeUSDRank === undefined) {
      _volumeUSDRank = '50+'
    }
    let _feesUSDRank = topFeesUSDPoolsRankMap.get(poolAddress.toLowerCase())?.toString()
    if (_feesUSDRank === undefined) {
      _feesUSDRank = '50+'
    }
    let _totalValueLockedRank = topTotalValueLockedUSDPoolsRankMap.get(poolAddress.toLowerCase())?.toString()
    if (_totalValueLockedRank === undefined) {
      _totalValueLockedRank = '50+'
    }
    let _txCountRank = topTxCountPoolsRankMap.get(poolAddress.toLowerCase())?.toString()
    if (_txCountRank === undefined) {
      _txCountRank = '50+'
    }

    return {
      volumeUSDRank: _volumeUSDRank,
      feesUSDRank: _feesUSDRank,
      totalValueLockedRank: _totalValueLockedRank,
      txCountRank: _txCountRank,
    }
  }, [
    topVolumeUSDPools,
    topVolumeUSDPoolsRank,
    topFeesUSDPoolsRank,
    topTotalValueLockedUSDPoolsRank,
    topTxCountPoolsRank,
    poolAddress,
  ])

  return (
    <LiquidityBox>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        style={{ marginTop: 0, marginLeft: 0 }}
      >
        <Grid item xs={7} style={{ paddingTop: '12px', paddingLeft: 0 }}>
          <GridItem>
            <span className="title">
              <Trans>Pool create time:</Trans>{' '}
            </span>
            <span className="data">{new Date(poolCreatedAtTimestamp * 1000).toLocaleString()}</span>
          </GridItem>
          <GridItem>
            <span className="title">
              <Trans>Pool address: </Trans>
            </span>
            <span className="data">{isAddress(poolAddress)}</span>
          </GridItem>
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Grid item xs={4} style={{ paddingTop: '12px' }}>
          <GridItem style={{ marginBottom: '4px' }}>
            <span className="title">
              <Trans>Total tx count: </Trans>
            </span>
            <span className="data">
              {poolTxCount} <Trans>(Rank：{txCountRank})</Trans>
            </span>
          </GridItem>
          <GridItem style={{ marginBottom: '4px' }}>
            <span className="title">
              <Trans>Total TVL:</Trans>{' '}
            </span>
            <span className="data">
              {formatDollarAmount(poolTotalValueLockedUSD.toNumber(), 2, true)}{' '}
              <Trans>(Rank：{totalValueLockedRank})</Trans>
            </span>
          </GridItem>
          <GridItem style={{ marginBottom: '4px' }}>
            <span className="title">
              <Trans>Cumulation volume: </Trans>
            </span>
            <span className="data">
              {formatDollarAmount(poolVolumeUSD.toNumber(), 2, true)} <Trans>(Rank：{volumeUSDRank})</Trans>
            </span>
          </GridItem>
          <GridItem style={{ marginBottom: '4px' }}>
            <span className="title">
              <Trans>Cumulation fees:</Trans>{' '}
            </span>
            <span className="data">
              {formatDollarAmount(poolFeesUSD.toNumber(), 2, true)} <Trans>(Rank：{feesUSDRank})</Trans>
            </span>
          </GridItem>
        </Grid>
        {/*<Grid item xs={7} style={{ paddingTop: '12px', paddingLeft: 0 }}>*/}
        {/*  <GridItem>*/}
        {/*    <span className="title">池地址：</span>*/}
        {/*    <span className="data">{poolAddress}</span>*/}
        {/*  </GridItem>*/}
        {/*</Grid>*/}
        {/*<Divider orientation="vertical" variant="middle" flexItem />*/}
        {/*<Grid item xs={7} style={{ paddingTop: '12px' }}>*/}
        {/*  <GridItem>*/}
        {/*    <span className="title">雷达图：</span>*/}
        {/*    <span className="data">{poolAddress}</span>*/}
        {/*  </GridItem>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} style={{ paddingTop: '12px', paddingLeft: 0 }}>*/}
        {/*  <GridItem>*/}
        {/*    <span className="title">最近30天交易情况：</span>*/}
        {/*    <PoolDayChart poolDayData={poolDayData} />*/}
        {/*    /!*<span className="data">{JSON.stringify(poolDayData)}</span>*!/*/}
        {/*  </GridItem>*/}
        {/*</Grid>*/}
      </Grid>
      {/*流动池创建时间: {new Date(poolCreatedAtTimestamp * 1000).toLocaleString()} <br />*/}
      {/*池地址：{poolAddress} <br />*/}
      {/*总交易次数: {poolTxCount} (排名：{txCountRank})<br />*/}
      {/*当前总锁仓量: {poolTotalValueLockedUSD.toFixed(2)} (排名：{totalValueLockedRank})<br />*/}
      {/*累积交易量: {poolVolumeUSD.toFixed(2)} (排名：{volumeUSDRank})*/}
      {/*<br />*/}
      {/*累积总手续费: {poolFeesUSD.toFixed(2)} (排名：{feesUSDRank})<br />*/}
      {/*/!* 累积总收割手续费: {poolCollectedFeesUSD.toFixed(2)} <br /> *!/*/}
      {/*最近30天交易情况:*/}
    </LiquidityBox>
  )
}

export default memo(PoolInfoCard)
