import { TransactionResponse } from '@ethersproject/providers'
// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/macro'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { Currency, CurrencyAmount, Fraction, Percent, Price, Token } from '@uniswap/sdk-core'
import { NonfungiblePositionManager, Position } from '@uniswap/v3-sdk'
import Badge from 'components/Badge'
import RangeBadge from 'components/Badge/RangeBadge'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import Loader from 'components/Loader'
import { RowBetween, RowFixed } from 'components/Row'
// eslint-disable-next-line no-restricted-imports
import { ethers } from 'ethers'
import { useToken } from 'hooks/Tokens'
import { usePool } from 'hooks/usePools'
import { useAPR, useSpreadRange } from 'hooks/usePositionsData'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { LinkedCurrency } from 'pages/Position/PositionPage'
import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useUpdatePoolData } from 'state/pools/hooks'
import styled, { DefaultTheme } from 'styled-components/macro'
import { MEDIA_WIDTHS, ThemedText } from 'theme'
import { PositionDetails } from 'types/position'
import { formatCurrencyAmount, formatPrice } from 'utils/formatCurrencyAmount'
import { unwrappedToken } from 'utils/unwrappedToken'

import { DAI, USDC_MAINNET, USDT, WBTC, WRAPPED_NATIVE_CURRENCY } from '../../constants/tokens'
import { useV3NFTPositionManagerContract } from '../../hooks/useContract'
import { useUSDCPriceWithCache } from '../../hooks/useUSDCPrice'
import { useV3PositionFees } from '../../hooks/useV3PositionFees'
import { useIsTransactionPending, useTransactionAdder } from '../../state/transactions/hooks'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { currencyId } from '../../utils/currencyId'
import { ButtonConfirmed, ButtonPrimary } from '../Button'
import { LightCard } from '../Card'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import RateToggle from '../RateToggle'
import { Dots } from '../swap/styleds'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../TransactionConfirmationModal'
import SpreadRange from './SpreadRange'

const LinkRow = styled(Link)`
  align-items: center;
  border-radius: 20px;
  display: flex;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  color: ${({ theme }) => theme.text1};
  margin: 8px 0;
  padding: 16px;
  text-decoration: none;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg1};

  &:last-of-type {
    margin: 8px 0 0 0;
  }
  & > div:not(:first-child) {
    text-align: center;
  }
  :hover {
    background-color: ${({ theme }) => theme.bg2};
  }

  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    /* flex-direction: row; */
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    row-gap: 12px;
  `};
`

const BadgeText = styled.div`
  font-weight: 500;
  font-size: 14px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
`

const DataLineItem = styled.div`
  font-size: 14px;
`

const RangeLineItem = styled(DataLineItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  margin-top: 4px;
  width: 100%;

  //   ${({ theme }) => theme.mediaWidth.upToSmall`
//   // background-color: ${({ theme }) => theme.bg2};
//     border-radius: 12px;
//     padding: 8px 0;
// `};
`

const DoubleArrow = styled.span`
  margin: 0 2px;
  color: ${({ theme }) => theme.text3};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 4px;
    padding: 4px;
  `};
`

const RangeText = styled.span`
  /* background-color: ${({ theme }) => theme.bg2}; */
  padding: 0.25rem 0.2rem;
  border-radius: 8px;
  text-align: center;
`

const ExtentsText = styled.span`
  color: ${({ theme }) => theme.text3};
  font-size: 14px;
  margin-right: 0;
  // ${({ theme }) => theme.mediaWidth.upToSmall`
  //   display: none;
  // `};
`

const PrimaryPositionIdData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-right: 8px;
  }
`

const DataText = styled.div`
  font-weight: 600;
  font-size: 18px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `};
`

const ItemDetail = styled.div`
  width: 100%;
  border: 1px solid #666;
`

export const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  border-radius: 12px;
  padding: 4px 6px;
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex: 1 1 auto;
    width: 49%;
  `};
  text-transform: none !important;
`
interface CustomPositionListItemProps {
  theme: DefaultTheme
  chainId: number | undefined
  account: string | null | undefined
  provider: ethers.providers.Web3Provider | undefined
  positionManager: NonfungiblePositionManager | null
}

export interface PositionListItemProps {
  positionDetails: PositionDetails
  selected: string[]
  setSelected: (data: string[]) => void
  customPositionDetails?: CustomPositionListItemProps
}

export function getPriceOrderingFromPositionForUI(position?: Position): {
  priceLower?: Price<Token, Token>
  priceUpper?: Price<Token, Token>
  quote?: Token
  base?: Token
} {
  if (!position) {
    return {}
  }

  const token0 = position.amount0.currency
  const token1 = position.amount1.currency

  // if token0 is a dollar-stable asset, set it as the quote token
  const stables = [DAI, USDC_MAINNET, USDT]
  if (stables.some((stable) => stable.equals(token0))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // if token1 is an ETH-/BTC-stable asset, set it as the base token
  const bases = [...Object.values(WRAPPED_NATIVE_CURRENCY), WBTC]
  if (bases.some((base) => base && base.equals(token1))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // if both prices are below 1, invert
  if (position.token0PriceUpper.lessThan(1)) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // otherwise, just return the default
  return {
    priceLower: position.token0PriceLower,
    priceUpper: position.token0PriceUpper,
    quote: token1,
    base: token0,
  }
}

export default function PositionListItem({
  positionDetails,
  selected,
  setSelected,
  customPositionDetails,
}: PositionListItemProps) {
  const {
    token0: token0Address,
    token1: token1Address,
    fee: feeAmount,
    liquidity,
    tickLower,
    tickUpper,
    tokenId,
  } = positionDetails

  const { theme, chainId, account, provider } = customPositionDetails!

  const token0 = useToken(token0Address)
  const token1 = useToken(token1Address)

  // const currency0 = token0 ? unwrappedToken(token0) : undefined
  // const currency1 = token1 ? unwrappedToken(token1) : undefined
  const { currency0, currency1 } = useMemo(() => {
    const currency0 = token0 ? unwrappedToken(token0) : undefined
    const currency1 = token1 ? unwrappedToken(token1) : undefined
    return { currency0, currency1 }
  }, [token0, token1])

  // construct Position from details returned
  const [, pool] = usePool(currency0 ?? undefined, currency1 ?? undefined, feeAmount)

  const position = useMemo(() => {
    if (pool) {
      return new Position({ pool, liquidity: liquidity.toString(), tickLower, tickUpper })
    }
    return undefined
  }, [liquidity, pool, tickLower, tickUpper])

  // const tickAtLimit = useIsTickAtLimit(feeAmount, tickLower, tickUpper)

  // prices
  let { priceLower, priceUpper, quote, base } = getPriceOrderingFromPositionForUI(position)

  const [manuallyInverted, setManuallyInverted] = useState(false)
  // handle manual inversion
  if (manuallyInverted) {
    ;[priceLower, priceUpper, base, quote] = [priceUpper?.invert(), priceLower?.invert(), quote, base]
  }
  const currencyQuote = quote && unwrappedToken(quote)
  const currencyBase = base && unwrappedToken(base)

  // check if price is within range
  const outOfRange: boolean = pool ? pool.tickCurrent < tickLower || pool.tickCurrent >= tickUpper : false
  // const positionSummaryLink = '/pool/' + positionDetails.tokenId

  const removed = liquidity?.eq(0)

  // 下面都是新的
  // const theme = useTheme()
  // const { chainId, account, library } = useActiveWeb3React()
  const positionManager = useV3NFTPositionManagerContract()

  const owner = useSingleCallResult(!!tokenId ? positionManager : null, 'ownerOf', [tokenId]).result?.[0]
  const ownsNFT = owner === account || positionDetails?.operator === account
  const [receiveWETH, setReceiveWETH] = useState(false)
  const [collecting, setCollecting] = useState<boolean>(false)
  const [collectMigrationHash, setCollectMigrationHash] = useState<string | null>(null)
  const isCollectPending = useIsTransactionPending(collectMigrationHash ?? undefined)
  const [feeValue0, feeValue1] = useV3PositionFees(pool ?? undefined, positionDetails?.tokenId, receiveWETH)
  const [showConfirm, setShowConfirm] = useState(false)
  const inverted = token1 ? base?.equals(token1) : undefined
  const addTransaction = useTransactionAdder()

  const feeValueUpper = inverted ? feeValue0 : feeValue1
  const feeValueLower = inverted ? feeValue1 : feeValue0

  const collect = useCallback(() => {
    if (!chainId || !feeValue0 || !feeValue1 || !positionManager || !account || !tokenId || !provider) return

    setCollecting(true)

    const { calldata, value } = NonfungiblePositionManager.collectCallParameters({
      tokenId: tokenId.toString(),
      expectedCurrencyOwed0: feeValue0,
      expectedCurrencyOwed1: feeValue1,
      recipient: account,
    })

    const txn = {
      to: positionManager.address,
      data: calldata,
      value,
    }

    provider
      .getSigner()
      .estimateGas(txn)
      .then((estimate) => {
        const newTxn = {
          ...txn,
          gasLimit: calculateGasMargin(estimate),
        }

        return provider
          .getSigner()
          .sendTransaction(newTxn)
          .then((response: TransactionResponse) => {
            setCollectMigrationHash(response.hash)
            setCollecting(false)
            // addTransaction(response, {
            //   // summary: `Collect ${feeValue0.currency.symbol}/${feeValue1.currency.symbol} fees`,
            //   type: TransactionType.COLLECT_FEES,
            //   currencyId0: currencyId(currency0ForFeeCollectionPurposes),
            //   currencyId1: currencyId(currency1ForFeeCollectionPurposes),
            // })
          })
      })
      .catch((error) => {
        setCollecting(false)
        console.error(error)
      })
  }, [chainId, feeValue0, feeValue1, positionManager, account, tokenId, addTransaction, provider])

  function modalHeader() {
    return (
      <AutoColumn gap={'md'} style={{ marginTop: '20px' }}>
        <LightCard padding="12px 16px">
          <AutoColumn gap="md">
            <RowBetween>
              <RowFixed>
                <CurrencyLogo currency={feeValueUpper?.currency} size={'20px'} style={{ marginRight: '0.5rem' }} />
                <ThemedText.Main>{feeValueUpper ? formatCurrencyAmount(feeValueUpper, 4) : '-'}</ThemedText.Main>
              </RowFixed>
              <ThemedText.Main>{feeValueUpper?.currency?.symbol}</ThemedText.Main>
            </RowBetween>
            <RowBetween>
              <RowFixed>
                <CurrencyLogo currency={feeValueLower?.currency} size={'20px'} style={{ marginRight: '0.5rem' }} />
                <ThemedText.Main>{feeValueLower ? formatCurrencyAmount(feeValueLower, 4) : '-'}</ThemedText.Main>
              </RowFixed>
              <ThemedText.Main>{feeValueLower?.currency?.symbol}</ThemedText.Main>
            </RowBetween>
          </AutoColumn>
        </LightCard>
        <ThemedText.Italic>
          <Trans>Collecting fees will withdraw currently available fees for you.</Trans>
        </ThemedText.Italic>
        <ButtonPrimary onClick={collect}>
          <Trans>Collect</Trans>
        </ButtonPrimary>
      </AutoColumn>
    )
  }

  const { onUpdatePositionData: onUpdatePoolData, onUpdatePositionAPR } = useUpdatePoolData()

  const price0 = useUSDCPriceWithCache(chainId?.toString(), token0 ?? undefined)
  const price1 = useUSDCPriceWithCache(chainId?.toString(), token1 ?? undefined)

  // const price0 = new Price(token0!, USDC, '1', '1')
  // const price1 = new Price(token1!, USDC, '2', '1')
  // const price0 = useUSDCPrice(token0 ?? undefined)
  // const price1 = useUSDCPrice(token1 ?? undefined)

  const fiatValueOfFees: CurrencyAmount<Currency> | null = useMemo(() => {
    if (!price0 || !price1 || !feeValue0 || !feeValue1) return null

    // we wrap because it doesn't matter, the quote returns a USDC amount
    const feeValue0Wrapped = feeValue0?.wrapped
    const feeValue1Wrapped = feeValue1?.wrapped

    if (!feeValue0Wrapped || !feeValue1Wrapped) return null

    const amount0 = price0.quote(feeValue0Wrapped)
    const amount1 = price1.quote(feeValue1Wrapped)
    return amount0.add(amount1)
  }, [price0, price1, feeValue0, feeValue1])

  const fiatValueOfLiquidity: CurrencyAmount<Token> | null = useMemo(() => {
    if (!price0 || !price1 || !position) return null
    const amount0 = price0.quote(position.amount0)
    const amount1 = price1.quote(position.amount1)
    return amount0.add(amount1)
  }, [price0, price1, position])

  const ratio = useMemo(() => {
    return priceLower && pool && priceUpper
      ? getRatio(
          inverted ? priceUpper.invert() : priceLower,
          pool.token0Price,
          inverted ? priceLower.invert() : priceUpper
        )
      : undefined
  }, [inverted, pool, priceLower, priceUpper])

  function getRatio(
    lower: Price<Currency, Currency>,
    current: Price<Currency, Currency>,
    upper: Price<Currency, Currency>
  ) {
    try {
      if (!current.greaterThan(lower)) {
        return 100
      } else if (!current.lessThan(upper)) {
        return 0
      }

      const a = Number.parseFloat(lower.toSignificant(15))
      const b = Number.parseFloat(upper.toSignificant(15))
      const c = Number.parseFloat(current.toSignificant(15))

      const ratio = Math.floor((1 / ((Math.sqrt(a * b) - Math.sqrt(b * c)) / (c - Math.sqrt(b * c)) + 1)) * 100)

      if (ratio < 0 || ratio > 100) {
        throw Error('Out of range')
      }

      return ratio
    } catch {
      return undefined
    }
  }
  const tokenIdString = tokenId.toString()

  useMemo(() => {
    if (!removed) {
      const fiatValueOfLiquidity_num = fiatValueOfLiquidity?.greaterThan(new Fraction(1, 100))
        ? Number(fiatValueOfLiquidity.toFixed(2))
        : 0
      const fiatValueOfFees_num = fiatValueOfFees?.greaterThan(new Fraction(1, 100))
        ? Number(fiatValueOfFees.toFixed(2))
        : 0
      // 这里要特别他妈注意，什么时候用(a,b,c),什么时候用({a:a1,b2:b,c:c3}) 这个很重要，是我自己没理解清楚，坑啊！
      onUpdatePoolData(tokenIdString, !outOfRange, fiatValueOfLiquidity_num, fiatValueOfFees_num)
    }
  }, [removed, tokenIdString, outOfRange, fiatValueOfLiquidity, fiatValueOfFees])

  const [openRow, setOpenRow] = useState(false)

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const NoBorderTbcell = styled(TableCell)`
    border-bottom: unset;
    padding: 6px;
  `
  const ItemMoreBox = styled.div`
    .title {
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
      margin: 0;
      padding: 8px 0;
    }
    .range-subtitle {
      text-align: center;
    }
  `
  const PriceRange = styled.div`
    .direction {
      margin-top: 0;
      margin-bottom: 8px;
      text-align: center;
    }
    .range-content {
      display: flex;
      justify-content: space-between;
      .range-item {
        display: inline-block;
        text-align: left;
        .range-text {
          display: inline-block;
        }
      }
    }
  `

  // 下面是之前监听仓位变化的，如果仓位有变化则会提醒。现在去掉了
  // const { positions } = usePositionRemindState()
  // const tokenIdInRemind = positions.filter((item) => item.tokenId === tokenId.toString()).length !== 0
  // const remindItem = positions.filter((item) => item.tokenId === tokenId.toString())[0]
  // const [showListenConfig, setShowListenConfig] = useState(false)
  // const [listenMin, setListenMin] = useState(tokenIdInRemind ? remindItem.remindMin : '')
  // const [listenMinErr, setListenMinErr] = useState(false)
  // const [listenMax, setListenMax] = useState(tokenIdInRemind ? remindItem.remindMax : '')
  // const [listenMaxErr, setListenMaxErr] = useState(false)
  // const { onUpdatePositionRemind } = useUpdatePositionRemind()
  // const cancleListenConfig = () => {
  //   setListenMaxErr(false)
  //   setListenMinErr(false)
  //   setShowListenConfig(false)
  // }
  // const saveListenConfig = () => {
  //   const positionData = {
  //     tokenId: tokenId.toString(),
  //     token0Addr: token0Address,
  //     token1Addr: token1Address,
  //     fee: feeAmount,
  //     remindMin: new BigNumber(listenMin).toNumber(),
  //     remindMax: new BigNumber(listenMax).toNumber(),
  //     isReminded: false,
  //   }
  //   if (positions.filter((item) => item.tokenId === tokenId.toString()).length === 0) {
  //     onUpdatePositionRemind([...positions, ...[positionData]])
  //   } else {
  //     const newPositions = positions.map((item) => {
  //       const newItem = { ...item }
  //       if (newItem.tokenId === tokenId.toString()) {
  //         newItem.remindMin = new BigNumber(listenMin).toNumber()
  //         newItem.remindMax = new BigNumber(listenMax).toNumber()
  //         newItem.isReminded = false
  //       }
  //       return newItem
  //     })
  //     onUpdatePositionRemind(newPositions)
  //   }
  //   setShowListenConfig(false)
  // }
  // const handleListenMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.value === '') {
  //     setListenMinErr(true)
  //   } else {
  //     setListenMinErr(false)
  //   }
  //   setListenMin(event.target.value)
  // }
  // const handleListenMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.value === '') {
  //     setListenMaxErr(true)
  //   } else {
  //     setListenMaxErr(false)
  //   }
  //   setListenMax(event.target.value)
  // }

  // 获取APR
  // const positionInfo = usePosDataByTokenId(tokenIdString)
  // const poolAddress = positionInfo?.pool.id
  const fiatValueOfLiquidity_num = fiatValueOfLiquidity?.greaterThan(new Fraction(1, 100))
    ? Number(fiatValueOfLiquidity.toExact())
    : 0
  const fiatValueOfFees_num = fiatValueOfFees?.greaterThan(new Fraction(1, 100)) ? Number(fiatValueOfFees.toExact()) : 0
  const { apr, currentTimestamp, createPositionTimestamp, lastCollectFeesTimestamp } = useAPR(
    tokenId.toNumber(),
    owner,
    fiatValueOfLiquidity_num,
    fiatValueOfFees_num
  )

  useMemo(() => {
    if (
      !tokenIdString ||
      typeof fiatValueOfLiquidity_num === 'undefined' ||
      typeof fiatValueOfFees_num === 'undefined' ||
      typeof apr === 'undefined' ||
      typeof currentTimestamp === 'undefined' ||
      typeof createPositionTimestamp === 'undefined' ||
      typeof lastCollectFeesTimestamp === 'undefined'
    ) {
      return
    }
    onUpdatePositionAPR(
      tokenIdString,
      fiatValueOfLiquidity_num,
      fiatValueOfFees_num,
      apr,
      currentTimestamp,
      createPositionTimestamp,
      lastCollectFeesTimestamp
    )
  }, [tokenIdString, apr])

  const spreadRange = useSpreadRange(position?.token0PriceLower, position?.token0PriceUpper, 'percentage')

  return (
    <>
      <TableRow key={tokenId.toNumber()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        {/*<NoBorderTbcell padding="checkbox" onClick={(event) => handleClick(event, tokenId.toString())}>*/}
        {/*  <Checkbox*/}
        {/*    color="primary"*/}
        {/*    checked={isSelected(tokenId.toString())}*/}
        {/*    inputProps={{*/}
        {/*      'aria-labelledby': `enhanced-table-checkbox-${tokenId}`,*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</NoBorderTbcell>*/}
        {/*<NoBorderTbcell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpenRow(!openRow)}>
            {openRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </NoBorderTbcell>*/}
        {/* 改 */}
        <NoBorderTbcell component="th" scope="row">
          <DoubleCurrencyLogo currency0={currencyBase} currency1={currencyQuote} size={18} margin />
          <DataText>
            &nbsp;{currencyQuote?.symbol}&nbsp;/&nbsp;{currencyBase?.symbol}
          </DataText>
          &nbsp;
          <Badge>
            <BadgeText>
              <Trans>{new Percent(feeAmount, 1_000_000).toSignificant()}%</Trans>
            </BadgeText>
          </Badge>
        </NoBorderTbcell>

        <NoBorderTbcell align="right">
          <span>{tokenId.toNumber()}</span>
        </NoBorderTbcell>
        <NoBorderTbcell align="right">
          {apr ? <span style={{ color: '#00B464', fontWeight: 'bold' }}>{(100 * apr).toFixed(2)}%</span> : '-'}
        </NoBorderTbcell>

        <NoBorderTbcell align="right">
          <div style={{ margin: 0, textAlign: 'right' }}>
            {currencyBase && currencyQuote && (
              <div style={{ display: 'inline-block', margin: '0 auto' }}>
                <RateToggle
                  currencyA={currencyBase}
                  currencyB={currencyQuote}
                  handleRateToggle={() => setManuallyInverted(!manuallyInverted)}
                />
              </div>
            )}
            {/*<HoverInlineText text={currencyQuote?.symbol} /> per <HoverInlineText text={currencyBase?.symbol ?? ''} />*/}
          </div>
          {priceLower && priceUpper ? (
            <RangeLineItem style={{ alignItems: 'flex-start' }}>
              <RangeText>
                {/* <ExtentsText>
                  <Trans>Min: </Trans>
                </ExtentsText>
                <br /> */}
                <ExtentsText>{formatPrice(priceLower, 5)}</ExtentsText>
              </RangeText>{' '}
              <RangeText>
                <DoubleArrow>⟷</DoubleArrow>
                <br />
                <SpreadRange
                  spreadRange={spreadRange}
                  tickUpper={tickUpper}
                  tickLower={tickLower}
                  tickCurrent={pool?.tickCurrent}
                  invert={manuallyInverted}
                />
              </RangeText>
              {/*<HideSmall>*/}
              {/*  <DoubleArrow>⟷</DoubleArrow>{' '}*/}
              {/*</HideSmall>*/}
              {/*<SmallOnly>*/}
              {/*  <DoubleArrow>↕</DoubleArrow>{' '}*/}
              {/*</SmallOnly>*/}
              <RangeText>
                {/* <ExtentsText>
                  <Trans>Max:</Trans>
                </ExtentsText>
                <br /> */}
                <ExtentsText>{formatPrice(priceUpper, 5)}</ExtentsText>
              </RangeText>
            </RangeLineItem>
          ) : (
            <Loader />
          )}
        </NoBorderTbcell>

        <NoBorderTbcell align="right">
          {fiatValueOfLiquidity?.greaterThan(new Fraction(1, 100)) ? (
            <ThemedText.LargeHeader fontSize="22px" fontWeight={500}>
              <Trans>${fiatValueOfLiquidity.toFixed(2, { groupSeparator: ',' })}</Trans>
            </ThemedText.LargeHeader>
          ) : (
            <ThemedText.LargeHeader color={theme.text1} fontSize="22px" fontWeight={500}>
              <Trans>$-</Trans>
            </ThemedText.LargeHeader>
          )}
          <RowBetween>
            <LinkedCurrency chainId={chainId} currency={currencyQuote} />
            <RowFixed>
              <ThemedText.Main>
                {inverted ? position?.amount0.toSignificant(4) : position?.amount1.toSignificant(4)}
              </ThemedText.Main>
              {typeof ratio === 'number' && !removed ? (
                <Badge style={{ marginLeft: '10px' }}>
                  <ThemedText.Main fontSize={11}>
                    <Trans>{inverted ? ratio : 100 - ratio}%</Trans>
                  </ThemedText.Main>
                </Badge>
              ) : null}
            </RowFixed>
          </RowBetween>
          <RowBetween>
            <LinkedCurrency chainId={chainId} currency={currencyBase} />
            <RowFixed>
              <ThemedText.Main>
                {inverted ? position?.amount1.toSignificant(4) : position?.amount0.toSignificant(4)}
              </ThemedText.Main>
              {typeof ratio === 'number' && !removed ? (
                <Badge style={{ marginLeft: '10px' }}>
                  <ThemedText.Main color={theme.text2} fontSize={11}>
                    <Trans>{inverted ? 100 - ratio : ratio}%</Trans>
                  </ThemedText.Main>
                </Badge>
              ) : null}
            </RowFixed>
          </RowBetween>
        </NoBorderTbcell>

        <NoBorderTbcell align="right">
          {fiatValueOfFees?.greaterThan(new Fraction(1, 100)) ? (
            <ThemedText.LargeHeader color={theme.green1} fontSize="24px" fontWeight={500}>
              <Trans>${fiatValueOfFees.toFixed(2, { groupSeparator: ',' })}</Trans>
            </ThemedText.LargeHeader>
          ) : (
            <ThemedText.LargeHeader color={theme.text1} fontSize="24px" fontWeight={500}>
              <Trans>$-</Trans>
            </ThemedText.LargeHeader>
          )}
          <RowBetween>
            <RowFixed>
              <CurrencyLogo currency={feeValueUpper?.currency} size={'20px'} style={{ marginRight: '0.5rem' }} />
              <ThemedText.Main>{feeValueUpper?.currency?.symbol}</ThemedText.Main>
            </RowFixed>
            <RowFixed>
              <ThemedText.Main>{feeValueUpper ? formatCurrencyAmount(feeValueUpper, 4) : '-'}</ThemedText.Main>
            </RowFixed>
          </RowBetween>
          <RowBetween>
            <RowFixed>
              <CurrencyLogo currency={feeValueLower?.currency} size={'20px'} style={{ marginRight: '0.5rem' }} />
              <ThemedText.Main>{feeValueLower?.currency?.symbol}</ThemedText.Main>
            </RowFixed>
            <RowFixed>
              <ThemedText.Main>{feeValueLower ? formatCurrencyAmount(feeValueLower, 4) : '-'}</ThemedText.Main>
            </RowFixed>
          </RowBetween>
        </NoBorderTbcell>
        <NoBorderTbcell align="center">
          <div style={{ minWidth: '60px' }}>
            <RangeBadge removed={removed} inRange={!outOfRange} />
          </div>
        </NoBorderTbcell>

        {ownsNFT ? (
          <NoBorderTbcell align="center">
            {currency0 && currency1 && feeAmount && tokenId ? (
              <ResponsiveButtonPrimary
                as={Link}
                to={`/increase/${currencyId(currency0)}/${currencyId(currency1)}/${feeAmount}/${tokenId}`}
                // width="fit-content"
                // padding="6px 8px"
                // borderRadius="12px"
                style={{ marginRight: '8px' }}
              >
                <Trans>Increase liquidity</Trans>
              </ResponsiveButtonPrimary>
            ) : null}
            {tokenId && !removed ? (
              <ResponsiveButtonPrimary
                as={Link}
                to={`/remove/${tokenId}`}
                // width="fit-content"
                // padding="6px 8px"
                // borderRadius="12px"
                style={{ marginTop: '6px' }}
              >
                <Trans>Remove liquidity</Trans>
              </ResponsiveButtonPrimary>
            ) : null}
            {ownsNFT && (feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0) || !!collectMigrationHash) ? (
              <ButtonConfirmed
                disabled={collecting || !!collectMigrationHash}
                confirmed={!!collectMigrationHash && !isCollectPending}
                width="fit-content"
                style={{ borderRadius: '12px', marginTop: '6px' }}
                padding="4px 8px"
                onClick={() => setShowConfirm(true)}
              >
                {!!collectMigrationHash && !isCollectPending ? (
                  <ThemedText.Main color={theme.text1}>
                    <Trans> Collected</Trans>
                  </ThemedText.Main>
                ) : isCollectPending || collecting ? (
                  <ThemedText.Main color={theme.text1}>
                    {' '}
                    <Dots>
                      <Trans>Collecting</Trans>
                    </Dots>
                  </ThemedText.Main>
                ) : (
                  <>
                    <ThemedText.Main color={theme.white}>
                      <Trans>Collect fees</Trans>
                    </ThemedText.Main>
                  </>
                )}
              </ButtonConfirmed>
            ) : null}
            <TransactionConfirmationModal
              isOpen={showConfirm}
              onDismiss={() => setShowConfirm(false)}
              attemptingTxn={collecting}
              hash={collectMigrationHash ?? ''}
              content={() => (
                <ConfirmationModalContent
                  title={<Trans>Claim fees</Trans>}
                  onDismiss={() => setShowConfirm(false)}
                  topContent={modalHeader}
                />
              )}
              pendingText={<Trans>Collecting fees</Trans>}
            />
            <ResponsiveButtonPrimary
              as={Link}
              to={`/position/${tokenId.toString()}`}
              // width="fit-content"
              // padding="6px 8px"
              // borderRadius="12px"
              style={{ width: '90px', marginTop: '6px' }}
              target="_blank"
            >
              <Trans>More</Trans>
            </ResponsiveButtonPrimary>
            {/* <ResponsiveButtonPrimary
              as={Button}
              // width="fit-content"
              // padding="6px 8px"
              // borderRadius="12px"
              style={{ width: '90px', marginTop: '6px' }}
              onClick={() => setShowListenConfig(true)}
            >
              <Trans>Reminder</Trans>
            </ResponsiveButtonPrimary> */}
          </NoBorderTbcell>
        ) : (
          <NoBorderTbcell align="center">
            <ResponsiveButtonPrimary
              as={Link}
              to={`/position/${tokenId.toString()}`}
              // width="fit-content"
              // padding="6px 8px"
              // borderRadius="12px"
              style={{ width: '90px', marginTop: '6px' }}
              target="_blank"
            >
              <Trans>More</Trans>
            </ResponsiveButtonPrimary>
            {/* <ResponsiveButtonPrimary
              as={Button}
              // width="fit-content"
              // padding="6px 8px"
              // borderRadius="12px"
              style={{ width: '90px', marginTop: '6px' }}
              onClick={() => setShowListenConfig(true)}
            >
              <Trans>Reminder</Trans>
            </ResponsiveButtonPrimary> */}
          </NoBorderTbcell>
        )}
      </TableRow>
      {/* <Dialog open={showListenConfig}>
        <DialogTitle>
          <Trans>Reminder</Trans>
        </DialogTitle>
        <DialogContent>
          {pool ? (
            <>
              <Trans>Current price</Trans>: {pool!.token1Price.toSignificant(6)} {currencyQuote?.symbol} per{' '}
              {currencyBase?.symbol}
            </>
          ) : null}
          <Grid container spacing={4} style={{ width: 600 }}>
            <Grid item xs={6}>
              <TextField
                required
                error={listenMinErr}
                margin="dense"
                id="name"
                label={t`Min Price`}
                type="number"
                fullWidth
                variant="standard"
                value={listenMin}
                onChange={handleListenMinChange}
                helperText={listenMinErr ? t`Input can not be empty` : ' '}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                error={listenMaxErr}
                margin="dense"
                id="name"
                label={t`Max Price`}
                type="number"
                fullWidth
                value={listenMax}
                variant="standard"
                onChange={handleListenMaxChange}
                helperText={listenMaxErr ? t`Input can not be empty` : ' '}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancleListenConfig}>
            <Trans>Cancel</Trans>
          </Button>
          <Button onClick={saveListenConfig}>
            <Trans>Confirm</Trans>
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  )
}
