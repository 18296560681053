import { SupportedChainId } from 'constants/chains'

// TODO: 这几个是Uniswap V3交易中固定的数值，后续要确认在polygon、arbitrum、optimism上是否都是一样的，如果是不一样的，则要修改
export const logAddress = '0xc36442b4a4522e871399cd717abdd847ab11fe88'
export const topic_DecreaseLiquidity = '0x26f6a048ee9138f2c0ce266f322cb99228e8d619ae2bff30c67f8dcf9d2377b4'
export const topic_Collect = '0x40d0efd1a53d60ecbf40971b9daf7dc90178c3aadc7aab1765632738fa8b8f01'

export const Log_Address: Record<number, string> = {
  [SupportedChainId.MAINNET]: '0xc36442b4a4522e871399cd717abdd847ab11fe88',
  [SupportedChainId.ARBITRUM_ONE]: '0xc36442b4a4522e871399cd717abdd847ab11fe88',
  [SupportedChainId.OPTIMISM]: '0xc36442b4a4522e871399cd717abdd847ab11fe88',
  [SupportedChainId.POLYGON]: '0xc36442b4a4522e871399cd717abdd847ab11fe88',
}
