import { useWeb3React } from '@web3-react/core'
import { CLIENT } from 'apollo/client'
import { TOP_POOL_CHART } from 'data/GQLStatement'
import { PoolInfoByAddressResult } from 'data/GRTResultSchema'
import React, { useMemo } from 'react'
import { useUpdatePoolData } from 'state/pools/hooks'

export default function RequestTopPools() {
  const {
    onUpdateTopVolumeUSDPools,
    onUpdateTopFeesUSDPools,
    onUpdateTopTotalValueLockedUSDPools,
    onUpdateTopTxCountUSDPools,
  } = useUpdatePoolData()

  // 下面这些真不应该写在这里啊，当时我应该是脑子抽风了。后面最好还是单独提出去写hook
  const { chainId } = useWeb3React()
  useMemo(() => {
    if (!chainId) return
    CLIENT[chainId]
      .query<PoolInfoByAddressResult>({
        query: TOP_POOL_CHART,
        variables: {
          orderBy: 'volumeUSD',
          first: 50,
        },
        fetchPolicy: 'cache-first',
      })
      .then((result) => {
        const topPools = result.data.pools
        onUpdateTopVolumeUSDPools(topPools)
      })

    CLIENT[chainId]
      .query<PoolInfoByAddressResult>({
        query: TOP_POOL_CHART,
        variables: {
          orderBy: 'feesUSD',
          first: 50,
        },
        fetchPolicy: 'cache-first',
      })
      .then((result) => {
        const topPools = result.data.pools
        onUpdateTopFeesUSDPools(topPools)
      })

    CLIENT[chainId]
      .query<PoolInfoByAddressResult>({
        query: TOP_POOL_CHART,
        variables: {
          orderBy: 'totalValueLockedUSD',
          first: 50,
        },
        fetchPolicy: 'cache-first',
      })
      .then((result) => {
        const topPools = result.data.pools
        onUpdateTopTotalValueLockedUSDPools(topPools)
      })

    CLIENT[chainId]
      .query<PoolInfoByAddressResult>({
        query: TOP_POOL_CHART,
        variables: {
          orderBy: 'txCount',
          first: 50,
        },
        fetchPolicy: 'cache-first',
      })
      .then((result) => {
        const topPools = result.data.pools
        onUpdateTopTxCountUSDPools(topPools)
      })
  }, [
    onUpdateTopVolumeUSDPools,
    onUpdateTopFeesUSDPools,
    onUpdateTopTotalValueLockedUSDPools,
    onUpdateTopTxCountUSDPools,
    chainId,
  ])

  return <></>
}
