import { createAction } from '@reduxjs/toolkit'

export const updateTokenPrice = createAction<{
  chainId: string | undefined
  symbol: string
  address: string
  denominator: string
  numerator: string
  price: string
}>('tokens/updateTokenPrice')
