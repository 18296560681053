import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { SupportedChainId } from 'constants/chains'
import { CHAIN_SUBGRAPH_URL } from 'state/data/slice'

const ethereumClient = new ApolloClient({
  uri: CHAIN_SUBGRAPH_URL[SupportedChainId.MAINNET],
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})
const polygonClient = new ApolloClient({
  uri: CHAIN_SUBGRAPH_URL[SupportedChainId.POLYGON],
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})
const arbitrumClient = new ApolloClient({
  uri: CHAIN_SUBGRAPH_URL[SupportedChainId.ARBITRUM_ONE],
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})
const optimismClient = new ApolloClient({
  uri: CHAIN_SUBGRAPH_URL[SupportedChainId.OPTIMISM],
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const CLIENT: Record<number, ApolloClient<NormalizedCacheObject>> = {
  [SupportedChainId.MAINNET]: ethereumClient,

  [SupportedChainId.ARBITRUM_ONE]: arbitrumClient,

  [SupportedChainId.OPTIMISM]: optimismClient,

  [SupportedChainId.POLYGON]: polygonClient,
}

export const healthClient = new ApolloClient({
  uri: 'https://api.thegraph.com/index-node/graphql',
  cache: new InMemoryCache(),
})

export const blockClient = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
})
