import Alert from '@material-ui/core/Alert'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components/macro'

import ConfirmDialog from '../../components/ConfirmDialog'
import { useAccountManager } from '../../constants/accounts'
import { isAddress } from '../../utils'

const PageTitle = styled.h2`
  text-align: left;
`
const AccountPageBox = styled.div`
  padding-left: 156px;
`
export default function AddAccount({ history }: RouteComponentProps) {
  const { addAccount } = useAccountManager()
  const [newAccount, setNewAccount] = useState<string>('')
  const [accountErr, setAccountErr] = useState<boolean>(false)
  const [showAddAccountDialog, setShowAddAccountDialog] = useState<boolean>(false)
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [remark, setRemark] = useState<string>('')
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setNewAccount(event.target.value)
    checkAccount(event.target.value)
  }
  function checkAccount(account: string) {
    if (isAddress(account)) {
      setAccountErr(false)
      return true
    } else {
      setAccountErr(true)
      return false
    }
  }
  function AddAccount(account?: string) {
    if (account) {
      const check: boolean = checkAccount(account)
      if (check && newAccount.trim() !== '') {
        setAccountErr(false)
        addAccount(newAccount.trim(), remark)
        // editAccountRemark(newAccount.trim(), remark)
        setShowPopup(true)
        setShowAddAccountDialog(false)
        setTimeout(() => {
          history.push('/accounts')
        }, 2000)
      } else {
        setAccountErr(true)
        setShowAddAccountDialog(true)
      }
    } else {
      setAccountErr(false)
      setShowAddAccountDialog(false)
    }
  }
  function showAddDialog() {
    setShowAddAccountDialog(true)
  }
  function hidePopup() {
    setShowPopup(false)
  }
  function remarkChange(event: React.ChangeEvent<HTMLInputElement>) {
    setRemark(event.target.value)
  }
  return (
    <AccountPageBox>
      <PageTitle>添加地址</PageTitle>
      <Button variant="outlined" onClick={showAddDialog}>
        添加
      </Button>
      <ConfirmDialog onClose={AddAccount} value={newAccount} open={showAddAccountDialog} title="添加观察地址">
        <TextField
          sx={{ width: '100%' }}
          label="支持 Ethereum地址"
          value={newAccount}
          inputProps={{ 'aria-label': '添加地址' }}
          onChange={handleChange}
          error={accountErr}
          helperText={accountErr ? '地址错误' : ' '}
        />
        <TextField
          sx={{ width: '100%' }}
          label="添加备注"
          value={remark}
          inputProps={{ 'aria-label': '添加备注' }}
          onChange={remarkChange}
          // error={remarkErr}
          // helperText={remarkErr ? '地址备注格式错误' : ' '}
        />
      </ConfirmDialog>
      <Snackbar
        open={showPopup}
        autoHideDuration={2000}
        onClose={hidePopup}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={hidePopup} severity="success" sx={{ width: '100%' }}>
          添加成功
        </Alert>
      </Snackbar>
    </AccountPageBox>
  )
}
