// eslint-disable-next-line no-restricted-imports
import { t } from '@lingui/macro'
import { Button, Menu, MenuItem, Toolbar } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import CheckIcon from '@material-ui/icons/Check'
import HistoryIcon from '@material-ui/icons/History'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import TelegramIcon from '@material-ui/icons/Telegram'
import TwitterIcon from '@material-ui/icons/Twitter'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { darken } from 'polished'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { LOCALE_LABEL } from '../../constants/locales'
import { useActiveLocale } from '../../hooks/useActiveLocale'
import { useLocationLinkProps } from '../../hooks/useLocationLinkProps'
import { ExternalLink } from '../../theme'

const activeClassName = 'ACTIVE'
const SideBarItem = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: 100%;
  font-weight: 500;

  &.${activeClassName} {
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
    background-color: ${({ theme }) => theme.bg2};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`
const Version = styled.p`
  display: block;
  width: 100%;
  margin-bottom: 0;
  padding-left: 16px;
  height: 30px;
  margin-top: -30px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
`
const Medias = styled.p`
  height: 30px;
  display: block;
  width: 100%;
  margin-bottom: 0;
  margin-top: -60px;
  position: relative;
  padding-left: 16px;
  z-index: 2;
`
const MediaLink = styled(ExternalLink)`
  margin-right: 8px;
`
const Language = styled.div`
  height: 30px;
  display: block;
  width: 100%;
  margin-bottom: 0;
  margin-top: -80px;
  position: relative;
  padding-left: 16px;
  z-index: 2;
`

const LangOptions = ['zh-CN', 'en-US'] as const
type SupportedLocale = typeof LangOptions[number]

export default function Sidebar({ showSidebar }: { showSidebar?: () => void }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const activeLocale = useActiveLocale()
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const hideSidebar = () => {
    if (showSidebar) showSidebar()
  }
  return (
    <>
      <Drawer
        sx={{
          width: 180,
          flexShrink: 0,
          height: '100%',
          '& .MuiDrawer-paper': {
            width: 180,
            boxSizing: 'border-box',
            position: 'relative',
            border: 0,
            zIndex: 1,
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar sx={{ display: { xs: 'block', sm: 'none' }, height: '20px', minHeight: '20px' }} />
        <List sx={{ padding: 0 }}>
          <SideBarItem to="/overview" onClick={hideSidebar}>
            <ListItem button key="overview">
              <ListItemIcon
                sx={{
                  minWidth: 30,
                }}
              >
                <VisibilityIcon />
              </ListItemIcon>
              <ListItemText primary={t`Overview`} />
            </ListItem>
          </SideBarItem>
          <SideBarItem
            to="/accounts"
            onClick={hideSidebar}
            isActive={(match, { pathname }) =>
              Boolean(match) || pathname.startsWith('/accounts') || pathname.startsWith('/accounts/add')
            }
          >
            <ListItem button key="accounts">
              <ListItemIcon
                sx={{
                  minWidth: 30,
                }}
              >
                <AccountBalanceWalletIcon />
              </ListItemIcon>
              <ListItemText primary={t`Manage Wallets`} />
            </ListItem>
          </SideBarItem>
          <SideBarItem to="/history" onClick={hideSidebar}>
            <ListItem button key="overview">
              <ListItemIcon
                sx={{
                  minWidth: 30,
                }}
              >
                <HistoryIcon />
              </ListItemIcon>
              <ListItemText primary={t`History`} />
            </ListItem>
          </SideBarItem>
          <SideBarItem
            to="/position"
            onClick={hideSidebar}
            isActive={(match, { pathname }) =>
              Boolean(match) ||
              pathname.startsWith('/add') ||
              pathname.startsWith('/remove') ||
              pathname.startsWith('/increase') ||
              pathname.startsWith('/find')
            }
          >
            <ListItem button key="pool">
              <ListItemIcon
                sx={{
                  minWidth: 30,
                }}
              >
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={t`Uniswap V3`} />
            </ListItem>
          </SideBarItem>
          <SideBarItem to="/swap" onClick={hideSidebar}>
            <ListItem button key="swap">
              <ListItemIcon
                sx={{
                  minWidth: 30,
                }}
              >
                <SwapHorizIcon />
              </ListItemIcon>
              <ListItemText primary={t`Swap`} />
            </ListItem>
          </SideBarItem>
          {/*<SideBarItem to="/settings">
            <ListItem button key="settings">
              <ListItemIcon
                sx={{
                  minWidth: 30,
                }}
              >
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="设置" />
            </ListItem>
          </SideBarItem>*/}
        </List>
      </Drawer>
      <Version>Version: v2.0.0_Alpha</Version>
      <Medias>
        <MediaLink href="https://twitter.com/uniocean_org">
          <TwitterIcon />
        </MediaLink>
        <MediaLink href="https://t.me/uniocean_org">
          <TelegramIcon />
        </MediaLink>
        <MediaLink href="https://discord.gg/KvWsHbJjQa">
          <svg
            className="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2226"
            width="24"
            height="24"
          >
            <path
              d="M606.4 428.8c-28.8 0-52.8 25.6-52.8 57.6s24 57.6 52.8 57.6c28.8 0 52.8-25.6 52.8-57.6s-24-57.6-52.8-57.6zM419.2 428.8c-28.8 0-52.8 25.6-52.8 57.6s24 57.6 52.8 57.6c28.8 0 52.8-25.6 52.8-57.6s-24-57.6-52.8-57.6z"
              p-id="2227"
              fill="#1c1f26"
            ></path>
            <path
              d="M854.4 0H169.6C110.4 0 64 46.4 64 105.6v692.8c0 57.6 46.4 105.6 105.6 105.6h580.8l-27.2-94.4 65.6 60.8 62.4 57.6 108.8 96V105.6C960 46.4 913.6 0 854.4 0zM657.6 668.8s-19.2-22.4-33.6-41.6c67.2-19.2 92.8-60.8 92.8-60.8-20.8 14.4-41.6 24-59.2 30.4-25.6 11.2-49.6 17.6-73.6 22.4-49.6 9.6-94.4 6.4-132.8 0-28.8-6.4-54.4-14.4-75.2-22.4-11.2-4.8-24-9.6-36.8-17.6-1.6-1.6-3.2-1.6-4.8-3.2-1.6 0-1.6-1.6-1.6-1.6-9.6-4.8-14.4-8-14.4-8s24 41.6 89.6 60.8c-16 19.2-33.6 43.2-33.6 43.2-113.6-3.2-156.8-78.4-156.8-78.4 0-164.8 73.6-299.2 73.6-299.2 73.6-56 144-54.4 144-54.4l4.8 6.4C347.2 272 304 312 304 312s11.2-6.4 30.4-14.4c54.4-24 97.6-30.4 116.8-32 3.2 0 6.4-1.6 8-1.6 32-4.8 67.2-4.8 104-1.6 48 6.4 100.8 19.2 153.6 49.6 0 0-40-38.4-128-65.6l6.4-8s70.4-1.6 144 54.4c0 0 73.6 134.4 73.6 299.2 1.6-1.6-41.6 73.6-155.2 76.8z"
              p-id="2228"
              fill="#1c1f26"
            ></path>
          </svg>
        </MediaLink>
      </Medias>
      <Language>
        <Button
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant="outlined"
          endIcon={open ? <KeyboardArrowDownIcon sx={{ transform: `rotate(180deg)` }} /> : <KeyboardArrowDownIcon />}
        >
          {LOCALE_LABEL[activeLocale]}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {LangOptions.map((locale) => {
            return (
              <LanguageMenuItem locale={locale} key={locale} click={handleClose} active={activeLocale === locale} />
            )
          })}
        </Menu>
      </Language>
    </>
  )
}
const LanguageLink = styled(Link)`
  text-decoration: none;
`
const LanguageLinkMenuItem = styled(MenuItem)`
  font-size: 14px;
`
function LanguageMenuItem({
  locale,
  key,
  click,
  active,
}: {
  locale: SupportedLocale
  active: boolean
  key: string
  click: () => void
}) {
  const { to } = useLocationLinkProps(locale)
  if (!to) return null
  return (
    <LanguageLink to={to} key={key} onClick={click}>
      <LanguageLinkMenuItem>
        {LOCALE_LABEL[locale]} {active && <CheckIcon />}
      </LanguageLinkMenuItem>
    </LanguageLink>
  )
}
