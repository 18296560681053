import { TickMath } from '@uniswap/v3-sdk'
import { BigNumber } from 'bignumber.js'

export function getSqrtPriceByTick(tick: number): BigNumber {
  const X96 = new BigNumber(2).pow(96)
  return new BigNumber(TickMath.getSqrtRatioAtTick(tick).toString()).dividedBy(X96)
}

export function getPriceByTick(tick: number): BigNumber {
  return getSqrtPriceByTick(tick).pow(2)
}

// 获取忽略精度的价格，比如Token0
export function getHumanReadablePrice0ByTick(tick: number, decimals0: number, decimals1: number) {
  const precision = new BigNumber(10).pow(decimals0 - decimals1)
  return getPriceByTick(tick).multipliedBy(precision)
}

export function getHumanReadablePrice1ByTick(tick: number, decimals0: number, decimals1: number) {
  const precision = new BigNumber(10).pow(decimals1 - decimals0)
  return new BigNumber(1).dividedBy(getPriceByTick(tick)).multipliedBy(precision)
}

export function getNumberPrecisionAndStepBySignificantDigits(num: number, significantDigits = 5) {
  const numExponential = new BigNumber(num).toExponential(significantDigits - 1)
  const arr = numExponential.split(/e/)
  const step = new BigNumber(10).pow(Number(arr[1]) + 1 - significantDigits)
  const stepArr = step.toExponential().split('e')
  return {
    precision: stepArr.length === 2 ? (Number(stepArr[1]) < 0 ? -Number(stepArr[1]) : 0) : 0,
    step: step.toNumber(),
  }
}

export function NumbertoSignificantDigits(num: number | undefined, significantDigits = 5): string | undefined {
  if (!num) {
    return undefined
  }
  const { precision } = getNumberPrecisionAndStepBySignificantDigits(num, significantDigits)
  return num.toFixed(precision)
}
