// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import Loader from 'components/Loader'
import QuestionHelper from 'components/QuestionHelper'
import { MouseoverTooltipContent } from 'components/Tooltip'
import { BurnSchema, MintSchema, TxActionType } from 'data/GRTResultSchema'
import { memo, useMemo } from 'react'
import { dollarFormat, percentFormat } from 'utils/dollarFormat'
import { combineMintsAndBurns } from 'utils/fixData'

import { RowFixed } from '../Row'

interface IProps {
  price0: string | undefined
  price1: string | undefined
  fiatValueOfLiquidity: string | undefined
  mints: MintSchema[] | undefined
  burns: BurnSchema[] | undefined
}

interface ITipProps {
  symbol0: string | undefined
  symbol1: string | undefined
  amount0: number
  amount1: number
  holdValue: number
  poolValue: number
  lossValue: number
}
function ImpermanentLossTip(props: ITipProps) {
  const { symbol0, symbol1, amount0, amount1, holdValue, poolValue, lossValue } = props
  return (
    <div style={{ width: '450px' }}>
      <span>
        {symbol0 ? `${symbol0} ${t`amount:`} ${amount0}` : ''}
        <br />
        {symbol1 ? `${symbol1} ${t`amount:`} ${amount1}` : ''}
        <br />
        <Trans>Hold value: {dollarFormat(holdValue)}</Trans>
        <br />
        <Trans>Position value: {dollarFormat(poolValue)}</Trans>
        <br />
        <Trans>Loss value: {dollarFormat(lossValue)}</Trans>
        <br />
      </span>
    </div>
  )
}
function PositionImpermanentLoss(props: IProps) {
  const { price0, price1, fiatValueOfLiquidity, mints, burns } = props

  // 计算Impermanent Loss
  const IL = useMemo(() => {
    if (!price0 || !price1 || !fiatValueOfLiquidity) return undefined
    const txs = combineMintsAndBurns(mints, burns, 'asc')
    if (!txs) return undefined
    const currentValue = Number(fiatValueOfLiquidity)
    if (txs.length === 0)
      return {
        symbol0: undefined,
        symbol1: undefined,
        amount0: NaN,
        amount1: NaN,
        holdValue: NaN,
        poolValue: currentValue,
        lossValue: NaN,
        ratio: '-',
      }
    const amount = txs.reduce(
      (t, v) => {
        if (v.type === TxActionType.INCREASE) {
          t.amount0 = t.amount0.plus(v.amount0)
          t.amount1 = t.amount1.plus(v.amount1)
        } else if (v.type === TxActionType.DECREASE) {
          t.amount0 = t.amount0.minus(v.amount0)
          t.amount1 = t.amount1.minus(v.amount1)
        }
        return t
      },
      { amount0: new BigNumber(0), amount1: new BigNumber(0) }
    )
    const p0 = Number(price0)
    const p1 = Number(price1)
    const amount0 = amount.amount0.toNumber()
    const amount1 = amount.amount1.toNumber()
    const holdValue = p0 * amount0 + p1 * amount1
    return {
      symbol0: txs[0].pool.token0.symbol,
      symbol1: txs[0].pool.token1.symbol,
      amount0,
      amount1,
      holdValue,
      poolValue: currentValue,
      lossValue: holdValue - currentValue,
      ratio: percentFormat((holdValue - currentValue) / holdValue, 2, '-'),
    }
  }, [price0, price1, mints, burns, fiatValueOfLiquidity])

  return (
    <div style={{ marginLeft: '16px' }}>
      <RowFixed>
        <Trans>Impermanent loss</Trans>
        <QuestionHelper
          text={t`Impermanent loss refers to a temporary loss caused to a liquidity provider due to the volatility in a trading pair`}
        />
        :{' '}
        {typeof IL !== 'undefined' ? (
          IL ? (
            <MouseoverTooltipContent
              content={
                <ImpermanentLossTip
                  symbol0={IL.symbol0}
                  symbol1={IL.symbol1}
                  amount0={IL.amount0}
                  amount1={IL.amount1}
                  holdValue={IL.holdValue}
                  poolValue={IL.poolValue}
                  lossValue={IL.lossValue}
                />
              }
            >
              {IL.ratio + '%'}
            </MouseoverTooltipContent>
          ) : (
            '-'
          )
        ) : (
          <Loader />
        )}
      </RowFixed>
    </div>
  )
}

export default memo(PositionImpermanentLoss)
