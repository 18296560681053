import { Trans } from '@lingui/macro'
import { Collapse, Grid, IconButton } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import DoDisturbOnOutlinedIcon from '@material-ui/icons/DoDisturbOnOutlined'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'
import { Percent } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { IV3Transaction } from 'hooks/useHistoryData'
import React, { useState } from 'react'
import styled from 'styled-components/macro'

import { useToken } from '../../hooks/Tokens'
import { BadgeText } from '../../pages/Position/PositionPage'
import { ExternalLink, ThemedText } from '../../theme'
import { shortenTxid } from '../../utils'
import { getFormattedTimeString } from '../../utils/calculateTime'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import { unwrappedToken } from '../../utils/unwrappedToken'
import Badge from '../Badge'
import CurrencyLogo from '../CurrencyLogo'
import { RowFixed } from '../Row'
import { MouseoverTooltip } from '../Tooltip'

interface IProps {
  transaction: IV3Transaction
}

export function V3TransactionIncreaseItem(props: IProps) {
  const { transaction } = props
  const [openItem, setOpenItem] = useState(false)
  const changeOpen = () => {
    setOpenItem(!openItem)
  }
  const token0 = useToken(transaction.pool.token0.id)
  const token1 = useToken(transaction.pool.token1.id)
  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined
  const { chainId } = useWeb3React()
  return (
    <HistoryItem isOpen={openItem}>
      <Collapse in={openItem} collapsedSize={74}>
        <Grid container style={{ margin: 0, width: '100%' }} alignItems="center">
          <GridItem item container xs={3} alignItems="center">
            <GridItem item xs={2} style={{ textAlign: 'center', borderRight: '1px dashed #dddddd' }}>
              <TypeIcon type={transaction.type} />
            </GridItem>
            <GridItem item xs={10} style={{ fontSize: '14px' }}>
              <Trans>Increase liquidity</Trans>
              <br />
              {transaction.pool.token0.symbol}/{transaction.pool.token1.symbol}{' '}
              <Badge>
                <BadgeText>
                  <Trans>{new Percent(transaction.pool.feeTier, 1_000_000).toSignificant()}%</Trans>
                </BadgeText>
              </Badge>
              <br />
              {getFormattedTimeString(Number(transaction.timestamp), 'LT')}
            </GridItem>
          </GridItem>
          <GridItem item xs={3} style={{ maxWidth: '25%', flexBasis: '25%' }}>
            <RowFixed style={{ marginBottom: '4px' }}>
              <CurrencyLogo currency={currency0} size={'20px'} style={{ marginRight: '0.5rem' }} />
              <ThemedText.Main>{transaction.pool.token0.symbol}</ThemedText.Main>
              <ThemedText.SubHeader style={{ marginLeft: '12px' }}>
                -
                {Number(transaction.amount0).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                })}
              </ThemedText.SubHeader>
            </RowFixed>
            <RowFixed>
              <CurrencyLogo currency={currency1} size={'20px'} style={{ marginRight: '0.5rem' }} />
              <ThemedText.Main>{transaction.pool.token1.symbol}</ThemedText.Main>
              <ThemedText.SubHeader style={{ marginLeft: '12px' }}>
                -
                {Number(transaction.amount1).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                })}
              </ThemedText.SubHeader>
            </RowFixed>
          </GridItem>
          <GridItem item container xs={1} style={{ maxWidth: '5%', flexBasis: '5%' }} alignItems="center">
            <GridItem item xs={12} style={{ textAlign: 'center' }}>
              <KeyboardArrowRightIcon />
            </GridItem>
          </GridItem>
          <GridItem item xs={4} style={{ maxWidth: '26%', flexBasis: '26%' }}>
            <RowFixed style={{ marginBottom: '4px' }}>
              <ThemedText.Main>
                +{new BigNumber(transaction.amount!).dividedBy(1e18).precision(5).toString()} ({transaction.tokenId})
              </ThemedText.Main>
            </RowFixed>
            <RowFixed>
              <ThemedText.SubHeader>
                $
                {Number(new BigNumber(transaction.amountUSD).toFixed(2)).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                })}
              </ThemedText.SubHeader>
            </RowFixed>
          </GridItem>
          <GridItem item xs={1} style={{ maxWidth: '14%', flexBasis: '14%' }}>
            {' '}
          </GridItem>
          <GridItem item xs={1} style={{ maxWidth: '5%', flexBasis: '5%' }}>
            <IconButton aria-label="show" onClick={changeOpen} style={{ float: 'right' }}>
              {openItem ? <KeyboardArrowDownIcon sx={{ transform: `rotate(180deg)` }} /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </GridItem>
        </Grid>
        <ItemMore>
          <span className="title">
            <Trans>Gas:</Trans>
          </span>
          <span className="data">
            {Number(transaction.transaction.gasUsed) !== 0
              ? `${new BigNumber(transaction.transaction.gasUsed)
                  .times(new BigNumber(transaction.transaction.gasPrice))
                  .dividedBy(1e18)
                  .toPrecision(3, 0)}${' '}
                          ETH`
              : '-'}
          </span>
          {chainId && transaction.transaction.id ? (
            <>
              <span className="title">
                <Trans>Tx Hash:</Trans>
              </span>
              <span className="data">
                <MouseoverTooltip text={transaction.transaction.id}>
                  <ExternalLink
                    href={getExplorerLink(chainId, transaction.transaction.id, ExplorerDataType.TRANSACTION)}
                  >
                    <span style={{ marginLeft: '4px' }}>{shortenTxid(transaction.transaction.id, 6)}</span>
                  </ExternalLink>
                </MouseoverTooltip>
              </span>
            </>
          ) : null}
        </ItemMore>
        {/*类型: <Trans>Increase</Trans> - 时间:{transaction.timestamp} - {transaction.pool.token0.symbol}/*/}
        {/*{transaction.pool.token1.symbol} <br />*/}
        {/*amount0:-{transaction.amount0} <br />*/}
        {/*amount1:-{transaction.amount1} {'>'} +*/}
        {/*{new BigNumber(transaction.amount!).dividedBy(1e18).precision(5).toString()} V3LP(*/}
        {/*{transaction.tokenId}) amountUSD:{transaction.amountUSD} <br />*/}
        {/*Tx Hash: {transaction.transaction.id}*/}
      </Collapse>
    </HistoryItem>
  )
}

export function V3TransactionCollectItem(props: IProps) {
  const { transaction } = props
  const [openItem, setOpenItem] = useState(false)
  const changeOpen = () => {
    setOpenItem(!openItem)
  }
  const token0 = useToken(transaction.pool.token0.id)
  const token1 = useToken(transaction.pool.token1.id)
  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined
  const { chainId } = useWeb3React()
  return (
    <HistoryItem isOpen={openItem}>
      <Collapse in={openItem} collapsedSize={74}>
        <Grid container style={{ margin: 0, width: '100%' }} alignItems="center">
          <GridItem item container xs={3} alignItems="center">
            <GridItem item xs={2} style={{ textAlign: 'center', borderRight: '1px dashed #dddddd' }}>
              <TypeIcon type={transaction.type} />
            </GridItem>
            <GridItem item xs={10} style={{ fontSize: '14px' }}>
              <Trans>Collect</Trans>
              <br />
              {transaction.pool.token0.symbol}/{transaction.pool.token1.symbol}{' '}
              <Badge>
                <BadgeText>
                  <Trans>{new Percent(transaction.pool.feeTier, 1_000_000).toSignificant()}%</Trans>
                </BadgeText>
              </Badge>
              <br />
              {getFormattedTimeString(Number(transaction.timestamp), 'LT')}
            </GridItem>
          </GridItem>
          <GridItem item xs={3} style={{ maxWidth: '25%', flexBasis: '25%' }}>
            <RowFixed style={{ marginBottom: '4px' }}>
              {' '}
              <br />
            </RowFixed>
            <RowFixed style={{ marginBottom: '4px' }}>
              <ThemedText.SubHeader>
                -{new BigNumber(transaction.amount!).dividedBy(1e18).precision(5).toString()} Liq ({transaction.tokenId}
                )
              </ThemedText.SubHeader>
            </RowFixed>
            <RowFixed>
              <ThemedText.SubHeader>
                $
                {Number(new BigNumber(transaction.amountUSD).toFixed(2)).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                })}
              </ThemedText.SubHeader>
            </RowFixed>
          </GridItem>
          <GridItem item container xs={1} style={{ maxWidth: '5%', flexBasis: '5%' }} alignItems="center">
            <GridItem item xs={12} style={{ textAlign: 'center' }}>
              <KeyboardArrowRightIcon />
            </GridItem>
          </GridItem>
          <GridItem item xs={4} style={{ maxWidth: '26%', flexBasis: '26%' }}>
            <RowFixed style={{ marginBottom: '4px' }}>
              {' '}
              <br />
            </RowFixed>
            <RowFixed style={{ marginBottom: '4px' }}>
              <CurrencyLogo currency={currency0} size={'20px'} style={{ marginRight: '0.5rem' }} />
              <ThemedText.Main>{transaction.pool.token0.symbol}</ThemedText.Main>
              <ThemedText.SubHeader style={{ marginLeft: '12px' }}>
                +
                {Number(transaction.amount0).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                })}
              </ThemedText.SubHeader>
            </RowFixed>
            <RowFixed>
              <CurrencyLogo currency={currency1} size={'20px'} style={{ marginRight: '0.5rem' }} />
              <ThemedText.Main>{transaction.pool.token1.symbol}</ThemedText.Main>
              <ThemedText.SubHeader style={{ marginLeft: '12px' }}>
                +
                {Number(transaction.amount1).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                })}
              </ThemedText.SubHeader>
            </RowFixed>
          </GridItem>
          <GridItem item xs={1} style={{ maxWidth: '14%', flexBasis: '14%' }}>
            <RowFixed style={{ marginBottom: '4px' }}>
              <ThemedText.SubHeader>
                <Trans>Fees</Trans>
              </ThemedText.SubHeader>
            </RowFixed>
            <RowFixed style={{ marginBottom: '4px' }}>
              <ThemedText.Main>
                +
                {Number(transaction.feeAmount0).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                })}
              </ThemedText.Main>
            </RowFixed>
            <RowFixed>
              <ThemedText.Main>
                +
                {Number(transaction.feeAmount1).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                })}
              </ThemedText.Main>
            </RowFixed>
          </GridItem>
          <GridItem item xs={1} style={{ maxWidth: '5%', flexBasis: '5%' }}>
            <IconButton aria-label="show" onClick={changeOpen} style={{ float: 'right' }}>
              {openItem ? <KeyboardArrowDownIcon sx={{ transform: `rotate(180deg)` }} /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </GridItem>
        </Grid>
        <ItemMore>
          <span className="title">
            <Trans>Gas:</Trans>
          </span>
          <span className="data">
            {Number(transaction.transaction.gasUsed) !== 0
              ? `${new BigNumber(transaction.transaction.gasUsed)
                  .times(new BigNumber(transaction.transaction.gasPrice))
                  .dividedBy(1e18)
                  .toPrecision(3, 0)}${' '}
                          ETH`
              : '-'}
          </span>
          {chainId && transaction.transaction.id ? (
            <>
              <span className="title">
                <Trans>Tx Hash:</Trans>
              </span>
              <span className="data">
                <MouseoverTooltip text={transaction.transaction.id}>
                  <ExternalLink
                    href={getExplorerLink(chainId, transaction.transaction.id, ExplorerDataType.TRANSACTION)}
                  >
                    <span style={{ marginLeft: '4px' }}>{shortenTxid(transaction.transaction.id, 6)}</span>
                  </ExternalLink>
                </MouseoverTooltip>
              </span>
            </>
          ) : null}
        </ItemMore>
        {/*类型: <Trans>Collect</Trans> - 时间:{transaction.timestamp} - {transaction.pool.token0.symbol}/
        {transaction.pool.token1.symbol} <br />-{new BigNumber(transaction.amount!).dividedBy(1e18).toString()} V3LP(
        {transaction.tokenId}) amountUSD:{transaction.amountUSD}
        {'>'} amount0:+{transaction.amount0}-amount1:+{transaction.amount1} <br />
        feeAmount0:+{transaction.feeAmount0}-feeAmount1:+{transaction.feeAmount1} <br />
        Tx Hash: {transaction.transaction.id}*/}
      </Collapse>
    </HistoryItem>
  )
}
interface TypeIconProp {
  type: string
}
function TypeIcon({ type }: TypeIconProp) {
  let IconDom
  switch (type) {
    case 'INCREASE':
      IconDom = <AddCircleOutlineIcon sx={{ fontSize: 30, color: '#00B464', marginLeft: '-12px' }} />
      break
    case 'DECREASE':
      IconDom = <DoDisturbOnOutlinedIcon sx={{ fontSize: 30, color: '#FE542B', marginLeft: '-12px' }} />
      break
    case 'CLAIM_FEE':
      IconDom = <MonetizationOnOutlinedIcon sx={{ fontSize: 30, color: '#203AC4', marginLeft: '-12px' }} />
      break
    case 'COLLECT':
      IconDom = <DoDisturbOnOutlinedIcon sx={{ fontSize: 30, color: '#FE542B', marginLeft: '-12px' }} />
      break
    default:
      IconDom = <AddCircleOutlineIcon sx={{ fontSize: 30, color: '#00B464', marginLeft: '-12px' }} />
      break
  }
  return IconDom
}

const HistoryItem = styled.div<{ isOpen: boolean }>`
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  padding: ${(props) => (props.isOpen ? '11px' : '12px')};
  border: ${(props) => (props.isOpen ? '1px solid #2962ef' : 'none')};
  border-bottom: ${(props) => (props.isOpen ? '' : '1px solid rgba(0, 0, 0, 0.12)')};
  border-radius: ${(props) => (props.isOpen ? '8px' : '0')};
  margin-top: 6px;
`

const GridItem = styled(Grid)`
  padding: 4px;
  p {
    margin: 0;
    font-size: 16px;
    &:first-child {
      margin-bottom: 4px;
    }
  }
`
const ItemMore = styled.div`
  width: 100%;
  padding: 20px 6px 12px;
  .title {
    font-size: 14px !important;
    margin-right: 8px;
  }
  .data {
    font-size: 16px;
    margin-right: 20px;
  }
`
