// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { Button } from '@material-ui/core'
import HistoryDataExportToCsv from 'components/History/HistoryDataExportToCsv'
import { V3TransactionCollectItem, V3TransactionIncreaseItem } from 'components/History/V3TransactionItem'
import Loader from 'components/Loader'
import { useAccountManager } from 'constants/accounts'
import { TxActionType } from 'data/GRTResultSchema'
import { IV3Transaction, useHistoryWithFixedData } from 'hooks/useHistoryData'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { getFormattedTimeString } from '../../utils/calculateTime'

export default function History() {
  const { selectedAccount } = useAccountManager()
  const first = 25
  const [page, setPage] = useState<number>(0) // 设置skip值
  const [isLastPage, setIsLastPage] = useState<boolean>(false) // 判断是否获取到了所有的数据
  const [historyData, setHistoryData] = useState<IV3Transaction[]>()
  const [formatHistory, setFormatHistory] = useState<Map<string, IV3Transaction[]>>(new Map<string, IV3Transaction[]>())
  const { isLoading, isError, error, isUninitialized, data } = useHistoryWithFixedData(
    selectedAccount,
    first,
    first * page
  )

  //   const l = new BigNumber(14286724302903623)
  //   const b = getSqrtPriceByTick(-195710)
  //   const a = getSqrtPriceByTick(-196600)
  //   const result = l.multipliedBy(b.minus(a)).dividedBy(1e6).toString()
  //   console.log('result:', result, a.toNumber(), b.toNumber())
  //   console.log('get historyData:', historyData)
  // 把多次获取的数据进行合并
  useEffect(() => {
    if (!data) return
    if (!historyData) {
      setHistoryData(data)
    } else {
      const len = historyData.length
      const last = historyData[len - 1]
      const sameData = data.find((x) => x.transaction.id?.toLowerCase() === last.transaction.id?.toLowerCase())
      if (!sameData) {
        const newData = [...historyData, ...data]
        setHistoryData(newData)
      }
    }
    checkIsLastPage(data)
  }, [data])

  function checkIsLastPage(data: IV3Transaction[]) {
    if (data.length < first) {
      setIsLastPage(true)
    }
  }
  useEffect(() => {
    if (historyData && historyData.length > 0) {
      let timeMark: string | null = null
      let newArr: IV3Transaction[] = []
      const historyMap = new Map<string, IV3Transaction[]>()
      historyData.forEach((item, index) => {
        const formatTime = getFormattedTimeString(Number(item.timestamp))
        if (timeMark) {
          if (formatTime === timeMark) {
            newArr.push(item)
          } else {
            historyMap.set(timeMark, newArr)
            newArr = [item]
            timeMark = formatTime
          }
        } else {
          newArr = [item]
          timeMark = formatTime
        }
        if (index === historyData.length - 1) {
          historyMap.set(timeMark, newArr)
        }
      })
      setFormatHistory(historyMap)
      console.log('historyMap', historyMap)
    }
  }, [historyData])

  const getMoreData = useCallback(() => {
    setPage(page + 1)
  }, [page])

  return (
    <HistoryBox>
      <h2 className="title">
        <Trans>History</Trans>
        <HistoryDataExportToCsv historyData={historyData} address={selectedAccount} />
      </h2>
      <br />
      {historyData
        ? [...formatHistory].map((item) => {
            return (
              <>
                <TimeTitle>{item[0]}</TimeTitle>
                {item[1].map((item_) => {
                  return item_.type === TxActionType.INCREASE ? (
                    <V3TransactionIncreaseItem key={item_.transaction.id} transaction={item_} />
                  ) : item_.type === TxActionType.COLLECT ? (
                    <V3TransactionCollectItem key={item_.transaction.id} transaction={item_} />
                  ) : (
                    ''
                  )
                })}
              </>
            )
          })
        : /*historyData.map((item) => {
            return item.type === TxActionType.INCREASE ? (
              <V3TransactionIncreaseItem key={item.transaction.id} transaction={item} />
            ) : item.type === TxActionType.COLLECT ? (
              <V3TransactionCollectItem key={item.transaction.id} transaction={item} />
            ) : (
              ''
            )
          })*/
        isLoading
        ? ''
        : t`No data`}
      {!isLoading ? (
        !isLastPage ? (
          <ShowMore>
            <CsvBtn style={{ width: '90px', margin: '0 auto' }} onClick={getMoreData}>
              <Trans>More</Trans>
            </CsvBtn>
          </ShowMore>
        ) : null
      ) : (
        <ShowMore>
          <Loader key={new Date().getTime().toString()} size={'30px'} />
        </ShowMore>
      )}
    </HistoryBox>
  )
}
const HistoryBox = styled.div`
  width: 940px;
  margin: 40px auto;
  .title {
    font-size: 32px;
  }
`
const ShowMore = styled.div`
  width: 100%;
  margin: 40px 0 0;
  text-align: center;
`
const CsvBtn = styled(Button)`
  font-size: 14px;
  padding: 6px 20px;
  border-radius: 16px;
  border: 1px solid #2962ef;
  color: #2962ef;
  text-decoration: none;
  font-weight: 500;
`
const TimeTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
`
