// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Pagination from '@material-ui/core/Pagination'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import DoDisturbOnOutlinedIcon from '@material-ui/icons/DoDisturbOnOutlined'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'
import { Token } from '@uniswap/sdk-core'
import BigNumber from 'bignumber.js'
import QuestionHelper from 'components/QuestionHelper'
import { BurnSchema, MintSchema, TxActionType } from 'data/GRTResultSchema'
import { TxSchema } from 'data/position/positionData'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { combineMintsAndBurns } from 'utils/fixData'

import { ExternalLink, ThemedText } from '../../theme'
import { shortenTxid } from '../../utils'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import { unwrappedToken } from '../../utils/unwrappedToken'
import CurrencyLogo from '../CurrencyLogo'
import { RowBetween, RowFixed } from '../Row'
import { MouseoverTooltip } from '../Tooltip'

interface IParam {
  chainId: number
  // position?: Position
  token0: Token | undefined
  token1: Token | undefined
  mints: MintSchema[]
  burns: BurnSchema[]
}

interface TypeIconProp {
  type: string
}

interface totalTxDataType {
  totalAmount0: string
  totalAmount1: string
  totalFee0: string
  totalFee1: string
  totalGas: string
}

function TypeIcon({ type }: TypeIconProp) {
  let IconDom
  switch (type) {
    case 'INCREASE':
      IconDom = <AddCircleOutlineIcon sx={{ fontSize: 30, color: '#00B464', marginLeft: '-12px' }} />
      break
    case 'DECREASE':
      IconDom = <DoDisturbOnOutlinedIcon sx={{ fontSize: 30, color: '#FE542B', marginLeft: '-12px' }} />
      break
    case 'CLAIM_FEE':
      IconDom = <MonetizationOnOutlinedIcon sx={{ fontSize: 30, color: '#203AC4', marginLeft: '-12px' }} />
      break
    default:
      IconDom = <AddCircleOutlineIcon sx={{ fontSize: 30, color: '#00B464', marginLeft: '-12px' }} />
      break
  }
  return IconDom
}

function PositionTxDetails(props: IParam) {
  const { chainId, token0, token1, mints, burns } = props

  const txs = useMemo(() => {
    // const txs = [...mints, ...burns]
    // // 把mints和burns整合到一个新的数组，并按照时间降序排序
    // txs.sort((a, b) => {
    //   return Number(b.timestamp) - Number(a.timestamp)
    // })
    const txs = combineMintsAndBurns(mints, burns, 'desc')
    if (!txs) return []

    const txs_reverse = [...txs].reverse()

    const liquidity_cost = txs_reverse.reduce(
      (t, v) => {
        if (v.type === TxActionType.INCREASE) {
          t.amount = new BigNumber(t.amount).plus(new BigNumber(v.amount)).toString()
          t.amount0 = new BigNumber(t.amount0).plus(new BigNumber(v.amount0)).toString()
          t.amount1 = new BigNumber(t.amount1).plus(new BigNumber(v.amount1)).toString()
          t.amountUSD = new BigNumber(t.amountUSD).plus(new BigNumber(v.amountUSD)).toString()
        } else if (v.type === TxActionType.DECREASE || v.type === TxActionType.CLAIM_FEE) {
          t.amount = new BigNumber(t.amount).minus(new BigNumber(v.amount)).toString()
          t.amount0 = new BigNumber(t.amount0).minus(new BigNumber(v.amount0)).toString()
          t.amount1 = new BigNumber(t.amount1).minus(new BigNumber(v.amount1)).toString()
          t.amountUSD = new BigNumber(t.amountUSD).minus(new BigNumber(v.amountUSD)).toString()
        }
        return t
      },
      {
        amount: '0',
        amount0: '0',
        amount1: '0',
        amountUSD: '0',
        unitLiquidity: '0',
      }
    )
    liquidity_cost.unitLiquidity = new BigNumber(liquidity_cost.amountUSD).dividedBy(liquidity_cost.amount).toString()
    return txs
  }, [mints, burns])

  // const token0 = position.pool.token0 //useToken(txs[0].pool.token0.id)
  // const token1 = position.pool.token1 //useToken(txs[0].pool.token1.id)
  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined
  // const symbol0 = position.pool.token0.symbol // txs[0].pool.token0.symbol
  // const symbol1 = position.pool.token1.symbol //txs[0].pool.token1.symbol
  const symbol0 = token0?.symbol
  const symbol1 = token1?.symbol

  const TxActionTypeForUI = {
    INCREASE: t`Increase liquidity`,
    DECREASE: t`Decrease liquidity`,
    CLAIM_FEE: t`Claimed fees`,
    COLLECT: t`COLLECT`,
  }

  const TxListBox = styled.div`
    width: 100%;
    margin-top: 20px;
  `
  const ListItem = styled(Paper)`
    width: 100%;
    margin-bottom: 10px;
    padding: 12px;
  `
  const GridItem = styled(Grid)`
    padding: 4px;
    p {
      margin: 0;
      font-size: 16px;
      &:first-child {
        margin-bottom: 4px;
      }
    }
  `

  const EmptyList = styled.p`
    font-size: 16px;
  `
  const TotalTxDataBox = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
  `
  const TotalTxDataItem = styled.div`
    margin: 0;
  `

  const [value, setValue] = useState(0)
  const [page, setPage] = useState(1)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    setPage(1)
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const calcTotalTxData = (arr: TxSchema[]) => {
    return arr.reduce(
      (t: totalTxDataType, v) => {
        switch (v.type) {
          case 'INCREASE':
            t.totalAmount0 = new BigNumber(t.totalAmount0).plus(new BigNumber(v.amount0)).toFixed()
            t.totalAmount1 = new BigNumber(t.totalAmount1).plus(new BigNumber(v.amount1)).toFixed()
            break
          case 'DECREASE':
            t.totalAmount0 = new BigNumber(t.totalAmount0).minus(new BigNumber(v.amount0)).toFixed()
            t.totalAmount1 = new BigNumber(t.totalAmount1).minus(new BigNumber(v.amount1)).toFixed()
            break
          case 'CLAIM_FEE':
            t.totalFee0 = new BigNumber(t.totalFee0).plus(new BigNumber(v.amount0)).toFixed(4)
            t.totalFee1 = new BigNumber(t.totalFee1).plus(new BigNumber(v.amount1)).toFixed(4)
            break
        }
        t.totalGas = new BigNumber(t.totalGas)
          .plus(new BigNumber(v.transaction.gasUsed).times(new BigNumber(v.transaction.gasPrice)).dividedBy(1e18))
          .toFixed()
        return t
      },
      { totalAmount0: '0', totalAmount1: '0', totalFee0: '0', totalFee1: '0', totalGas: '0' }
    )
  }

  // const [txData, setTxData] = useState<TxSchema[]>([])
  // const [totalTxData, setTotalTxData] = useState<totalTxDataType>({
  //   totalAmount0: '0',
  //   totalAmount1: '0',
  //   totalFee0: '0',
  //   totalFee1: '0',
  //   totalGas: '0',
  // })
  // useEffect(() => {
  //   let newTxs
  //   switch (value) {
  //     case 0:
  //       newTxs = txs
  //       setTotalTxData(calcTotalTxData(newTxs))
  //       setTxData(newTxs)
  //       break
  //     case 1:
  //       newTxs = txs.filter((t) => t.type === 'INCREASE')
  //       setTotalTxData(calcTotalTxData(newTxs))
  //       setTxData(newTxs)
  //       break
  //     case 2:
  //       newTxs = txs.filter((t) => t.type === 'DECREASE')
  //       setTotalTxData(calcTotalTxData(newTxs))
  //       setTxData(newTxs)
  //       break
  //     case 3:
  //       newTxs = txs.filter((t) => t.type === 'CLAIM_FEE')
  //       setTotalTxData(calcTotalTxData(newTxs))
  //       setTxData(newTxs)
  //       break
  //   }
  // }, [value, txs])

  const pageStep = 10

  // Start 重构设置全部、加仓、减仓、收割手续费的数据
  const [allSummary, setAllSummary] = useState<totalTxDataType>()
  const [increaseSummary, setIncreaseSummary] = useState<totalTxDataType>()
  const [decreaseSummary, setDecreaseSummary] = useState<totalTxDataType>()
  const [claimFeeSummary, setClaimFeeSummary] = useState<totalTxDataType>()
  const [allTxData, setAllTxData] = useState<TxSchema[]>([])
  const [increaseTxData, setIncreaseTxData] = useState<TxSchema[]>([])
  const [decreaseTxData, setDecreaseTxData] = useState<TxSchema[]>([])
  const [claimFeeTxData, setClaimFeeTxData] = useState<TxSchema[]>([])
  useEffect(() => {
    const all = [...txs]
    setAllSummary(calcTotalTxData(all))
    setAllTxData(all)

    const increase = txs.filter((t) => t.type === 'INCREASE')
    setIncreaseSummary(calcTotalTxData(increase))
    setIncreaseTxData(increase)

    const decrease = txs.filter((t) => t.type === 'DECREASE')
    setDecreaseSummary(calcTotalTxData(decrease))
    setDecreaseTxData(decrease)

    const claimFee = txs.filter((t) => t.type === 'CLAIM_FEE')
    setClaimFeeSummary(calcTotalTxData(claimFee))
    setClaimFeeTxData(claimFee)
  }, [txs])

  const { txData, totalTxData } = useMemo(() => {
    switch (value) {
      case 0:
        return { txData: allTxData, totalTxData: allSummary }
      case 1:
        return { txData: increaseTxData, totalTxData: increaseSummary }
      case 2:
        return { txData: decreaseTxData, totalTxData: decreaseSummary }
      case 3:
        return { txData: claimFeeTxData, totalTxData: claimFeeSummary }
      default:
        return { txData: allTxData, totalTxData: allSummary }
    }
  }, [value, allTxData, increaseTxData, decreaseTxData, claimFeeTxData])
  // End 重构设置全部、加仓、减仓、收割手续费的数据

  return (
    <TxListBox>
      <Tabs value={value} onChange={handleChange}>
        <Tab label={t`All(${allTxData.length})`} />
        <Tab label={t`Increase liquidity(${increaseTxData.length})`} />
        <Tab label={t`Decrease liquidity(${decreaseTxData.length})`} />
        <Tab label={t`Claimed fees(${claimFeeTxData.length})`} />
      </Tabs>
      <TotalTxDataBox>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={4}
          style={{ marginTop: 0, marginLeft: 0 }}
        >
          {value !== 3 ? (
            <Grid container item xs={4} style={{ padding: '4px 8px' }}>
              <Grid item xs={3}>
                <span style={{ fontSize: '14px' }}>
                  <Trans>Amount:</Trans>
                </span>
              </Grid>
              <Grid item xs={9}>
                <TotalTxDataItem style={{ marginBottom: '4px' }}>
                  <RowFixed>
                    <CurrencyLogo currency={currency0} size={'20px'} style={{ marginRight: '0.5rem' }} />
                    <ThemedText.Main>{symbol0}</ThemedText.Main>
                    <ThemedText.SubHeader style={{ marginLeft: '20px' }}>
                      {Number(totalTxData?.totalAmount0).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 4,
                      })}
                    </ThemedText.SubHeader>
                  </RowFixed>
                </TotalTxDataItem>
                <TotalTxDataItem>
                  <RowFixed>
                    <CurrencyLogo currency={currency1} size={'20px'} style={{ marginRight: '0.5rem' }} />
                    <ThemedText.Main>{symbol1}</ThemedText.Main>
                    <ThemedText.SubHeader style={{ marginLeft: '20px' }}>
                      {Number(totalTxData?.totalAmount1).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 4,
                      })}
                    </ThemedText.SubHeader>
                  </RowFixed>
                </TotalTxDataItem>
              </Grid>
            </Grid>
          ) : null}
          {value === 0 || value === 3 ? (
            <Grid container item xs={5} style={{ padding: '4px 8px' }}>
              <Grid item xs={4}>
                <span style={{ fontSize: '14px' }}>
                  <Trans>Claimed fees:</Trans>
                </span>
              </Grid>
              <Grid item xs={8}>
                <TotalTxDataItem style={{ marginBottom: '4px' }}>
                  <RowFixed>
                    <CurrencyLogo currency={currency0} size={'20px'} style={{ marginRight: '0.5rem' }} />
                    <ThemedText.Main>{symbol0}</ThemedText.Main>
                    <ThemedText.SubHeader style={{ marginLeft: '20px' }}>
                      {Number(totalTxData?.totalFee0).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 4,
                      })}
                    </ThemedText.SubHeader>
                  </RowFixed>
                </TotalTxDataItem>
                <TotalTxDataItem>
                  <RowFixed>
                    <CurrencyLogo currency={currency1} size={'20px'} style={{ marginRight: '0.5rem' }} />
                    <ThemedText.Main>{symbol1}</ThemedText.Main>
                    <ThemedText.SubHeader style={{ marginLeft: '20px' }}>
                      {Number(totalTxData?.totalFee1).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 4,
                      })}
                    </ThemedText.SubHeader>
                  </RowFixed>
                </TotalTxDataItem>
              </Grid>
            </Grid>
          ) : null}
          <Grid container item xs={3} style={{ padding: '4px 8px' }} justifyContent="flex-end">
            <RowBetween style={{ justifyContent: 'flex-end', width: 'auto' }}>
              <RowFixed style={{ paddingRight: '12px' }}>
                <span style={{ fontSize: '14px' }}>
                  <Trans>Gas</Trans>
                </span>
                <QuestionHelper
                  text={t`When the gas fee is'-', it usually means that multiple operations have been performed in one transaction (such as: decrease and claim fee at the same time). In order to avoid double calculation of the gas fee, the system only reserves the gas fee for one operation`}
                />
                :{' '}
                {totalTxData ? (
                  <TotalTxDataItem>{new BigNumber(totalTxData.totalGas).toFixed(4)} ETH</TotalTxDataItem>
                ) : (
                  ''
                )}
              </RowFixed>
            </RowBetween>
          </Grid>
        </Grid>
      </TotalTxDataBox>
      <Divider sx={{ marginBottom: '12px' }} />
      {txData && txData.length > 0 ? (
        txData.slice((page - 1) * pageStep, page * pageStep).map((item, index) => {
          return (
            <ListItem key={index + item.timestamp}>
              <Grid container style={{ margin: 0, width: '100%' }}>
                <GridItem item container xs={3} alignItems="center" style={{ maxWidth: '22%', flexBasis: '22%' }}>
                  <GridItem item xs={2} style={{ textAlign: 'center', borderRight: '1px dashed #dddddd' }}>
                    <TypeIcon type={item.type} />
                  </GridItem>
                  <GridItem item xs={10} style={{ fontSize: '14px' }}>
                    {TxActionTypeForUI[item.type]}
                    <br />
                    {new Date(Number(item.timestamp) * 1000).toLocaleString()}
                  </GridItem>
                </GridItem>
                <GridItem item xs={3} style={{ maxWidth: '24%', flexBasis: '24%' }}>
                  {/* <p> */}
                  <RowFixed style={{ marginBottom: '4px' }}>
                    <CurrencyLogo currency={currency0} size={'20px'} style={{ marginRight: '0.5rem' }} />
                    <ThemedText.Main>{item.pool.token0.symbol}</ThemedText.Main>
                    <ThemedText.SubHeader style={{ marginLeft: '20px' }}>
                      {Number(item.amount0).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 4,
                      })}
                    </ThemedText.SubHeader>
                  </RowFixed>
                  {/* </p>
                  <p> */}
                  <RowFixed>
                    <CurrencyLogo currency={currency1} size={'20px'} style={{ marginRight: '0.5rem' }} />
                    <ThemedText.Main>{item.pool.token1.symbol}</ThemedText.Main>
                    <ThemedText.SubHeader style={{ marginLeft: '20px' }}>
                      {Number(item.amount1).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 4,
                      })}
                    </ThemedText.SubHeader>
                  </RowFixed>
                  {/* </p> */}
                </GridItem>
                <GridItem item xs={3} style={{ maxWidth: '26%', flexBasis: '26%' }}>
                  <p>
                    <Trans>Value:</Trans> ${' '}
                    {Number(item.amountUSD) > 1 / 100
                      ? Number(item.amountUSD).toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })
                      : '-'}
                  </p>
                </GridItem>
                <GridItem
                  item
                  xs={3}
                  style={{ textAlign: 'right', fontSize: '16px', maxWidth: '28%', flexBasis: '28%' }}
                >
                  {/*<p>*/}
                  <Trans>Gas:</Trans>{' '}
                  {Number(item.transaction.gasUsed) !== 0
                    ? `${new BigNumber(item.transaction.gasUsed)
                        .times(new BigNumber(item.transaction.gasPrice))
                        .dividedBy(1e18)
                        .toPrecision(3, 0)}${' '}
                          ETH`
                    : '-'}
                  <br />
                  {/*</p>*/}
                  {/* <p> */}
                  <Trans>Tx Hash:</Trans>{' '}
                  <MouseoverTooltip text={item.transaction.id}>
                    <ExternalLink href={getExplorerLink(chainId, item.transaction.id, ExplorerDataType.TRANSACTION)}>
                      <span style={{ marginLeft: '4px' }}>{shortenTxid(item.transaction.id, 6)}</span>
                    </ExternalLink>
                  </MouseoverTooltip>
                  {/* </p> */}
                </GridItem>
              </Grid>
            </ListItem>
          )
        })
      ) : (
        <EmptyList>
          <Trans>No position transaction datas</Trans>
        </EmptyList>
      )}
      {txData && txData.length > 0 ? (
        <Pagination
          count={Math.ceil(txData.length / pageStep)}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      ) : null}
    </TxListBox>
  )
}

export default React.memo(PositionTxDetails)
