import BigNumber from 'bignumber.js'
import { useMemo } from 'react'
import styled from 'styled-components/macro'

import { getPriceByTick } from '../../utils/fromTickToPrice'

interface SpreadRangeProps {
  spreadRange: number | string
  inRange: boolean
  spreadRangePercent: any
}
const SpreadRangeBox = styled.span<SpreadRangeProps>`
  padding: 4px 8px;
  display: inline-block;
  border: 1px solid ${(props) => (props.inRange ? '#00B464' : '#F59901')};
  border-radius: 6px;
  color: #1c1f26;
  position: relative;
  &:after {
    content: '';
    background-color: red;
    width: 1px;
    height: 100%;
    display: ${(props) => (props.inRange ? 'inline-block' : 'none')};
    position: absolute;
    top: 0;
    left: ${(props) => props.spreadRangePercent};
  }
`
interface IProps {
  // pool: Pool
  tickCurrent: number | undefined
  tickLower: number | undefined
  tickUpper: number | undefined
  spreadRange: string | number | undefined
  invert: boolean
}

export default function SpreadRange({ spreadRange, tickLower, tickUpper, tickCurrent, invert }: IProps) {
  const outOfRange: boolean =
    typeof tickCurrent !== 'undefined' && typeof tickLower !== 'undefined' && typeof tickUpper !== 'undefined'
      ? tickCurrent < tickLower || tickCurrent >= tickUpper
      : false
  const spreadRangePercent = useMemo(() => {
    if (typeof tickCurrent === 'undefined' || typeof tickLower === 'undefined' || typeof tickUpper === 'undefined')
      return undefined
    const currentPrice = getPriceByTick(tickCurrent).toFixed()
    const lowerPrice = getPriceByTick(tickLower).toFixed()
    const upperPrice = getPriceByTick(tickUpper).toFixed()
    let spreadRangeNumber = new BigNumber(currentPrice)
      .minus(lowerPrice)
      .dividedBy(new BigNumber(upperPrice).minus(lowerPrice))
    spreadRangeNumber = invert ? spreadRangeNumber : new BigNumber(1).minus(spreadRangeNumber)
    const spreadRangePercent = spreadRangeNumber.times(100).toFixed(2) + '%'
    return spreadRangePercent
  }, [tickLower, tickUpper, tickCurrent, invert])
  return (
    <>
      {spreadRangePercent && spreadRange ? (
        <SpreadRangeBox spreadRange={spreadRange} inRange={!outOfRange} spreadRangePercent={spreadRangePercent}>
          {spreadRange}
        </SpreadRangeBox>
      ) : (
        ''
      )}
    </>
  )
}
