// eslint-disable-next-line no-restricted-imports
import { t } from '@lingui/macro'
import { memo } from 'react'

import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'

interface ShowAprProps {
  apr: number | undefined
  title?: string
  size?: number
}

// 展示APR数据以及APR的解释
const ShowApr = memo(function ShowApr({ apr, title, size }: ShowAprProps) {
  return (
    <RowBetween style={{ justifyContent: 'flex-end', width: 'auto' }}>
      <RowFixed>
        {title ? title : 'APR'}
        <QuestionHelper text={t`Annual Percentage Rate`} />: <OnlyApr apr={apr} size={size} />
      </RowFixed>
    </RowBetween>
  )
})

// 仅仅展示APR数值
const OnlyApr = memo(function OnlyApr({ apr, size }: ShowAprProps) {
  return (
    <>
      {typeof apr !== 'undefined' && !isNaN(apr) && isFinite(apr) ? (
        <span style={{ color: '#00B464', fontSize: size ?? '24px', fontWeight: 500 }}>{(100 * apr).toFixed(2)}%</span>
      ) : (
        '-'
      )}
    </>
  )
})

export default ShowApr
export { OnlyApr }
