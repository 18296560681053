import { Drawer } from '@material-ui/core'
import { initializeAnalytics } from 'components/AmplitudeAnalytics'
import Loader from 'components/Loader'
import Settings from 'components/Settings'
import TopLevelModals from 'components/TopLevelModals'
import Web3Provider from 'components/Web3Provider'
import { AccountOverviewManager, AccountsManager } from 'constants/accounts'
import { useAccountOverview, useObservedAccounts, useSelectedAccount } from 'hooks/useAccounts'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import { Suspense, useState } from 'react'
import { useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'

import { AccountOverview } from '../components/AccountOverview'
import { useAnalyticsReporter } from '../components/analytics'
import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
import Popups from '../components/Popups'
import SideBar from '../components/SideBar'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import Accounts from './Accounts'
import AddAccount from './Accounts/AddAccount'
import AddLiquidity from './AddLiquidity'
import { RedirectDuplicateTokenIds } from './AddLiquidity/redirects'
import History from './History'
import Overview from './OverView'
import Position from './Position'
import { PositionPage } from './Position/PositionPage'
import RemoveLiquidityV3 from './RemoveLiquidity/V3'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'

// const Vote = lazy(() => import('./Vote'))

const AppWrapper = styled.div`
  display: flex;
  // flex-flow: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100vw;
  height: 100vh;
`

const BodyWrapper = styled.div`
  // display: flex;
  // flex-direction: column;
  // width: 100%;
  // padding: 120px 16px 0px 16px;
  // align-items: center;
  // flex: 1;
  // z-index: 1;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 180px);
  height: calc(100vh - 76px);
  //padding-top: 120px;
  align-items: flex-start;
  overflow: auto;
  box-sizing: border-box;
  padding: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    // padding: 4rem 8px 16px 8px;
    padding: 16px;
    width: 100vw;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: relative;
  height: 76px;
  // position: fixed;
  // top: 0;
  z-index: 2;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

const SideBarWrapper = styled(Drawer)`
  width: 180px;
  height: calc(100vh - 76px);
  background-color: white;
  // background-color: ${({ theme }) => theme.bg1};
`
const SideBarWrapperPc = styled.div`
  width: 180px;
  height: calc(100vh - 76px);
  background-color: white;
  // background-color: ${({ theme }) => theme.bg1};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none
  `};
`

export default function App() {
  const history = useHistory()
  useAnalyticsReporter(useLocation())
  initializeAnalytics()

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [history])

  const [selectedAccount, setSelectedAccount] = useSelectedAccount()
  const [accounts, addAccount, removeAccount, editAccountRemark, updateAccountSort] = useObservedAccounts()
  const [accountOverview, setAccountOverview] = useAccountOverview()
  const [showSideBar, setShowSideBar] = useState(false)
  const handleSideBarTargle = () => {
    setShowSideBar(!showSideBar)
  }

  return (
    <ErrorBoundary>
      <Route component={DarkModeQueryParamReader} />
      <Route component={ApeModeQueryParamReader} />
      <AccountsManager.Provider
        value={{
          selectedAccount,
          setSelectedAccount,
          accounts,
          addAccount,
          removeAccount,
          editAccountRemark,
          updateAccountSort,
        }}
      >
        <AccountOverviewManager.Provider value={{ accountOverview, setAccountOverview }}>
          {/* <Web3ReactManager> */}
          <Web3Provider>
            <AppWrapper>
              {/* <HeaderWrapper>
          <Header />
        </HeaderWrapper> */}
              <HeaderWrapper>
                <Header showSidebar={handleSideBarTargle} />
                <AccountOverview />
              </HeaderWrapper>
              <SideBarWrapper open={showSideBar} onClose={handleSideBarTargle}>
                <SideBar showSidebar={handleSideBarTargle} />
              </SideBarWrapper>
              <SideBarWrapperPc>
                <SideBar />
              </SideBarWrapperPc>
              <BodyWrapper>
                <Popups />
                <Polling />
                <TopLevelModals />
                <Suspense fallback={<Loader />}>
                  <Switch>
                    {/* <Route strict path="/vote" component={Vote} />
              <Route exact strict path="/create-proposal">
                <Redirect to="/vote/create-proposal" />
              </Route> */}
                    {/* <Route exact strict path="/claim" component={OpenClaimAddressModalAndRedirectToSwap} />
              <Route exact strict path="/uni" component={Earn} />
              <Route exact strict path="/uni/:currencyIdA/:currencyIdB" component={Manage} /> */}

                    <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                    <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                    <Route exact strict path="/swap" component={Swap} />

                    {/* <Route exact strict path="/pool/v2/find" component={PoolFinder} />
              <Route exact strict path="/pool/v2" component={PoolV2} /> */}
                    {/* <Route exact strict path="/pool" component={Pool} />
              <Route exact strict path="/pool/:tokenId" component={PositionPage} /> */}
                    <Route exact strict path="/position" component={Position} />
                    <Route exact strict path="/position/:tokenId" component={PositionPage} />

                    <Route exact strict path="/accounts" component={Accounts} />
                    <Route exact strict path="/accounts/add" component={AddAccount} />

                    <Route exact strict path="/overview" component={Overview} />
                    <Route exact strict path="/settings" component={Settings} />
                    {/* <Route exact strict path="/analysis/:address" component={Analysis} /> */}
                    <Route exact strict path="/history" component={History} />

                    {/* <Route exact strict path="/add/v2/:currencyIdA?/:currencyIdB?" component={RedirectDuplicateTokenIdsV2} /> */}
                    <Route
                      exact
                      strict
                      path="/add/:currencyIdA?/:currencyIdB?/:feeAmount?"
                      component={RedirectDuplicateTokenIds}
                    />

                    <Route
                      exact
                      strict
                      path="/increase/:currencyIdA?/:currencyIdB?/:feeAmount?/:tokenId?"
                      component={AddLiquidity}
                    />

                    {/* <Route exact strict path="/remove/v2/:currencyIdA/:currencyIdB" component={RemoveLiquidity} /> */}
                    <Route exact strict path="/remove/:tokenId" component={RemoveLiquidityV3} />

                    {/* <Route exact strict path="/migrate/v2" component={MigrateV2} />
              <Route exact strict path="/migrate/v2/:address" component={MigrateV2Pair} /> */}

                    <Route component={RedirectPathToSwapOnly} />
                  </Switch>
                </Suspense>
                <Marginer />
              </BodyWrapper>
            </AppWrapper>
          </Web3Provider>
          {/* </Web3ReactManager> */}
        </AccountOverviewManager.Provider>
      </AccountsManager.Provider>
    </ErrorBoundary>
  )
}
